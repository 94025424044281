import request from '.';

/** @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig */

/**
 * 通用下载方法
 *
 * @typedef {Object} downloadParams
 * @property {String} url
 * @property {Object} params
 * @property {String} filename
 * @property {AxiosRequestConfig} requestConfig
 */

export function download(url, params, filename = '', requestConfig) {
  const {headers = {}, ...restConfig} = requestConfig || {};
  return request
    .post(url, params, {
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      responseType: 'blob',
      ...restConfig,
    })
    .then((response) => {
      const content = response?.data ?? response;
      let finalFilename = filename;
      if (!finalFilename) {
        const responseFilename = response?.headers?.[
          'content-disposition'
        ]?.match(/filename=(\S+?\.[a-z]+)/)?.[0];
        const recordResponseFilename = responseFilename
          ? responseFilename.split('=')[1]
          : '';
        finalFilename = decodeURIComponent(recordResponseFilename ?? '');
      }
      const blob = new Blob([content]);
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a');
        elink.download = finalFilename;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } else {
        navigator.msSaveBlob(blob, finalFilename);
      }
    })
    .catch((r) => {
      console.error(r);
    });
}

// 后端命名文件
export const downloadBackEndFilename = download;

// 传JSON数据下载
export function downloadByJson(url, data, fileName = '') {
  return request
    .post(url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    })
    .then((res) => {
      const resHeaders = res.headers['content-disposition'] || '';
      let defFileName = fileName;
      if (resHeaders.includes('filename')) {
        defFileName = resHeaders.split('=')[1];
        defFileName = decodeURIComponent(defFileName);
      }
      const content = res.data;
      const blob = new Blob([content]);
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a');
        elink.download = defFileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } else {
        navigator.msSaveBlob(blob, defFileName);
      }
    })
    .catch((r) => {
      console.error(r);
    });
}
