import {css} from '@emotion/react';
import loadComponent from '@loadable/component';
import {useAtom} from 'jotai';
import React, {useRef, forwardRef} from 'react';

import {deepClone} from '../../helpers/utils';
import GlobalModal from '../GlobalModal';
import {filterAtom} from './atoms/filterAtom';
import FilterPreviewTable from './components/FilterPreviewTable';

function Title() {
  const [{column}] = useAtom(filterAtom);
  return (
    <div>
      <p
        css={css`
          height: 22px;
          font-size: 16px;
          font-weight: 500;
          color: #1a2230;
          line-height: 22px;
          margin-bottom: 12px;
        `}
      >
        筛选器
      </p>
      <p
        css={css`
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(26, 34, 48, 0.7);
          line-height: 20px;
        `}
      >
        {column.filterTitle ?? column.title}
      </p>
    </div>
  );
}

const FilterMode = forwardRef(function FilterMode(props, ref) {
  const [{column, visible}] = useAtom(filterAtom);
  const Component = loadComponent(() =>
    import(`./components/${column.filterType}`)
  );

  if (!column.filterType || !visible) return null;

  return <Component ref={ref} />;
});

export default function Filter() {
  const filterRef = useRef();

  const [filterParams, setFilterAtom] = useAtom(filterAtom);

  const {visible, tableKey, conditions, column} = filterParams;

  function onClose() {
    setFilterAtom({
      ...filterParams,
      visible: false,
    });
  }

  function search() {
    const res = filterRef?.current?.validate();
    if (!res) return;
    const conditionsCopy = deepClone(conditions);
    conditionsCopy[tableKey][column.aliasName] = {
      ...conditionsCopy[tableKey][column.aliasName],
      ...column.extraFilterParams,
      ...res,
    };

    setFilterAtom({
      ...filterParams,
      conditions: conditionsCopy,
      visible: false,
    });

    Promise.resolve().then(() => {
      column?.onFilterSearch?.(conditionsCopy);
    });
  }

  return (
    <GlobalModal
      centered
      forceRender
      maskClosable={false}
      maskTransitionName=''
      style={{userSelect: 'none'}}
      title={<Title />}
      transitionName=''
      visible={visible}
      width={688}
      zIndex={1001}
      onCancel={onClose}
      onOk={search}
    >
      <FilterMode ref={filterRef} />
    </GlobalModal>
  );
}

Filter.FilterPreviewTable = FilterPreviewTable;
