import {css} from '@emotion/react';
import {Button, Divider, notification, Space} from 'antd';
import {useAtom} from 'jotai';
import React, {useEffect, useRef, useContext, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

import {MicroDataQueryContext} from '../..';
import {
  batchEditFilterGroup,
  filterGroupList,
  getFilterGroupDetail,
  getFilterRecord,
} from '../../../../../api/dataQuery/microDataQuery';
import {filterAtom} from '../../../../../components/Filter/atoms/filterAtom';
import GlobalModal from '../../../../../components/GlobalModal';
import WarningModal from '../../../../../components/GlobalModal/WarningModal';
import TableSheetCopy from '../../../../../components/TableSheetCopy';
import {makeFilterCondition} from '../../../../../components/TableSheetCopy/helper';
import {deepClone} from '../../../../../helpers/utils';
import useModalControl from '../../../../../hooks/useModalControl';
import useSafeState from '../../../../../hooks/useSafeState';
import {encodeValueList} from '../../helpers';
import {useFetchFilterGroup} from '../../hooks/useFetchFilterGroup';
import {infoItem, infoItemLabel, infoItemText} from '../../style.css';
import FilterTableEditor from '../FilterTableEditor';

export default function BatchEditFilterModal({modalControl}) {
  const [groupList, setGroupList] = useSafeState([]);
  const [curGroup, setCurGroup] = useSafeState({});
  const [curGroupDetail, setCurGroupDetail] = useSafeState([]);
  const [updatesGroup, setUpdatesGroup] = useSafeState([]);
  const [cacheFilterData, setCacheFilterData] = useSafeState({});
  const [reqFilterData, setReqFilterData] = useSafeState({});
  const [deleteGroupList, setDeleteGroupList] = useSafeState([]);
  const [update, setUpdate] = useSafeState(1);
  const [loading, setLoading] = useSafeState(false);
  const [confirmLoading, setConfirmLoading] = useSafeState(false);

  const {formatColumns, wideOverview, columnList} = useContext(
    MicroDataQueryContext
  );

  const originDataRef = useRef();
  const setTypeFlagRef = useRef(false);
  const extraFilterConditionRef = useRef({});
  const staticFiltersRef = useRef({});
  const sectionFiltersRef = useRef({});

  const [searchParams] = useSearchParams();

  const cancelModalControl = useModalControl();

  const [reqFilterGroupList] = useFetchFilterGroup();

  const [filterParams, setFilterParams] = useAtom(filterAtom);
  const {conditions} = filterParams;

  const isCanChange = useMemo(() => {
    return cacheFilterData[curGroup.id]?.some((i) => !!i.valueList) ?? true;
  }, [cacheFilterData, curGroup]);

  const extraFilterCondition = useMemo(() => {
    const conditionsCopy = deepClone(conditions);
    extraFilterConditionRef.current[curGroup?.id] = makeFilterCondition(
      columnList,
      conditionsCopy.filterGroupListTable2 ?? {}
    );
    return extraFilterConditionRef.current;
  }, [columnList, conditions, curGroup]);

  const staticFilters = useMemo(() => {
    staticFiltersRef.current[curGroup?.id] = extraFilterCondition[curGroup?.id]
      .map((item) => {
        const column = columnList.find(
          (col) => `zhtj_coding_${col.indexOriName}` === item.aliasName
        );
        return column?.mosaicType && !!item.valueList
          ? {
              column: column.indexOriName,
              mosaicType: column.mosaicType,
            }
          : false;
      })
      .filter(Boolean);
    return staticFiltersRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnList, curGroup, extraFilterCondition, conditions]);

  const sectionFilters = useMemo(() => {
    const _sectionFilters = [];
    const conditionsCopy = deepClone(conditions);
    const queryFilterConditions = conditionsCopy.filterGroupListTable2 ?? {};
    Object.keys(queryFilterConditions).forEach((key) => {
      const column = columnList.find((col) => col.indexOriName === key);
      if (column?.sectionId) {
        const valueList = queryFilterConditions[key].valueList;
        if (valueList) {
          valueList.forEach((item) => {
            let splitItem = item;
            if (typeof splitItem === 'string') {
              splitItem = decodeURIComponent(item).split('#z_h_t_j#');
              if (splitItem.length < 2) return;
              splitItem = splitItem.slice(0, splitItem.length - 1);
              splitItem = JSON.parse(splitItem.join('#z_h_t_j#'));
            }
            _sectionFilters.push({
              column: key,
              left: splitItem.left,
              right: splitItem.right,
              symbol: splitItem.symbol,
              label: splitItem.label,
            });
          });
        }
      }
    });
    sectionFiltersRef.current[curGroup?.id] = _sectionFilters;
    return sectionFiltersRef.current;
  }, [columnList, conditions, curGroup]);

  const fuseColumnList = (data) => {
    const cloneColumnList = deepClone(formatColumns);
    const combination = [];
    data.forEach((i) => {
      cloneColumnList.forEach((k) => {
        if (i.aliasName === k.indexOriName) {
          combination.push({...k, ...i});
        }
      });
    });
    return combination;
  };

  const reqFilterGroupDetail = async (id, con = null) => {
    setLoading(true);

    if (cacheFilterData[id]) {
      try {
        const res = await getFilterRecord(
          {
            'filter-condition': JSON.stringify(
              encodeValueList(con) ??
                encodeValueList(
                  cacheFilterData[id]
                    .filter((cfd) => cfd.aliasName !== 'zhtj_tag')
                    .filter((cfd) => Object.keys(cfd).length > 0)
                )
            ),
          },
          {
            queryList: formatColumns.map((i) => ({
              aliasName: i.indexOriName,
              curShowName: i.curShowName || i.indexOriName,
            })),
          }
        );
        if (res?.code === 200) {
          const comb = fuseColumnList(res?.data ?? []);
          setCurGroupDetail(comb);
          const _reqFilter = {
            ...reqFilterData,
            [id]: res?.data,
          };
          setReqFilterData(_reqFilter);
          setLoading(false);
        }
      } catch (error) {
        setCurGroupDetail([]);
        throw new Error('failed');
      }
    } else {
      try {
        const res = await getFilterGroupDetail({
          id,
          queryList: formatColumns.map((i) => ({
            aliasName: i.indexOriName,
            curShowName: i.curShowName || i.indexOriName,
          })),
        });
        if (res?.code === 200) {
          const comb = fuseColumnList(res?.data ?? []);
          setCurGroupDetail(comb);
          const record = res?.data?.map((i) =>
            i.filterJson ? JSON.parse(i.filterJson) : {}
          );
          const obj = {
            ...cacheFilterData,
            [id]: record,
          };
          const _reqFilter = {
            ...reqFilterData,
            [id]: res?.data,
          };
          setCacheFilterData(obj);
          setReqFilterData(_reqFilter);
          setLoading(false);
        }
      } catch (error) {
        setCurGroupDetail([]);
        throw new Error('request detail failed');
      }
    }
  };

  const deleteGroupHandle = (cur) => {
    const cloneGroupList = deepClone(groupList);
    const cloneUpdatesGroup = deepClone(updatesGroup);
    const cloneCacheFilterData = deepClone(cacheFilterData);
    const cloneReqFilterData = deepClone(reqFilterData);
    const cloneDeleteGroupList = deepClone(deleteGroupList);
    const deleteListIndex = cloneGroupList.findIndex((i) => i.id === cur.id);
    // 删除已经存在于updatesGroup中的对象
    const deleteUpdateIndex = cloneUpdatesGroup.findIndex(
      (i) => i.id === cur.id
    );
    // 删除缓存的筛选器
    delete cloneCacheFilterData[cur.id];
    delete cloneReqFilterData[cur.id];

    cloneGroupList.splice(deleteListIndex, 1);
    cloneUpdatesGroup.splice(deleteUpdateIndex, 1);

    // 保存删除的数组
    cloneDeleteGroupList.push(cur);

    setGroupList(cloneGroupList);
    setUpdatesGroup(cloneUpdatesGroup);
    setCacheFilterData(cloneCacheFilterData);
    setReqFilterData(cloneReqFilterData);
    setCurGroupDetail([]);
    setCurGroup({});
    setTypeFlagRef.current = false;
    setDeleteGroupList(cloneDeleteGroupList);
  };

  const updateCacheFilterHandle = (con) => {
    const record = Object.values(con.filterGroupListTable2);

    const keys = Object.keys(con.filterGroupListTable2);
    const format = record.map((i, index) => ({
      ...i,
      aliasName: keys[index],
    }));
    const obj = {
      ...cacheFilterData,
      [curGroup.id]: format,
    };
    setCacheFilterData(obj);
    reqFilterGroupDetail(curGroup.id, format);
  };

  const saveUpdateGroupHandle = (record) => {
    const clone = [...updatesGroup];
    const index = updatesGroup.findIndex((i) => i.id === record.id);
    if (index >= 0) {
      clone.splice(index, 1);
    }
    clone.push(record);
    setUpdatesGroup(clone);
  };

  const onSubmitHandle = async () => {
    const format = updatesGroup.map((upg) => ({
      ...groupList.find((gl) => gl.id === upg.id),
      filterGroupDetail: formatColumns.map((i) => ({
        curShowName: i.curShowName,
        indexId: i.indexId ?? null,
        calId: i.queryCalId ?? null,
        filterCondition: cacheFilterData[upg.id]?.filter(
          (v) => v.aliasName === i.indexOriName
        )[0]?.valueList
          ? JSON.stringify(
              cacheFilterData[upg.id]?.filter(
                (v) => v.aliasName === i.indexOriName
              )[0]
            )
          : '',
        filterContent: reqFilterData[upg.id].filter(
          (v) => v.aliasName === i.indexOriName
        )[0].record,
      })),
      tagIds:
        cacheFilterData[upg.id]
          ?.filter((cfd) => cfd.aliasName === 'zhtj_tag')[0]
          ?.valueList?.map(
            (vl) => decodeURIComponent(vl).split('#z_h_t_j#')[0]
          ) ?? [],
      staticFilters: staticFilters[upg.id] ?? [],
      sectionFilters: sectionFilters[upg.id] ?? [],
    }));
    setConfirmLoading(true);
    try {
      const res = await batchEditFilterGroup({
        updates: format,
        deletes: deleteGroupList,
      });
      if (res?.code === 200) {
        notification.success({description: '筛选组配置保存成功'});
        setConfirmLoading(false);
        modalControl._toggle(false);
        await reqFilterGroupList();
      }
    } catch (err) {
      setConfirmLoading(false);
      notification.error({description: '筛选组配置保存失败'});
      throw new Error('bacth edit filter group failed');
    }
  };

  const onCancelHandle = () => {
    if (updatesGroup.length > 0 || deleteGroupList.length > 0) {
      cancelModalControl._toggle(true);
    } else {
      modalControl._toggle(false);
    }
  };

  const right_columns = [
    {
      title: '名称',
      dataIndex: 'name',
    },
    {
      title: '类型',
      dataIndex: 'type',
      render: (val) => {
        return val ? '私有' : '公开';
      },
    },
    {
      title: '操作',
      dataIndex: 'operate',
      align: 'right',
      width: 110,
      fixWidth: true,
      render: (val, record) => {
        return (
          <Space>
            <Button
              size='small'
              style={{fontSize: 12, padding: 0}}
              type='link'
              onClick={(e) => {
                e.stopPropagation();
                deleteGroupHandle(record);
              }}
            >
              删除
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    async function getRightList() {
      try {
        const res = await filterGroupList({
          tableId: searchParams.get('wideId'),
        });
        if (res?.code === 200) {
          setGroupList(res?.data ?? []);
          originDataRef.current = res?.data;
        }
      } catch (error) {
        throw new Error('get filterGroup list failed');
      }
    }
    if (modalControl.visible) {
      getRightList();
    }
  }, [modalControl.visible, searchParams, setGroupList]);

  useEffect(() => {
    if (!modalControl.visible) {
      setUpdate(Date.now());
      setCacheFilterData({});
      setCurGroup({});
      setCurGroupDetail([]);
      setDeleteGroupList([]);
      setUpdatesGroup([]);
      setGroupList([]);
      setTypeFlagRef.current = false;
      staticFiltersRef.current = {};
      sectionFiltersRef.current = {};
      extraFilterConditionRef.current = {};
      const conditionsCopy = deepClone(conditions);
      conditionsCopy.filterGroupListTable2 = {};
      setFilterParams({
        ...filterParams,
        conditions: conditionsCopy,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalControl.visible]);

  // console.log('wideOverview', wideOverview, curGroupDetail);

  return (
    <>
      <GlobalModal
        centered
        title='筛选组管理'
        {...modalControl}
        confirmLoading={confirmLoading}
        key={update}
        okButtonProps={{disabled: !isCanChange}}
        width={900}
        onCancel={onCancelHandle}
        onOk={onSubmitHandle}
      >
        <p css={infoItem}>
          <span css={infoItemLabel}>表名：</span>
          <span css={infoItemText}>{wideOverview?.tableShowName || '--'}</span>
        </p>

        <Divider style={{margin: '10px 0', marginBottom: 0}} />

        <div
          css={css`
            display: flex;
            padding-top: 10px;
          `}
        >
          <div
            css={css`
              width: 40%;
              border-right: 1px solid #f0f0f0;
              padding-right: 10px;
            `}
          >
            <TableSheetCopy
              fixedTableWidth
              clearFilter={false}
              columns={right_columns}
              dataSource={groupList}
              pagination={false}
              recordRecurrent={false}
              refresh={false}
              rowKey='id'
              scroll={{y: '40vh'}}
              storeColumnWidthKey='filterGroupListTable1'
              onRow={(record) => {
                return {
                  onClick: (event) => {
                    if (!isCanChange) {
                      return;
                    }
                    if (curGroup.id === record.id) {
                      return;
                    }
                    if (setTypeFlagRef.current) {
                      const cloneGroupList = deepClone(groupList);
                      cloneGroupList.forEach((i, index) => {
                        if (i.id === record.id) {
                          cloneGroupList[index].type = i.type === 1 ? 0 : 1;
                        }
                      });
                      setTypeFlagRef.current = false;
                      setCurGroup(
                        cloneGroupList.find((cgl) => cgl.id === record.id)
                      );
                    } else {
                      setCurGroup(record);
                    }
                    reqFilterGroupDetail(record.id);
                    saveUpdateGroupHandle(record);
                  },
                };
              }}
            />
          </div>
          <div
            css={css`
              width: 60%;
              padding-left: 10px;
            `}
          >
            <p>
              <span style={{fontSize: 14}}>字段及筛选器详情</span>
              <span style={{marginLeft: 8}}>{curGroup.name}</span>
            </p>
            <FilterTableEditor
              cacheFilterConditions={cacheFilterData[curGroup?.id]}
              curGroup={curGroup}
              dataSource={curGroupDetail}
              filterConditions={cacheFilterData[curGroup?.id]}
              loading={loading}
              scroll={{y: '40vh'}}
              storeColumnWidthKey='filterGroupListTable2'
              updateCacheFilterHandle={updateCacheFilterHandle}
            />
          </div>
          {isCanChange ? null : (
            <span
              style={{
                color: '#E34D59',
                marginLeft: 8,
                position: 'absolute',
                bottom: 27,
                left: 433,
              }}
            >
              请至少对1个字段进行筛选
            </span>
          )}
        </div>
      </GlobalModal>
      <WarningModal
        confirm={() => {
          modalControl._toggle(false);
          cancelModalControl._toggle(false);
        }}
        description='当前操作内容未保存，请确认是否离开'
        modalControl={cancelModalControl}
        title='确认是否离开'
        width={450}
        zIndex={1001}
      />
    </>
  );
}
