import {css} from '@emotion/react';
import {useEffect, useState} from 'react';

import {hoverLink, normalLink} from '../../containers/Layout/layout.css';
import IconFont from '../IconFont';

async function toPollifyFullScreen(targetDom) {
  if (targetDom.requestFullscreen) {
    await targetDom.requestFullscreen();
  } else if (targetDom.webkitRequestFullScreen) {
    await targetDom.webkitRequestFullScreen();
  } else if (targetDom.mozRequestFullScreen) {
    await targetDom.mozRequestFullScreen();
  } else {
    await targetDom.msRequestFullscreen();
  }
}

export default function FullScreenButton() {
  const [fullScreen, setFullScreen] = useState(false);
  const handleClickFullScreen = async () => {
    const mainContent = document.querySelector('#root');

    if (fullScreen) {
      await document.exitFullscreen();
    } else {
      // await mainContent?.requestFullscreen();
      await toPollifyFullScreen(mainContent);
    }
  };

  const handleFullScreenChange = () => {
    const {fullscreenElement} = document;
    setFullScreen(!!fullscreenElement);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () =>
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
  }, []);

  return (
    <IconFont
      className='fullScreenBtn'
      css={css`
        ${normalLink}
        &:hover {
          ${hoverLink}
        }
      `}
      height={14}
      type='icon-full'
      width={14}
      onClick={handleClickFullScreen}
    />
  );
}
