export const operatorTypeOpt = [
  {
    value: 1,
    label: '后台用户',
  },
  {
    value: 2,
    label: '手机端用户',
  },
  {
    value: 0,
    label: '其他',
  },
];
export const behaviorTypeOpt = [
  {
    value: 'add',
    label: '新增',
  },
  {
    value: 'update',
    label: '修改',
  },
  {
    value: 'delete',
    label: '删除',
  },
  {
    value: 'select',
    label: '查询',
  },
  {
    value: 'login',
    label: '登录',
  },
  {
    value: 'logout',
    label: '退出',
  },
  {
    value: 'export',
    label: '导出',
  },
];

export const statusTypeOpt = [
  {
    value: 0,
    label: '正常',
  },
  {
    value: 1,
    label: '异常',
  },
];

export const logsColSchema = new Map([
  [
    '日志ID',
    {
      dataIndex: 'id',
      showInDetail: false,
      showInTable: true,
      showInSearch: false,
    },
  ],
  [
    '系统名称',
    {
      dataIndex: 'sysName',
      showInDetail: true,
      showInTable: true,
      showInSearch: true,
    },
  ],
  [
    '模块名称',
    {
      dataIndex: 'moduleName',
      showInDetail: true,
      showInTable: true,
      showInSearch: true,
    },
  ],
  [
    '上报人',
    {
      dataIndex: 'reportUserName',
      showInDetail: false,
      showInTable: true,
      showInSearch: true,
    },
  ],
  [
    '日志上报人UID',
    {
      dataIndex: 'reportUserId',
      showInDetail: true,
      showInTable: false,
      showInSearch: false,
    },
  ],
  [
    '日志上报人用户名',
    {
      dataIndex: 'reportUserName',
      showInDetail: true,
      showInTable: false,
      showInSearch: false,
    },
  ],
  [
    '操作人IP',
    {
      dataIndex: 'operIp',
      showInDetail: true,
      showInTable: false,
      showInSearch: false,
    },
  ],
  [
    '行为',
    {
      dataIndex: 'behavior',
      showInDetail: true,
      showInTable: true,
      showInSearch: true,
    },
  ],
  [
    '操作时间',
    {
      dataIndex: 'createTime',
      showInDetail: true,
      showInTable: true,
      showInSearch: true,
    },
  ],
  [
    '操作类别',
    {
      dataIndex: 'operatorType',
      showInDetail: false,
      showInTable: true,
      showInSearch: true,
    },
  ],
  [
    '业务状态',
    {
      dataIndex: 'status',
      showInDetail: true,
      showInTable: true,
      showInSearch: true,
    },
  ],
  [
    '接口名称',
    {
      dataIndex: 'interfaceMethod',
      showInDetail: true,
      showInTable: true,
      showInSearch: false,
    },
  ],
  [
    '执行消耗时间',
    {
      dataIndex: 'executeTime',
      showInDetail: true,
      showInTable: false,
      showInSearch: false,
    },
  ],
  [
    '操作内容',
    {
      dataIndex: 'content',
      showInDetail: true,
      showInTable: false,
      showInSearch: false,
    },
  ],
  [
    '错误内容',
    {
      dataIndex: 'errorMsg',
      showInDetail: true,
      showInTable: false,
      showInSearch: false,
    },
  ],
]);
