import {Button, Form} from 'antd';
import {useEffect} from 'react';

import GlobalModal from '../../../../../components/GlobalModal';
import {deepClone} from '../../../../../helpers/utils';
import useSafeState from '../../../../../hooks/useSafeState';
import FieldMatchFormItem from './FieldMatchFormItem';

export default function FieldMatchModal({
  tableId,
  visible,
  isSaving = false,
  syncFlag,
  onCancel,
  onOk,
  tableData,
  isSqlWide,
}) {
  const [syncFormValues, setSyncFormValues] = useSafeState({});

  const [form] = Form.useForm();

  const handleSubmit = (flag) => {
    form.validateFields().then(() => {
      onOk(syncFormValues, flag, (err) => {});
    });
  };

  useEffect(() => {
    if (tableData && visible) {
      const next = deepClone(tableData);
      if (next.jobCronJson) {
        next.jobCronJson = JSON.parse(next.jobCronJson);
      }
      if (next.collectPathId) {
        next.collectFlag = true;
      }
      if (!next.tableNameEn) {
        next.tableNameEn = 'ud_';
      }
      setSyncFormValues({...next});
    }
  }, [form, visible, setSyncFormValues, tableData]);

  return (
    <GlobalModal
      centered
      footer={[
        <Button
          className='cancel-btn-text-color'
          key={1}
          loading={isSaving}
          size='small'
          type='default'
          onClick={onCancel}
        >
          取消
        </Button>,
        <Button
          className='ensure-btn-text-color'
          key={2}
          loading={isSaving}
          size='small'
          type='primary'
          onClick={() => handleSubmit(0)}
        >
          保存
        </Button>,
        syncFlag ? (
          false
        ) : (
          <Button
            className='ensure-btn-text-color'
            key={3}
            loading={isSaving}
            size='small'
            type='primary'
            onClick={() => {
              handleSubmit(1);
            }}
          >
            保存并同步
          </Button>
        ),
      ].filter(Boolean)}
      title='保存数据库'
      visible={visible}
      width={650}
      onCancel={onCancel}
    >
      <Form form={form}>
        <FieldMatchFormItem
          form={form}
          isSqlWide={isSqlWide}
          tableId={tableId}
          visible={visible}
        />
      </Form>
    </GlobalModal>
  );
}
