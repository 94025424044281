import {css} from '@emotion/react';
import {Button} from 'antd';
import React from 'react';

import './style.css';
import {logoutHandle, routeHistory} from '../../helpers/history';
import {useUserInfo} from '../../hooks/useUserInfo';

export default function Guide() {
  const userInfo = useUserInfo();

  return (
    <div className='guide-wrapper'>
      <div
        className='guide-logo'
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 40px;
        `}
      >
        <img alt='前海数据' src={require('./images/logo.png')} />
        {userInfo ? (
          <div>
            <span style={{fontSize: 18}}>{userInfo.username}</span>
            <Button
              size='large'
              type='link'
              onClick={() => logoutHandle(false)}
            >
              退出登录
            </Button>
          </div>
        ) : (
          <Button
            size='large'
            type='link'
            onClick={() => {
              routeHistory.push(`/login?redirect=guide`);
            }}
          >
            登录
          </Button>
        )}
      </div>
      <div className='guide-content'>
        <div className='left_content'>
          <div className='left_item'>
            <img alt='' src={require('./images/left_item1.png')} />
            <div className='left_item_text_content'>
              <p className='left_item_text_title'>短期-提效</p>
              <p className='left_item_text_desc'>保证数据准确性，拿来即用</p>
              <p className='left_item_text_desc'>重复工作自动化，所见所得</p>
            </div>
          </div>
          <div className='left_item'>
            <img alt='' src={require('./images/left_item2.png')} />
            <div className='left_item_text_content'>
              <p className='left_item_text_title'>中期-增值</p>
              <p className='left_item_text_desc'>数据、业务挖价值，寻宝谋势</p>
              <p className='left_item_text_desc'>智能、业务相结合，借力打力</p>
            </div>
          </div>
          <div className='left_item'>
            <img alt='' src={require('./images/left_item3.png')} />
            <div className='left_item_text_content'>
              <p className='left_item_text_title'>长期-先知</p>
              <p className='left_item_text_desc'>联动更多业务场景，互联聚势</p>
              <p className='left_item_text_desc'>预知潜在业务联系，未卜先知</p>
            </div>
          </div>
        </div>
        <div className='right_content'>
          <div className='right_content_header'>
            <div className='right_content_header_item'>
              <p className='right_content_header_item_text'>业务支持</p>
            </div>
            <div className='right_content_header_item'>
              <p className='right_content_header_item_text'>数据支撑</p>
            </div>
            <div className='right_content_header_item'>
              <p className='right_content_header_item_text'>结果呈现</p>
            </div>
          </div>
          <div className='right_content_main'>
            <div className='right_content_main_left'>
              <a
                className='right_content_main_item'
                href='https://one.tuxinzhi.com/admin/system/iFrameWindow?frameUrl=https://one.tuxinzhi.com/stxz/index&code=M000627'
                rel='noreferrer'
                target='_blank'
              >
                <img alt='' src={require('./images/right_item1.png')} />
                <p className='right_content_main_item_title long_title'>
                  数据自主分析和可视化平台
                </p>
                <p className='right_content_main_item_desc'>
                  挖掘和探索数据、业务价值
                </p>
              </a>
              <a
                className='right_content_main_item'
                href='https://one.tuxinzhi.com/admin/system/iFrameWindow?frameUrl=https%3A%2F%2Fone.tuxinzhi.com%2Fyqyd%2Fyiqiyidang%2F&code=M000790'
                rel='noreferrer'
                target='_blank'
              >
                <img alt='' src={require('./images/right_item2.png')} />
                <p className='right_content_main_item_title'>一企一档</p>
                <p className='right_content_main_item_desc'>
                  企业数据录入、企业情况一览
                </p>
              </a>
            </div>

            <div className='line1'>
              <img alt='' src={require('./images/line.png')} />
            </div>

            <div className='line2'>
              <img alt='' src={require('./images/line2.png')} />
            </div>

            <div className='right_content_main_left'>
              <a
                className='right_content_main_item large'
                href='https://one.tuxinzhi.com/admin/M000641/dataTableManagement'
                rel='noreferrer'
                target='_blank'
              >
                <img alt='' src={require('./images/right_item3.png')} />
                <p className='right_content_main_item_title'>数据管理平台</p>
                <p className='right_content_main_item_desc'>
                  提升数据质量、数据放心使用
                </p>
              </a>
            </div>

            <div className='line3'>
              <img alt='' src={require('./images/line4.png')} />
            </div>

            <div className='line4'>
              <img alt='' src={require('./images/line3.png')} />
            </div>

            <div className='right_content_main_left'>
              <a
                className='right_content_main_item'
                href='https://one.tuxinzhi.com/admin/system/iFrameWindow?frameUrl=https%3A%2F%2Fone.tuxinzhi.com%2Fszgraph%2F&code=M000793'
                rel='noreferrer'
                target='_blank'
              >
                <img alt='' src={require('./images/right_item4.png')} />
                <p className='right_content_main_item_title'>经济一张图</p>
                <p className='right_content_main_item_desc'>核心指标直观展示</p>
              </a>
              <a
                className='right_content_main_item'
                href='https://one.tuxinzhi.com/admin/system/iFrameWindow?frameUrl=https://one.tuxinzhi.com/chatdata/chat&code=M000227'
                rel='noreferrer'
                target='_blank'
              >
                <img alt='' src={require('./images/right_item5.png')} />
                <p className='right_content_main_item_title long_title'>
                  AI数据便捷查询机器人
                </p>
                <p className='right_content_main_item_desc'>查询、信息小助手</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
