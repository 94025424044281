/* eslint-disable react/jsx-filename-extension */
import {css} from '@emotion/react';

import IconFont from '../../../../components/IconFont';

export const DATA_STATUS_TEXT_MAP = new Map([
  [5, {text: '错误', color: '#E34D59'}],
  [3, {text: '正常', color: '#00A870'}],
  [4, {text: '暂停', color: '#ED7B2F'}],
  [2, {text: '初始化', color: '#4D5EFF'}],
  [1, {text: '暂存', color: 'rgba(26, 34, 48, 0.7)'}],
]);

export const LOG_TYPE_STATUS_MAP = new Map([
  ['0', '启停'],
  ['1', '修改'],
  ['2', '更新'],
  ['3', '初始化'],
  ['4', '创建'],
  ['5', '错误'],
  ['6', '筛选组'],
  ['7', '标签'],
  ['8', '导出'],
  ['9', '查询'],
  ['10', '计算'],
]);

export const ENCRYPT_TYPE = new Map([
  [0, '不加密'],
  [1, '打码'],
  [2, '分层'],
]);

export const ENCRYPT_TYPE_REVERSE = new Map([
  ['不加密', 0],
  ['打码', 1],
  ['分层', 2],
]);

export const ENCRYPT_ICON = new Map([
  [
    0,
    <IconFont
      css={css`
        color: #4d5eff;
      `}
      key='icon-invisible'
      type='icon-invisible'
    />,
  ],
  [
    1,
    <IconFont
      css={css`
        color: #4d5eff;
      `}
      key='icon-code'
      type='icon-code'
    />,
  ],
  [
    2,
    <IconFont
      css={css`
        color: #4d5eff;
      `}
      key='icon-layers'
      type='icon-layers'
    />,
  ],
]);

export const MOSAIC_TYPE = new Map([
  [1, '前'],
  [2, '中'],
  [3, '后'],
]);

export const NUMBER_TYPE = [
  'tinyint',
  'smallint',
  'mediumint',
  'int',
  'bigint',
  'integer',
  'float',
  'double',
  'decimal',
];

export const INDEX_TYPE = new Map([
  ['BIGINT', '数值'],
  ['DECIMAL', '数值（小数） '],
  ['DATETIME', '时间'],
  ['VARCHAR', '文本'],
  ['TEXT', '长文本'],
]);
