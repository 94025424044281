import {css} from '@emotion/react';
import {Button, Card} from 'antd';
import React, {useEffect, useRef, useState} from 'react';

import {
  getPersonMessage,
  GET_PERSON_MESSAGE,
  hasReadByIds,
} from '../../../../api/system/letter';
import LimitRangePicker from '../../../../components/LimitRangePicker';
import TableSheetCopy from '../../../../components/TableSheetCopy';
import dayjs from '../../../../helpers/extendedDayjs';
import {useReadLetter} from '../../../Layout/hooks/useReadLetter';
import {cardBodyStyle, cardHeadStyle, StationLetterTitle} from './CardTitles';

const format = 'YYYY-MM-DD HH:mm:ss';

const STATUS_COLOR = {
  未读: '#00A870',
  已读: '#A3A7AC',
  已删除: '#E34D59',
};

const StationLetterTable = ({userId}) => {
  const [queryTime, setQueryTime] = useState(() => [
    dayjs().subtract(90, 'day'),
    dayjs(),
  ]);

  const {readResult, setReadResult} = useReadLetter();

  const tableRef = useRef();
  const pickerRef = useRef();

  useEffect(() => {
    if (typeof readResult?.result === 'function') {
      readResult.result();
    }
  }, [readResult]);

  function handleRead(row) {
    hasReadByIds({
      ids: [row.noticeId],
    }).then((res) => {
      if (res.code === 200) {
        tableRef.current?.mutate();
      }
    });
    setReadResult({
      noticeType: row.noticeType,
      pageCode: row.menuCode,
      content: row,
      queryParams: JSON.parse(row.paramData),
    });
  }

  const statusText = (color, text) => {
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          span {
            color: rgba(26, 34, 48, 0.7);
          }
        `}
      >
        <div
          css={css`
            background-color: ${color};
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 7px;
          `}
        />
        <span>{text}</span>
      </div>
    );
  };

  /** @type {import('antd/lib/table').ColumnsType} */
  const columns = [
    {
      dataIndex: 'noticeId',
      title: '通知ID',
      // renderWidth: 'AUTO',
      align: 'left',
    },
    {
      dataIndex: 'noticeTime',
      title: '通知时间',
      showSorter: false,
    },
    {
      dataIndex: 'menuName',
      title: '页面',
    },
    {
      dataIndex: 'noticeContent',
      title: '站内信内容',
    },
    {
      dataIndex: 'noticeStatus',
      title: '状态',
      render: (_, row) => {
        return statusText(STATUS_COLOR[row.noticeStatus], row.noticeStatus);
      },
    },
    {
      dataIndex: 'operation',
      title: '操作',
      width: 120,
      fixWidth: true,
      align: 'right',
      showSorter: false,
      render(value, row) {
        return (
          <Button
            size='small'
            type='link'
            onClick={() => {
              handleRead(row);
            }}
          >
            查看
          </Button>
        );
      },
    },
  ];

  const extraParams = {
    startTime: queryTime[0].startOf('day').format(format),
    endTime: queryTime[1].endOf('day').format(format),
  };

  return (
    <Card
      css={css`
        ${cardHeadStyle}
        ${cardBodyStyle}
      `}
      title={<StationLetterTitle />}
    >
      <TableSheetCopy
        clearFilter={() => pickerRef.current?.reset()}
        columns={columns}
        dataApi={userId ? GET_PERSON_MESSAGE : null}
        extraParams={extraParams}
        fetcher={(params) =>
          getPersonMessage({
            startTime: queryTime[0].format(format),
            endTime: queryTime[1].format(format),
            userId,
            ...params,
          })
        }
        pagination={{pageSize: 10}}
        ref={tableRef}
        refresh={false}
        rowKey='noticeId'
        searchParamsUsable={false}
        storeColumnWidthKey='stationLetterTable'
        topLeftButtons={[
          <LimitRangePicker
            handleChange={setQueryTime}
            key={1}
            limit={90}
            ref={pickerRef}
          />,
          <p
            css={css`
              display: flex;
              align-items: center;
              color: rgba(26, 34, 48, 0.4);
              font-size: 12px;
            `}
            key={2}
          >
            只支持开始日期和结束日期区间跨度最大为90天的查询
          </p>,
        ]}
      />
      {readResult?.result &&
        typeof readResult.result === 'object' &&
        readResult.result}
    </Card>
  );
};

export default StationLetterTable;
