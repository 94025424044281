import {css} from '@emotion/react';
import {Select} from 'antd';
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import {MicroDataQueryContext} from '../..';
import {
  EXPORT_TABLE_LOG,
  GET_TABLE_LOG,
} from '../../../../../api/dataManagement/wideTable2';
import {LogTable} from '../../../../../components/OperationLog/LogTable';
import dayjs from '../../../../../helpers/extendedDayjs';
import {download} from '../../../../../helpers/request/download';
import {formatDuring} from '../../../../../helpers/utils';
import useModalControl from '../../../../../hooks/useModalControl';
import FailReasonDetailModal from './FailReasonDetailModal';

const RUN_STATUS_MAP = new Map([
  [0, '队列中'],
  [1, '正在执行'],
  [2, '执行成功'],
  [3, '执行失败'],
]);

const runStartOptions = () => {
  const options = [];
  RUN_STATUS_MAP.forEach((key, val) => {
    options.push({value: val, label: key});
  });
  return options;
};

export default function LogDetailTable(props) {
  const {wideOverview} = useContext(MicroDataQueryContext);

  const [refreshFlag, setRefreshFlag] = useState(1);
  const [failReason, setFailReason] = useState('');
  const [runStatus, setRunStatus] = useState(null);

  const [searchParams] = useSearchParams();

  const tableRef = useRef();

  const failReasonModalControl = useModalControl();

  const wideId = useMemo(() => {
    return searchParams.get('wideId');
  }, [searchParams]);

  const columns = [
    {
      title: '触发时间',
      width: 200,
      dataIndex: 'createTime',
    },
    {
      title: '更新内容',
      dataIndex: 'logContent',
    },
    {
      title: '执行人',
      dataIndex: 'username',
    },
    {
      title: '运行状态',
      dataIndex: 'runStatus',
      render: (val) => {
        return RUN_STATUS_MAP.get(val);
      },
    },
    {
      title: '同步耗时',
      dataIndex: 'usedTime',
      render: (val) => {
        return formatDuring(val);
      },
    },
    {
      title: '同步开始时间',
      dataIndex: 'beginTime',
    },
    {
      title: '同步结束时间',
      dataIndex: 'endTime',
    },
    {
      title: '操作',
      dataIndex: 'failReason',
      render: (val, row) => {
        return val && row.runStatus === 3 ? (
          <span
            css={css`
              cursor: pointer;
            `}
            onClick={() => {
              setFailReason(val);
              failReasonModalControl._toggle(true);
            }}
          >
            失败原因
          </span>
        ) : null;
      },
    },
  ];

  const [queryTime, setQueryTime] = useState([
    dayjs().subtract(90, 'day'),
    dayjs(),
  ]);
  function onExportClick() {
    download(
      EXPORT_TABLE_LOG,
      {
        beginCreateTime: queryTime[0].startOf('day').format('YYYY-MM-DD'),
        endCreateTime: queryTime[1].endOf('day').format('YYYY-MM-DD'),
        wideId,
        ...(runStatus === null ? {} : {runStatusList: runStatus}),
      },
      `${
        wideOverview?.tableShowName || wideOverview?.tableNameEn
      }_${wideId}_${queryTime[0].format('YYYY-MM-DD')}_${queryTime[1].format(
        'YYYY-MM-DD'
      )}.xlsx`
    );
    return {};
    // return {
    //   url: EXPORT_TABLE_LOG,
    //   params: {
    //     beginCreateTime: queryTime[0].startOf('day').format('YYYY-MM-DD'),
    //     endCreateTime: queryTime[1].endOf('day').format('YYYY-MM-DD'),
    //     tableId,
    //     ...(runStatus === null ? {} : {runStatusList: runStatus}),
    //   },
    //   filename: `${
    //     wideOverview?.tableShowName || wideOverview?.tableNameEn
    //   }_${tableId}_${queryTime[0].format('YYYY-MM-DD')}_${queryTime[1].format(
    //     'YYYY-MM-DD'
    //   )}.xlsx`,
    // };
  }

  useEffect(() => {
    if (searchParams.get('wideId')) {
      setRefreshFlag(Date.now());
    } else {
      tableRef.current?.setDataSource([]);
    }
  }, [searchParams]);

  return (
    <>
      <div style={{height: 10}} />
      <LogTable
        clearFilter={false}
        columns={columns}
        css={css`
          th.ant-table-cell {
            padding: 3px 0 0 10px !important;
          }
          td.ant-table-cell {
            padding: 4.5px 0 4.5px 10px !important;
          }
        `}
        exportTwiceCheck={false}
        extra={() => {
          return (
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 15px;
              `}
            >
              <p>执行状态:</p>
              <Select
                allowClear
                options={runStartOptions()}
                size='small'
                style={{width: 215}}
                onChange={(value) => setRunStatus(value)}
              />
            </div>
          );
        }}
        extraParamsKey={{
          startTime: 'beginCreateTime',
          endTime: 'endCreateTime',
        }}
        getLogApi={
          wideId && wideId !== 'undefined' && wideId !== 'null'
            ? GET_TABLE_LOG
            : null
        }
        handleTimeChange={setQueryTime}
        hasTips={false}
        pagination={{pageSize: 20}}
        queryParams={{
          wideId,
          ...(runStatus === null ? {} : {runStatusList: runStatus}),
        }}
        recordRecurrent={false}
        ref={tableRef}
        refresh={false}
        refreshFlag={refreshFlag}
        restHeight={166}
        searchParamsUsable={false}
        storeColumnWidthKey='LogDetailTable'
        onExportClick={onExportClick}
      />
      <FailReasonDetailModal
        failReason={failReason}
        modalControl={failReasonModalControl}
      />
    </>
  );
}
