/* eslint-disable consistent-return */
import {css} from '@emotion/react';
import {Modal, notification, Spin, Table, Tabs, Tree} from 'antd';
import {useAtom} from 'jotai';
import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';

import {MicroDataQueryContext} from '../..';
import {
  getWideQuoteList,
  moveTableOrFolder,
} from '../../../../../api/dataManagement/wideTable';
import {createSyncJob} from '../../../../../api/dataQuery/microDataQuery';
import {blockRouterAtom} from '../../../../../atoms/commonAtom';
import {RenameModal} from '../../../../../components/GlobalModal/RenameModal';
import WarningModal from '../../../../../components/GlobalModal/WarningModal';
import IconFont from '../../../../../components/IconFont';
import {routeHistory} from '../../../../../helpers/history';
import {
  deepClone,
  dfsTransFn,
  filterTree,
  findIndexNodeInTree,
  getClientHeight,
  loop,
} from '../../../../../helpers/utils';
import useModalControl from '../../../../../hooks/useModalControl';
import useSafeState from '../../../../../hooks/useSafeState';
import SaveTableModal, {
  defaultFrequency,
} from '../../../MicroIndex/components/SaveTableModal';
import {treeFilter} from '../../helpers';
import {useApi} from '../../hooks/useApi';
import BatchChooseWide from '../BatchChooseWide';
import ChooseTargetFolder from '../ChooseTargetFolder';
import {iconMap} from '../MicroDataQueryInfo/WideTableInfo';
import MenuTreeNode from './MenuTreeNode';
import MousePositionModal from './MousePositionModal';

// eslint-disable-next-line no-unused-vars
const dotStyle = css`
  position: relative;
  padding-left: 16px;
  &::before {
    content: '';
    position: absolute;
    left: 4px;
    top: 8px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #2878ff;
  }
`;

const {DirectoryTree} = Tree;

const treeNodeTypes = new Map([
  [
    '文件夹',
    {
      title: '删除文件夹',
      content: '是否确认删除文件夹?',
      errMsg: '文件夹下存在数据表，请清空文件夹后删除',
    },
  ],
  [
    '宽表',
    {
      title: '移除数据表',
      content: (quoteData) => {
        const tabs = Object.keys(quoteData);
        const tabData = Object.values(quoteData);
        const columns = [
          {dataIndex: 'objId', title: 'ID', width: 150},
          {dataIndex: 'name', title: '名称'},
        ];

        return (
          <div style={{maxHeight: 600, overflow: 'auto'}}>
            <div>
              {tabs.length > 0
                ? '当前表被以下内容引用，删除此表将导致内容无法正常显示或报错，请解除引用后再删除。'
                : '移除后,原本有权限的用户也将无法查看该数据表,是否确认移除数据表?'}
            </div>
            <Tabs defaultActiveKey={tabs[0]} type='line'>
              {tabs.map((tabItem, tabIndex) => (
                <Tabs.TabPane
                  key={tabItem}
                  tab={`${tabItem} ${tabData[tabIndex].length}`}
                >
                  <Table
                    bordered
                    virtual
                    columns={columns}
                    dataSource={tabData[tabIndex]}
                    pagination={false}
                    scroll={{height: 300}}
                    size='small'
                  />
                </Tabs.TabPane>
              ))}
            </Tabs>
          </div>
        );
      },
      errMsg: '该数据表被使用于其他宽表的创建，无法删除',
    },
  ],
  [
    '草稿',
    {
      title: '删除草稿',
      content: '删除草稿后内容将丢失，且无法找回，是否确认删除草稿？',
    },
  ],
]);

const fileIconSize = 20;

export const filterNoViewAuth = (treeData, auth) =>
  treeData.filter((item, k) => {
    if (item?.children.length) {
      filterNoViewAuth(item.children, auth);
    }
    // 根据是否需要权限来做查看权限过滤
    if (!auth) {
      return true;
    }
    return !!item?.viewPerm;
  });

export const generateIcon = (org, expanded, disabled = false) => {
  const color = disabled ? '#00000040' : '#4D5EFF';

  // 草稿文件夹 draftFlag = 0 and pathId = null and parentId === -1
  if (org.draftFlag === 0 && org.pathId === null && org.parentId === -1) {
    return (
      <IconFont
        style={{color: '#4D5EFF', marginTop: 2, fontSize: fileIconSize}}
        type={iconMap.draftFolder.icon}
      />
    );
  }

  // 草稿文件 draftFlag = 0 and pathId = null && parentId !== -1
  if (org.draftFlag === 0 && org.parentId !== -1) {
    return (
      <IconFont
        style={{color: '#4D5EFF', marginTop: 2, fontSize: fileIconSize}}
        type={iconMap.draftFile.icon}
      />
    );
  }

  // 我的收藏时：
  // pathId为空代表文件夹
  // pathId不为空时：
  // tableType:1:创建的宽表2：同步的数据表3：直取的数据表4：上传的数据表
  if (org.pathId === null || org.pathType === 1) {
    return (
      <IconFont
        style={{color, marginTop: 2, fontSize: fileIconSize}}
        type={expanded ? iconMap.expand.icon : iconMap.shrink.icon}
      />
    );
  }

  // 数据库时：
  // pathType:1:文件夹2：数据库3：数据表
  // pathType为2时：
  // tableType:1:创建的宽表2：同步的数据表3：直取的数据表4：上传的数据表
  if (org.pathType === 2) {
    return (
      <IconFont
        style={{color, marginTop: 2, fontSize: fileIconSize}}
        type={iconMap.database.icon}
      />
    );
  }

  return (
    <IconFont
      style={{
        color: iconMap?.[`wideIcon${org?.tableType}`]?.color,
        marginTop: 2,
        fontSize: fileIconSize,
      }}
      type={iconMap?.[`wideIcon${org?.tableType}`]?.icon}
    />
  );
};

const MenuTree = (
  {
    needPermission = false,
    onlyFolder = false,
    moveFolderModalControl,
    // 点击选中的节点
    onSelectCallBack = (v) => v,
    // dropdown下拉操作时的节点
    setOperateNodeInfo,
    followWideTableModalControl,
    extraActions,
    generateExtraActions,
    treeApi,
    onlyView,
    checkable = false,
    isWideList = false,
    draggable = false,
    addFolderApi,
    wideIdProp,
    afterNewFolder,
    selectFolderable = true,
    selectable = true,
    notEditFeature = false,
    defaultExpandAllKeys = false,
    onSearch = (v) => v,
    ...rest
  },
  ref
) => {
  const {clearPrevTableInfo, setTableName, activeKey, setWideOverview} =
    useContext(MicroDataQueryContext);

  const apis = useApi();

  const [loading, setLoading] = useSafeState(false);

  const [top, setTop] = useSafeState(0);
  const [left, setLeft] = useSafeState(0);
  const [content, setContent] = useSafeState('');

  const [delType, setDelType] = useSafeState('文件夹');
  const [treeData, setTreeData] = useSafeState([]);
  const [dataSource, setDataSource] = useSafeState([]);
  const [expandKeys, setExpandKeys] = useSafeState([]);
  const [selectKeys, setSelectKeys] = useSafeState('');
  const [curNodeInfo, setCurNodeInfo] = useSafeState({});
  const [staticTreeData, setStaticTreeData] = useSafeState([]);

  const [batchMoveTree, setBatchMoveTree] = useSafeState([]);

  const [isSavingSync, setIsSavingSync] = useSafeState(false);
  const [dragTipsFlag, setDragTipsFlag] = useSafeState(false);
  const [curDragNode, setCurDragNode] = useSafeState({});

  const saveTableModalRef = useRef();
  const batchChoosedWidesRef = useRef();

  const [blockRouter] = useAtom(blockRouterAtom);

  // const [pageInfo] = useAtom(pagePermsAtom);
  // const {pageCode} = pageInfo;
  // const userInfo = useUserInfo();

  const renameModalControl = useModalControl();
  const addFolderModalControl = useModalControl();
  const notDeleteModalControl = useModalControl();
  const batchMoveModalControl = useModalControl();
  const creatSyncModalControl = useModalControl();
  const batchChooseModalControl = useModalControl();
  const cancelAttentionModalControl = useModalControl();

  // eslint-disable-next-line no-unused-vars
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const ID_KEY = React.useMemo(() => {
    return activeKey === '1' ? 'pathId' : 'id';
  }, [activeKey]);

  const wideId = wideIdProp ?? searchParams.get('wideId');

  const treeHeight = useMemo(() => {
    return getClientHeight(110);
  }, []);

  const handleExpand = useCallback(
    (flag, tree = dataSource) => {
      if (flag) {
        const keys = [];
        const copyTree = deepClone(tree);

        dfsTransFn(copyTree, (node) => {
          keys.push(`${node[ID_KEY] ?? node.id}`);
        });
        setExpandKeys(keys);
      } else {
        setExpandKeys([]);
      }
    },
    [dataSource, setExpandKeys, ID_KEY]
  );
  const getCollectTreeList = async () => {
    try {
      setLoading(true);

      const res = treeApi
        ? await treeApi({isWideList})
        : await apis.treeList({isWideList});
      if (res?.code === 200) {
        setTreeData(res?.data ?? []);
        setStaticTreeData(res?.data ?? []);

        if (defaultExpandAllKeys) {
          handleExpand(!!wideId, res?.data ?? []);
        }
        if (wideId) {
          setSelectKeys([wideId]);

          dfsTransFn(res?.data ?? [], (node) => {
            if (node.tableType && node[ID_KEY] === +wideId) {
              searchParams.set('tableId', node.tableId);
              setSearchParams(searchParams);
              setTableName(node.name);
              setCurNodeInfo({...node});
              onSelectCallBack({...node});
            }
          });
        }
      }
    } catch (err) {
      throw new Error('getPathList request failed');
    } finally {
      setLoading(false);
      onSearch();
    }
  };

  // id: 数据库TAB中的节点id
  // pathId: 数据库TAB中的节点id，被收藏后变成pathId
  // collectId：数据表收藏ID
  // wideId = pathId
  // tableId: 数据源表ID
  // dbId: 数据源库ID

  /**
   * 刷新页面并更新搜索参数
   * @param {string} _wideId - 宽表ID
   * @param {number} tableType - 表类型
   * @param {number} dbId - 数据库ID
   * @param {number} tableId - 表ID
   * @param {boolean} isDraftFile - 是否为草稿文件
   * @returns {void}
   */
  function freshPage(_wideId, tableType, dbId, tableId, isDraftFile = false) {
    if (!blockRouter) {
      clearPrevTableInfo();
    }
    let query = '';
    const _t = Date.now();

    searchParams.set('_t', _t);
    searchParams.set('tableType', tableType);
    searchParams.set('dbId', dbId);
    searchParams.set('tableId', tableId);
    searchParams.set('isDraftFile', isDraftFile);
    searchParams.delete('pageNum');
    searchParams.delete('pageSize');

    searchParams.forEach((value, key) => {
      if (key !== 'wideId' && key !== 'dbId') {
        query += `${key}=${value}&`;
      }
    });

    setSearchParams(searchParams);

    routeHistory.push({
      pathname: location.pathname,
      search: `?${query}${selectable ? `wideId=${_wideId}` : ''}&dbId=${dbId}`,
    });
  }

  const handleRename = (folderInfo) => {
    setCurNodeInfo(folderInfo);
    renameModalControl._toggle(true);
  };

  const handleRenameConfirm = async (name, id, cb) => {
    const trimName = name.trim();
    if (trimName) {
      try {
        const res = await apis.renameFolder({id, name: trimName});
        const {code} = res;
        if (code === 200) {
          const isFolder =
            (curNodeInfo?.pathId === null || curNodeInfo?.pathType === 1) &&
            curNodeInfo.draftFlag !== 0;

          await getCollectTreeList();

          if (!isFolder) {
            freshPage(
              curNodeInfo[ID_KEY],
              curNodeInfo.tableType,
              curNodeInfo.dbId,
              curNodeInfo.tableId
            );
          }

          renameModalControl._toggle(false);
        }
      } catch (error) {
        cb(error);
      }
    } else {
      renameModalControl._toggle(false);
    }
  };

  const cancelAttentionHandle = (info) => {
    setCurNodeInfo(info);
    cancelAttentionModalControl._toggle(true);
  };

  const cancelAttentionConfirm = async () => {
    try {
      const res = await apis.cancelCollect({
        id: activeKey === '2' ? curNodeInfo.collectPathId : curNodeInfo?.id,
      });
      if (res?.code === 200) {
        await getCollectTreeList();
        cancelAttentionModalControl._toggle(false);
      }
    } catch (error) {
      if (error.data.code === 512) {
        notification.error({description: error.data.msg});
      }
      throw new Error('unFollow wideTable failed');
    }
  };

  const moveFolderHandle = (info) => {
    setCurNodeInfo(info);
    setOperateNodeInfo(info);
    moveFolderModalControl._toggle(true);
  };

  const handleNewFolder = () => {
    addFolderModalControl._toggle(true);
  };

  const handleAddFolderConfirm = async (name, id, cb) => {
    const trimName = name.trim();
    try {
      const req = addFolderApi ? addFolderApi : apis.addFolder;
      const res = await req({parentId: id, name: trimName});
      const {code} = res;
      if (code === 200) {
        await getCollectTreeList();
        if (afterNewFolder) {
          afterNewFolder();
        }
        addFolderModalControl._toggle(false);
      }
    } catch (error) {
      cb(error);
    }
  };

  const removeFolderHandle = async (info, removeNodeType = '文件夹') => {
    const opt = treeNodeTypes.get(removeNodeType);
    const wideQuoteRes =
      removeNodeType === '宽表' ? await getWideQuoteList(info[ID_KEY]) : {};

    const {data: wideQuoteData = {}} = wideQuoteRes;
    const hasData = Object.keys(wideQuoteData)?.length > 0;

    setDelType(removeNodeType);

    Modal.confirm({
      title: opt.title,
      width: hasData ? 666 : 416,
      content:
        typeof opt.content === 'string'
          ? opt.content
          : opt.content(wideQuoteData),
      centered: true,
      okButtonProps: {
        style: {
          display: hasData ? 'none' : 'inline-block',
        },
        disabled: hasData,
      },
      onOk: async (close) => {
        try {
          const res =
            removeNodeType === '文件夹'
              ? await apis.removeFolder({id: info.id})
              : await apis.removeTable({ids: [info[ID_KEY]]});

          const curSearchParams = new URLSearchParams(window.location.search);

          const isSelf =
            curSearchParams.get('wideId') === String(info.pathId ?? info.id);

          if (res?.code === 200) {
            if (isSelf && ['宽表', '草稿'].includes(removeNodeType)) {
              setCurNodeInfo({});
              setWideOverview({});

              routeHistory.push({
                pathname: location.pathname,
                search: `?tabIndex=${curSearchParams.get('tabIndex') ?? 1}`,
              });
            }

            await getCollectTreeList();
          } else {
            notDeleteModalControl._toggle(true);
          }
        } catch (error) {
          notDeleteModalControl._toggle(true);
        }
        close();
      },
    });
  };

  const attentionWideTableHandle = (info) => {
    setOperateNodeInfo(info);
    followWideTableModalControl._toggle(true);
  };

  const showBatchChoose = async (actionInfo) => {
    setCurNodeInfo(actionInfo);
    try {
      const res = await apis.dbFolderTableTree({
        tablePathId: actionInfo[ID_KEY],
      });
      if (res?.code === 200) {
        setBatchMoveTree(res?.data ?? []);

        batchChooseModalControl._toggle(true);
      } else {
        batchChooseModalControl._toggle(false);
      }
    } catch (err) {
      batchChooseModalControl._toggle(false);
    }
  };

  const createSyncTaskHandle = (p) => {
    setCurNodeInfo(p);
    creatSyncModalControl._toggle(true);
  };

  const mapTree = (org) => {
    const haveChildren =
      Array.isArray(org?.children) && org?.children?.length > 0;

    const _extraActions = generateExtraActions
      ? generateExtraActions(org, createSyncTaskHandle)
      : extraActions;

    const disabled = (needPermission && !org?.managePerm) || org?.delFlag === 1;

    return {
      ...org,
      title: (
        <MenuTreeNode
          attentionWideTableHandle={attentionWideTableHandle}
          batchMoveTableHandle={showBatchChoose}
          cancelAttentionHandle={cancelAttentionHandle}
          createSyncTask={createSyncTaskHandle}
          disabled={disabled}
          extraActions={_extraActions}
          handleRename={handleRename}
          info={{...org}}
          moveFolderHandle={moveFolderHandle}
          onlyView={
            onlyView ? onlyView : !!(generateExtraActions && !_extraActions)
          }
          removeFolderHandle={removeFolderHandle}
        />
      ),
      disabled,
      key: `${org?.[ID_KEY] ?? org?.id}`,
      children: haveChildren ? org?.children?.map((i) => mapTree(i)) : [],
      icon: ({expanded}) => {
        return generateIcon(org, expanded, disabled);
      },
    };
  };

  const renderTree = (data) => {
    let filterData;
    if (onlyFolder) {
      if (typeof onlyFolder === 'function') {
        filterData = treeFilter(data, onlyFolder);
      } else {
        filterData = treeFilter(data, (node) => {
          return (
            (node?.pathId === null || node?.pathType === 1) &&
            node?.draftFlag !== 0
          );
        });
      }
    } else {
      filterData = data;
    }

    const mapData = filterData.map((org) => mapTree(org));
    // 过滤无查看权限数据
    const validMapData = filterNoViewAuth(mapData, needPermission);

    setDataSource(validMapData);
  };

  const onExpand = (expandedKeys) => {
    // 展开事件
    setExpandKeys(expandedKeys);
  };

  const onSelect = (keys, info) => {
    const {tableType, pathType, pathId, dbId, tableId} = info?.node || {};
    // 文件夹是否可以被选中
    if (!selectFolderable && (pathType === 2 || pathType === 1)) return;
    setCurNodeInfo(info.node);

    // 当点击选中的是宽表且不是文件夹时刷新页面，更新状态
    if (tableType && (pathType === 3 || pathId === null) && !onlyView) {
      // eslint-disable-next-line prettier/prettier
      const isDraftFile = info?.node?.draftFlag === 0 && info?.node?.parentId !== -1;

      freshPage(
        // 宽表id
        info.node[ID_KEY],
        // 宽表类型
        tableType,
        dbId,
        tableId,
        isDraftFile
      );
    }
    setSelectKeys(keys);
    onSelectCallBack(keys, info);
  };

  const batchChoosedWideConfirm = (batchWideIds) => {
    batchChoosedWidesRef.current = batchWideIds;
    batchMoveModalControl._toggle(true);
  };

  const batchMoveHandleConfirm = async (targetFolder) => {
    try {
      const res = await apis.batchMoveTo({
        pathIds: batchChoosedWidesRef.current,
        targetId: targetFolder?.id,
      });
      if (res?.code === 200) {
        await getCollectTreeList();
        batchMoveModalControl._toggle(false);
        batchChooseModalControl._toggle(false);
      }
    } catch (error) {
      if (error.data.code === 512) {
        notification.error({description: error.data.msg});
      }
      throw new Error('BatchMoveHandleConfirm failed');
    }
  };

  const onBatchDeleteHandle = async (delIds) => {
    try {
      const res = await apis.removeTable({ids: delIds});
      if (res?.code === 200) {
        await getCollectTreeList();
        batchChooseModalControl._toggle(false);
      }
    } catch (error) {
      if (error.data.code === 512) {
        notification.error({description: error.data.msg});
      }
      throw new Error('BatchDeleteHandle failed');
    }
  };

  const saveSyncTask = async (syncFormValues, triggerStatus) => {
    setIsSavingSync(true);
    try {
      const {tableType, dbId, tableId} = curNodeInfo || {};

      await createSyncJob({
        tableName: syncFormValues?.tableName,
        tableNameEn: syncFormValues?.tableNameEn,
        targetPathId: syncFormValues?.pathId,
        tablePathId: curNodeInfo?.[ID_KEY],
        collectPathId: syncFormValues?.collectPathId,
        mail: syncFormValues?.mail,
        sendMailFlag: syncFormValues?.syncResultRemind?.includes(2) || false,
        syncJobType: syncFormValues?.syncJobType,
        jobCronJson: JSON.stringify(
          syncFormValues?.syncJobType === 1 ? '' : syncFormValues?.jobCronJson
        ),
        triggerStatus,
        syncResultRemind: syncFormValues?.syncResultRemind,
      });

      getCollectTreeList();

      freshPage(
        // 宽表id
        curNodeInfo[ID_KEY],
        // 宽表类型
        tableType,
        dbId,
        tableId
      );

      creatSyncModalControl._toggle(false);
    } catch (err) {
      if (err?.data?.code === 512)
        if (err?.data?.errorInputs) {
          saveTableModalRef.current.setFormErrorMsg(
            'tableNameEn',
            err?.data?.msg
          );
          err.data.errorInputs.forEach(({key, message}) => {
            saveTableModalRef.current.setFormErrorMsg(key, message);
          });
        } else {
          notification.error({
            description: err?.data?.msg,
          });
        }
      throw new Error('Create Sync Task Failed');
    } finally {
      setIsSavingSync(false);
    }
  };

  useEffect(() => {
    const _treeData = deepClone(treeData);
    renderTree(_treeData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData, generateExtraActions]);

  useImperativeHandle(ref, () => ({
    newFolder: () => {
      handleNewFolder();
    },
    getTreeData: () => {
      return deepClone(treeData);
    },
    getOriginList: () => {
      return deepClone(staticTreeData);
    },
    handleExpand,
    refresh: () => {
      getCollectTreeList();
    },
    handleTreeData: (data) => {
      setTreeData(data);
    },
    curSelectNode: curNodeInfo,
  }));

  useEffect(() => {
    setCurNodeInfo({});
    getCollectTreeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey]);

  const onDrop = async (info) => {
    const dropKey = info.node.id;
    const dragKey = info.dragNode.id;
    const dropPos = info.node.pos.split('-');
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1

    const newTreedata = [...treeData];

    // Find dragObject
    let dragObj;
    loop(newTreedata, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (info.dropToGap) {
      let ar = [];
      let i;
      loop(newTreedata, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        // Drop on the top of the drop node
        ar.splice(i, 0, dragObj);
      } else {
        // Drop on the bottom of the drop node
        ar.splice(i + 1, 0, dragObj);
      }
    } else {
      // Drop on the content
      loop(newTreedata, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        item.children.unshift(dragObj);
      });
    }

    try {
      const afterMoveParams = {
        pathId: info.dragNode.id,
        targetParentId: null,
        prePathId: null,
        type: Number(searchParams.get('tabIndex') ?? 1),
      };

      const {preItem, parentItem} = findIndexNodeInTree(
        newTreedata,
        info.dragNode.id
      );

      if (preItem) afterMoveParams.prePathId = preItem?.id;
      if (parentItem) afterMoveParams.targetParentId = parentItem?.id;
      if (curDragNode.pathType === 3 && parentItem.pathType === 3) {
        notification.error({description: '数据表不能作为数据表的子级'});
        return false;
      }

      setTreeData(newTreedata);

      await moveTableOrFolder(afterMoveParams);

      setTimeout(async () => {
        await getCollectTreeList();

        // 刷新完列表再次展示当前操作节点所在tree
        const keys = [];
        const copyTree = deepClone(treeData);
        // 只默认展开当前表所在父节点
        const curWideTree = filterTree(
          copyTree,
          (item) =>
            String(item.id) === String(info.node.id) ||
            String(item.pathId) === String(info.node.id)
        );
        dfsTransFn(curWideTree, (node) => {
          keys.push(`${node[ID_KEY] ?? node.id}`);
        });
        setExpandKeys(keys);
      }, 100);
    } catch (err) {
      if (err?.data?.code === 512) {
        notification.error({description: err?.data?.msg});
        await getCollectTreeList();
      }
      console.log('drag err', err);
    } finally {
      setDragTipsFlag(false);
    }
  };

  const syncTaskTableData = useMemo(() => {
    return {
      tableName: curNodeInfo?.showName,
      tableNameEn: `ud_${curNodeInfo?.tableName}`,
      pathId: '',
      collectFlag: false,
      collectPathId: '',
      syncJobType: 0,
      jobCronJson: JSON.stringify(defaultFrequency),
      mail: '',
    };
  }, [curNodeInfo]);

  const mergeProps = notEditFeature
    ? {
        draggable: false,
        ...rest,
      }
    : {
        allowDrop: ({dropNode}) => {
          return searchParams.get('tabIndex') === '2'
            ? dropNode?.managePerm === true &&
                dropNode?.dbId === curDragNode?.dbId
            : !(
                dropNode?.draftFlag === 0 &&
                dropNode?.pathId === null &&
                dropNode?.parentId === -1
              );
        },
        draggable: {
          icon: false,
          nodeDraggable: (node) => {
            // console.log(
            //   "node",
            //   node,
            //   node?.pathType !== 2 &&
            //     node?.delFlag !== 1 &&
            //     node?.draftFlag !== 0 &&
            //     (searchParams.get("tabIndex") === "2"
            //       ? node?.managePerm === true
            //       : true)
            // );
            return (
              node?.pathType !== 2 &&
              node?.delFlag !== 1 &&
              node?.draftFlag !== 0 &&
              (searchParams.get('tabIndex') === '2'
                ? node?.managePerm === true
                : true)
            );
          },
        },
        onDragEnter: ({event, node}) => {
          if (curDragNode?.dbId !== node?.dbId) {
            setContent(`只能在同一个数据库内移动`);
            // 去掉悬浮提示框，清爽展示移动目标位置
            setDragTipsFlag(false);
          }
          setLeft(event.clientX + 18);
          setTop(event.clientY + 18);
        },
        onDragLeave: ({event, node}) => {
          if (curDragNode?.dbId !== node?.dbId) {
            setContent(`只能在同一个数据库内移动`);
          }
          setLeft(event.clientX + 36);
          setTop(event.clientY + 36);
        },
        onDragStart: ({event, node}) => {
          console.log('onDragStart node', node);
          setCurDragNode(node);
        },
        onDragEnd: ({event, node}) => {
          setDragTipsFlag(false);
        },
        onDrop,
        ...rest,
      };

  return (
    <>
      <Spin spinning={loading}>
        <DirectoryTree
          blockNode
          showIcon
          virtual
          checkable={checkable}
          css={css`
            /* height: ${treeHeight}px; */
            margin-top: 10px;
            .ant-tree-node-content-wrapper {
              display: flex;
              padding: 0;
              .ant-tree-title {
                flex: 1;
              }
            }
            .ant-tree-indent-unit {
              width: 13px;
            }
            .ant-tree-treenode-selected:before {
              background: #cbcbcb !important;
              border-radius: 2px;
              opacity: 0.2;
            }
            .ant-tree-treenode:before {
              transition: none !important;
            }
            /* .ant-tree-treenode:first-of-type {
              -webkit-user-drag: none;
            } */
            .ant-tree-switcher {
              color: rgba(26, 34, 48, 0.7) !important;
            }
          `}
          defaultExpandAll={false}
          expandAction={false}
          expandedKeys={expandKeys}
          height={treeHeight}
          selectable={selectable}
          selectedKeys={selectKeys}
          treeData={dataSource}
          onExpand={onExpand}
          onSelect={onSelect}
          {...mergeProps}
        />
      </Spin>
      <RenameModal
        confirm={handleRenameConfirm}
        folderInfo={curNodeInfo}
        keyName='pathName'
        modalControl={renameModalControl}
      />

      <RenameModal
        isAdd
        confirm={handleAddFolderConfirm}
        folderInfo={curNodeInfo}
        modalControl={addFolderModalControl}
      />

      <WarningModal
        confirm={cancelAttentionConfirm}
        description='取消收藏后数据表将从我的收藏中移除,您仍可以在数据库中再次收藏,是否确认取消收藏?'
        modalControl={cancelAttentionModalControl}
        title='取消收藏'
        width={397}
      />

      <WarningModal
        description={treeNodeTypes.get(delType).errMsg}
        ensureBtnText='返回'
        isNeedCancelBtn={false}
        modalControl={notDeleteModalControl}
        title={treeNodeTypes.get(delType).title}
        width={370}
      />

      {/* 批量选择数据表 */}
      <BatchChooseWide
        batchDel={onBatchDeleteHandle}
        confirm={batchChoosedWideConfirm}
        dataSource={batchMoveTree}
        modalControl={batchChooseModalControl}
      />

      {/* 批量选择后移动 */}
      <ChooseTargetFolder
        confirm={batchMoveHandleConfirm}
        modalControl={batchMoveModalControl}
        title='所选数据表'
        treeApi={() => apis.folderList({tablePathId: curNodeInfo.id})}
      />

      {/* 同步到其它库-创建同步任务 */}
      <SaveTableModal
        isSaveAs
        isSaving={isSavingSync}
        isWide={false}
        ref={saveTableModalRef}
        requestTablePathId={curNodeInfo?.[ID_KEY]}
        tableData={syncTaskTableData}
        tablePathId={curNodeInfo?.id}
        title='创建同步任务'
        type='add'
        visible={creatSyncModalControl.visible}
        onCancel={() => creatSyncModalControl._toggle(false)}
        onOk={saveSyncTask}
      />

      <MousePositionModal
        content={content}
        left={left}
        top={top}
        visible={dragTipsFlag}
      />
    </>
  );
};

export default forwardRef(MenuTree);
