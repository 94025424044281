import {css} from '@emotion/react';
import {Button, Checkbox, notification, Space} from 'antd';
import {useAtom} from 'jotai';
import React, {useMemo, useEffect} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {usePrevious} from 'react-use';

import {
  createSheetCfg,
  parseCalIndex,
  saveAsSheetCfg,
  tempSaveSheetCfg,
  updateSheetCfg,
} from '../../../../../../api/dataManagement/microIndex';
import {
  defaultLogicEditStatus,
  logicEditAtom,
} from '../../../../../../atoms/logicEditAtom';
import ComputedFieldEditor from '../../../../../../components/ComputedFieldEditor';
import {insertPlaceholder} from '../../../../../../components/ComputedFieldEditor/components/editorContentConfig';
import {COLUMN_TYPE} from '../../../../../../components/ComputedFieldEditor/constants';
import {filterAtom} from '../../../../../../components/Filter/atoms/filterAtom';
import FormErrMsg from '../../../../../../components/FormErrMsg';
import WarningModal from '../../../../../../components/GlobalModal/WarningModal';
import IconFont from '../../../../../../components/IconFont';
import {Page} from '../../../../../../components/Page';
import {routeHistory} from '../../../../../../helpers/history';
import {deepClone, equalArray} from '../../../../../../helpers/utils';
import useModalControl from '../../../../../../hooks/useModalControl';
import useSafeState from '../../../../../../hooks/useSafeState';
import ModalConfigIndex from '../../../components/ConfigIndex';
import SaveTableModal from '../../../components/SaveTableModal';
import FieldMatchModal from '../../../components/SaveTableModal/FieldMatchModal';
import SaveWideTableModal from '../../../components/SaveTableModal/SaveWideTableModal';
import TempModal from '../../../components/SaveTableModal/TempModal';
import {validateWideTableColumns} from '../../../helpers';
import {
  fillEmptyVal,
  filterOnlyShowCol4Info,
  sortComputedColumns,
} from '../../helpers';
import {errorHolderColor, specialSplitSymbol} from '../../helpers/constants';
import useDataChangeCheck from '../../hooks/useDataChangeCheck';
import useDataValidate from '../../hooks/useDataValidate';
import useFetchWideTable from '../../hooks/useFetchWideTable';
import useFilterDescription from '../../hooks/useFilterDescription';
import useSymbolSerialFilter from '../../hooks/useSymbolSerialFilter';
import FilterPreviewModal from './components/FilterPreviewModal';
import PreviewTableX from './PreviewTableX';
import PreviewTableY from './PreviewTableY';

const iconColor = 'rgba(26,34,48,0.40)';
const iconActiveColor = '#4D5EFF';

const PreviewTable = ({lastSaveConfig, createWideTableType}) => {
  const [filterParams, setFilterParams] = useAtom(filterAtom);
  const {tableKey, conditions} = filterParams;
  const filterObject = conditions[tableKey];

  const locationHook = useLocation();
  const [searchParams] = useSearchParams();
  const wideId = searchParams.get('wideId');
  const isDraftFile = searchParams.get('isDraftFile');

  const save11ModalControl = useModalControl();
  const cancelModalControl = useModalControl();
  const notSaveWideModalControl = useModalControl();

  const [, checkValidate] = useDataValidate();
  const [, checkChanged] = useDataChangeCheck();

  const {serialfilterConditions} = useSymbolSerialFilter();
  const prevSerialfilterConditions = usePrevious(serialfilterConditions);

  const {updatePreviewTable, checkJoinConditionValidate} = useFetchWideTable();
  const {getFilterRecordDes} = useFilterDescription();

  // const [rebuildTips, setRebuildTips] = useSafeState([]);
  const [wideQuoteInfo] = useSafeState({});
  // eslint-disable-next-line no-unused-vars
  const [loadingSaveUpdateFlag, setLoadingSaveUpdateFlag] = useSafeState(false);

  const [configIndexVisible, setConfigIndexVisible] = useSafeState(false);
  const [saveSyncFlag, setSaveSyncFlag] = useSafeState();
  const [saveModalVisible, setSaveModalVisible] = useSafeState(false);
  const [saveSyncModalVisible, setSaveSyncModalVisible] = useSafeState(false);
  const [saveAsModalVisible, setSaveAsModalVisible] = useSafeState(false);
  const [fieldMatchModalVisible, setFieldMatchModalVisible] =
    useSafeState(false);

  const [tempModalVisible, setTempModalVisible] = useSafeState(false);

  const [isSaving, setIsSaving] = useSafeState(false);

  const [totalStatus, setTotalStatus] = useAtom(logicEditAtom);
  const {
    previewXData,
    previewXOriginColumns,
    previewYData,
    previewTableDirection,
    showHideFields,
    previewAutoUpdate,
    deleteFilterFlag,
    // pathId,
    tableName,
    filterPreviewModalVisible,
    graphData,
    showNoTableStructure,
    matchFieldColumns,
  } = totalStatus;

  const {status, tableStructureList} = graphData;

  const prevPreviewAutoUpdate = usePrevious(previewAutoUpdate);
  const prevTableStructureList = usePrevious(tableStructureList);

  const hidedColumnCount = useMemo(() => {
    const hideLen = previewXOriginColumns.filter((col) => !col?.isShow).length;

    return hideLen;
  }, [previewXOriginColumns]);

  const filterColumnCount = useMemo(() => {
    return serialfilterConditions.filter((c) => {
      const {aliasName, orderType, ...restCondition} = c;

      return Object.keys(restCondition).length > 0;
    }).length;
  }, [serialfilterConditions]);

  const wideQuoteReson = useMemo(() => {
    const {quotes = []} = wideQuoteInfo;
    return quotes.map(
      (q) =>
        `${q.realName}(${q.userName})创建的${
          q.quoteCategory === 1
            ? `${q.tagName}(${q.tagType === 0 ? '公开' : '私有'}_${
                q.tagDynamic === 0 ? '固定' : '动态'
              }标签)(${q.funName})`
            : `${q.calName}计算字段`
        }(${q.funName})`
    );
  }, [wideQuoteInfo]);

  useEffect(() => {
    // deleteFilterFlag 如果是 被删除字段 清除筛选字段 则不更新预览
    if (deleteFilterFlag) {
      setTotalStatus((pre) => ({
        ...pre,
        deleteFilterFlag: false,
      }));
      return;
    }
    if (
      previewAutoUpdate === prevPreviewAutoUpdate &&
      equalArray(serialfilterConditions, prevSerialfilterConditions) &&
      equalArray(tableStructureList, prevTableStructureList)
    ) {
      return;
    }
    if (previewAutoUpdate) {
      const canFetch =
        tableStructureList.length > 0 &&
        serialfilterConditions &&
        checkJoinConditionValidate();

      if (canFetch) {
        updatePreviewTable();
      } else {
        setTotalStatus((pre) => ({
          ...pre,
          previewXData: [],
          previewXOriginColumns: [],
          previewYData: [],
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    previewAutoUpdate,
    serialfilterConditions,
    setTotalStatus,
    tableStructureList,
    deleteFilterFlag,
  ]);

  /* const onUpdatePreviewHandle = () => {
    if (tableStructureList.length > 0) {
      updatePreviewTable();
    } else {
      setTotalStatus((pre) => ({
        ...pre,
        previewXData: [],
        previewXOriginColumns: [],
        previewYData: [],
      }));
    }
  }; */

  const toggleShowHideFields = (e) => {
    const showFlag = e.target.checked;

    setTotalStatus((pre) => {
      return {
        ...pre,
        showHideFields: showFlag,
      };
    });
  };

  const showXPreviewTable = () => {
    const tableXColumn = deepClone(previewYData).sort(
      (a, b) => a.indexRank - b.indexRank
    );

    setTotalStatus((pre) => ({
      ...pre,
      previewXOriginColumns: [...tableXColumn],
      previewTableDirection: 'X',
    }));
  };

  const showYPreviewTable = () => {
    const tableYData = previewXOriginColumns.map((xcol, index) => {
      const tempData = (previewXData || [])
        .map((xdata) => fillEmptyVal(xdata[xcol.dataIndex]))
        .slice(0, 3)
        .join(';');

      return {
        ...xcol,
        tempData,
      };
    });

    setTotalStatus((pre) => ({
      ...pre,
      previewYData: tableYData,
      previewTableDirection: 'Y',
    }));
  };

  const showfilterPreviewModal = () => {
    setTotalStatus((pre) => ({
      ...pre,
      filterPreviewModalVisible: true,
    }));
  };

  // 重置筛选
  const clearFilterHandler = () => {
    setFilterParams({
      ...filterParams,
      conditions: {[tableKey]: {}},
    });
  };

  const jumpPreAndChoose = (newWideId, isTempSave = false) => {
    const wideIdStr = newWideId
      ? `?wideId=${newWideId}&`
      : wideId
      ? `?wideId=${wideId}&`
      : '?';

    const draftStr =
      isTempSave || isDraftFile
        ? `isDraftFile=${isTempSave || isDraftFile}`
        : '';

    const newRoutePath = `${locationHook.pathname
      .split('/')
      .slice(0, -1)
      .join('/')}${wideIdStr}${draftStr}`;

    setTotalStatus({
      ...deepClone(defaultLogicEditStatus),
      hasDelComputedColumns: [],
      modifiedComputedColumns: [],
    });
    clearFilterHandler();
    routeHistory.push(`${newRoutePath}&tabIndex=2`);
  };

  const onCancelHandle = () => {
    const {result} = checkChanged();

    if (result) {
      cancelModalControl._toggle(true);
    } else {
      jumpPreAndChoose();
    }
  };

  const ensureCancelHandle = () => {
    cancelModalControl._toggle(false);

    jumpPreAndChoose();
  };

  // 修改完字段名 替换筛选器里面的字段名
  const replaceFilterAliasName = (filter) => {
    const originFilterInfo = filterOnlyShowCol4Info(
      previewXOriginColumns,
      filter
    );

    const modifiedFilterInfo = originFilterInfo.map((o) => {
      const modifiedColumnAlias = previewXOriginColumns.find(
        (xCol) => xCol.originColumnAlias === o.aliasName
      );
      if (modifiedColumnAlias) {
        return {
          ...o,
          aliasName: modifiedColumnAlias.columnAlias,
        };
      }
      return o;
    });
    return modifiedFilterInfo;
  };

  const replaceFrontParam = () => {
    const originFilterObjectKeys = Object.keys(filterObject || {});
    return originFilterObjectKeys.reduce((acc, cur) => {
      const [, originId] = cur.split(specialSplitSymbol);
      const modifiedColumnAlias = previewXOriginColumns.find(
        (xCol) => xCol.id === Number(originId)
      );

      return {
        ...acc,
        [`${modifiedColumnAlias?.columnAlias}${specialSplitSymbol}${originId}`]:
          filterObject[cur],
      };
    }, {});
  };

  const replaceCalculatesHandler = async (originCalculates) => {
    let newCalculates = [];

    for (let i = 0; i < originCalculates.length; i++) {
      const {
        formulas = [],
        mentionsData,
        ...rest
      } = JSON.parse(originCalculates[i].formulas);

      const {uniqueAliasList, indexType, pointNum} = originCalculates[i];

      let newOriFormula = rest.oriFormula.split('');

      const newFormulas = formulas.map((f) => {
        if (f.type === COLUMN_TYPE) {
          const parseContent = JSON.parse(f?.content);

          const modifiedFormulas = previewXOriginColumns.find(
            (xCol) => xCol.originColumnAlias === parseContent?.calAlias
          );
          if (modifiedFormulas) {
            return {
              ...f,
              pointNum,
              content: JSON.stringify({
                ...parseContent,
                name: parseContent.id
                  ? modifiedFormulas.columnAlias
                  : parseContent.name,
                calAlias: modifiedFormulas.columnAlias,
              }),
            };
          }
        }
        return {
          pointNum,
          ...f,
        };
      });

      let diff = 0;
      const newMentionsData = mentionsData.map((m, index) => {
        const modifiedFormulas = previewXOriginColumns.find(
          (xCol) => xCol.originColumnAlias === m?.columnAlias
        );
        if (modifiedFormulas) {
          const ret = {
            ...m,
            start: index === 0 ? m.start : m.start + diff,
            columnAlias: modifiedFormulas.columnAlias,
            curShowName: modifiedFormulas.curShowName,
            name: modifiedFormulas.curShowName,
          };
          diff += modifiedFormulas.curShowName.length - m.curShowName.length;
          return ret;
        }
        return m;
      });

      for (let j = 0; j < mentionsData.length; j++) {
        const col = mentionsData[j];
        const oriLength = col.curShowName.length;
        const restLength = oriLength - 1;
        for (let k = j + 1; k < mentionsData.length; k++) {
          const nextCol = mentionsData[k];
          nextCol.start -= restLength;
        }
        newOriFormula.splice(
          col.start,
          oriLength,
          newMentionsData[j].curShowName
        );
      }

      const {data: newFormula} = await parseCalIndex({
        elements: newFormulas,
        pointNum,
      });

      const newUniqueAliasList = uniqueAliasList.map((uniqueItem) => {
        const modifiedUniqueAliasList = previewXOriginColumns.find(
          (xCol) => xCol.originColumnAlias === uniqueItem
        );
        if (modifiedUniqueAliasList) {
          return modifiedUniqueAliasList.columnAlias;
        }
        return uniqueItem;
      });

      newOriFormula = newOriFormula.join('');

      newCalculates.push({
        ...originCalculates[i],
        formulas: JSON.stringify({
          ...rest,
          formulas: newFormulas,
          mentionsData: newMentionsData,
          formula: newFormula,
          pointNum:
            indexType.includes('bigint') || indexType.includes('decimal')
              ? pointNum
              : null,
          oriFormula: newOriFormula,
          placeholder: insertPlaceholder(newOriFormula, newMentionsData),
        }),
        uniqueAliasList: newUniqueAliasList,
        formula: newFormula,
        pointNum:
          indexType.includes('bigint') || indexType.includes('decimal')
            ? pointNum
            : null,
      });
    }

    // 遍历计算字段
    // 遍历到当前字段
    // 判断有无其他字段引用了当前字段
    // 有
    //

    newCalculates = sortComputedColumns(newCalculates);
    for (let i = 0; i < newCalculates.length; i++) {
      const col = newCalculates[i];
      const parsedFormulas = JSON.parse(col.formulas);
      const formulas = parsedFormulas.formulas;
      formulas.forEach((element) => {
        if (element.type === COLUMN_TYPE) {
          const content = JSON.parse(element.content);
          if (!content.id) {
            // 计算字段
            const matchCol = newCalculates.find(
              (col2) => col2.calAlias === content.calAlias
            );
            content.name = matchCol.formula;
          }
          element.content = JSON.stringify(content);
        }
      });
      const mentionsData = parsedFormulas.mentionsData;
      mentionsData.forEach((element) => {
        if (!element.id) {
          // 计算字段
          const matchCol = newCalculates.find(
            (col2) => col2.calAlias === element.columnAlias
          );
          element.formula = matchCol.formula;
        }
      });
      col.formulas = JSON.stringify(parsedFormulas);
      col.uniqueAliasList = mentionsData.map((item) => item.columnAlias);
      const newFormula = await parseCalIndex({
        elements: formulas,
        pointNum: col.indexType.includes('decimal') ? col.pointNum : null,
      }).then((_res) => _res.data);
      // eslint-disable-next-line require-atomic-updates
      col.formula = newFormula;
    }

    return newCalculates;
  };

  const getSaveParmas = async (saveType) => {
    const tableList = [...tableStructureList];

    const filterRecord = await getFilterRecordDes();

    const filter = {
      filterInfo: JSON.stringify(
        saveType === '暂存'
          ? serialfilterConditions
          : replaceFilterAliasName(serialfilterConditions)
      ),
      frontParam: JSON.stringify(replaceFrontParam() ?? {}),
      indexFilters: filterOnlyShowCol4Info(previewXOriginColumns, filterRecord)
        .map(({aliasName, record}) => ({
          columnAlias: aliasName,
          detail: record,
        }))
        .map((o) => {
          const modifiedColumnAlias = previewXOriginColumns.find(
            (xCol) => xCol.originColumnAlias === o.columnAlias
          );
          if (modifiedColumnAlias) {
            return {
              ...o,
              columnAlias: modifiedColumnAlias.columnAlias,
            };
          }
          return o;
        }),
    };

    const columnList = previewXOriginColumns
      .filter((col) => !col.formula)
      .map(
        ({
          calId,
          curShowName,
          id,
          indexRank,
          tableId,
          title,
          _title,
          columnAlias,
          nameSource,
          isShow,
          indexId,
          indexType,
          decimalPlaces,
          sortKeys,
          sourceColumnId,
        }) => {
          const ret = {
            calId,
            curShowName,
            id,
            indexRank,
            tableId,
            columnAlias,
            isShow,
            sortKeys,
            sourceColumnId,
            dataType: indexType, // 字段类型
            // 当前显示名称来源，1：重命名，2：字段显示名，3：字段注释，4：字段名称
            nameSource: title === _title ? nameSource : 1,
          };
          if (indexType === 'decimal') {
            ret.decimalPlaces = decimalPlaces ?? 2;
          }
          if (saveType === '保存' && indexId) {
            ret.indexId = indexId;
          }
          return ret;
        }
      );

    const calculates = previewXOriginColumns
      .filter((col) => col.formula)
      .map(
        ({
          indexId,
          calId = '',
          columnAlias = '',
          curShowName = '',
          formula = '',
          formulas = '',
          calOriName = '',
          indexRank = 0,
          indexType = '',
          nameSource,
          pointNum,
          title,
          _title,
          sortKeys,
          sourceColumnId,
          uniqueAliasList = [],
          decimalPlaces,
        }) => {
          const ret = {
            calAlias: columnAlias,
            columnAlias,
            curShowName,
            formula,
            formulas,
            calOriName,
            indexRank,
            indexType,
            // 编辑表格赋值计算字段取小数位位数
            pointNum: decimalPlaces ?? pointNum,
            sourceColumnId,
            uniqueAliasList,
            sortKeys,
            // 当前显示名称来源，1：重命名，2：字段显示名，3：字段注释，4：字段名称, 5: 计算字段
            nameSource: title === _title ? nameSource : 1,
          };
          if (calId) {
            ret.calId = calId;
          }
          if (saveType === '保存' && indexId) {
            ret.indexId = indexId;
          }
          return ret;
        }
      );

    const params = {
      // pathId,
      columnList,
      tableList,
      filter,
      calculates: await replaceCalculatesHandler(calculates),
    };

    if (wideId) {
      params.tablePathId = wideId;
    }

    return params;
  };

  /* 暂存 */
  const onTempSaveHandle = async (_tableName) => {
    setIsSaving(true);
    try {
      const params = await getSaveParmas('暂存');
      const tempSaveRes = await tempSaveSheetCfg({
        ...params,
        tableName: _tableName,
      });
      const {code, data} = tempSaveRes;

      if (code === 200) {
        const newWideId = data?.pathId;
        jumpPreAndChoose(newWideId, true);
      }
    } catch (err) {
      FormErrMsg.disposeErr(null, err);
    } finally {
      setIsSaving(false);
    }
  };

  /* 保存 */
  const doSave = async (config = {}, syncFlag, errCb, isSaveAs) => {
    const isValidate = checkValidate();
    if (!isValidate) return;

    const params = await getSaveParmas('保存');
    const saveAction = isSaveAs
      ? saveAsSheetCfg
      : wideId && status !== 1
      ? updateSheetCfg
      : createSheetCfg;
    setIsSaving(true);
    try {
      const _config = deepClone(config);
      delete _config.collectFlag;
      _config.sendMailFlag = !!_config.mail;
      if (_config.syncJobType === 0 && _config.jobCronJson) {
        _config.jobCronJson = JSON.stringify(_config.jobCronJson);
      } else {
        _config.jobCronJson = null;
      }
      params.triggerStatus = syncFlag ? 1 : 0;

      // 导入数据表 字段匹配功能
      if (_config?.saveType === 2) {
        params.columnList.forEach((col) => {
          matchFieldColumns.forEach((m) => {
            if (`${col.sortKeys}-${col.columnAlias}` === m?.left) {
              col.targetColumnInfo = {
                columnName: m?.columnName,
                curShowName: m?.curShowName,
                dbId: m?.dbId,
                id: m?.id,
                tableId: m?.tableId,
              };
            }
          });
        });

        params.calculates.forEach((calc) => {
          matchFieldColumns.forEach((m) => {
            if (`${calc.sortKeys}-${calc.calAlias}` === m?.left) {
              calc.targetColumnInfo = {
                columnName: m?.columnName,
                curShowName: m?.curShowName,
                dbId: m?.dbId,
                id: m?.id,
                tableId: m?.tableId,
              };
            }
          });
        });
      }

      // 导入数据表，计算字段匹配功能

      const saveRes = await saveAction({...params, ..._config});
      const {code, data} = saveRes;

      // 对已存在的数据表进行修改编辑成功后，系统校验当前数据表被引用图表是否能够正常更新(功能未开发，留接口)
      // 任意一个图表不能正常更新，弹出弹窗O：【无法修改数据表窗体】，操作结束，返回保存前的页面B：【数据表逻辑编辑页面】
      if (code === 200) {
        jumpPreAndChoose(data.pathId);
        if (wideId) {
          notification.success(
            `${tableName}(id: ${data.pathId})的逻辑修改成功`
          );
        }
      } else {
        notification.error(`${tableName}(id: ${data.pathId})的逻辑修改失败`);
      }
    } catch (err) {
      errCb?.(err);
      // FormErrMsg.disposeErr(null, err);
    } finally {
      save11ModalControl._toggle(false);
      setIsSaving(false);
    }
  };

  /* const reqCanRemoveWide = async () => {
    try {
      const res = await getWideQuoteInfo({wideId});
      if (res?.code === 200) {
        setwideQuoteInfo(res?.data ?? {});
        return !res?.data.isQuote;
      }
      return true;
    } catch (err) {
      throw new Error('error');
    }
  }; */

  // ???
  const onSaveHandle = (config, syncFlag, errCb, isSaveAs) => {
    const isValidate = checkValidate();

    if (isValidate) {
      // 由暂存点保存时不需要提示
      if (wideId && status !== 1) {
        /* const {resultDetail} = checkChanged();
        const {
          // columnFieldsChanged,
          canvasTalbleChanged,
          canvasTalbleRelationChanged,
          filterChanged,
          calcFieldChanged,
        } = resultDetail;
        console.log('resultDetail: ', resultDetail); */

        // 保存前 需要查询宽表是否被引用
        // const canSave = await reqCanRemoveWide();

        // if (canSave) {
        /* if (
          canvasTalbleChanged ||
          canvasTalbleRelationChanged ||
          filterChanged ||
          calcFieldChanged
        ) {
          const rebuildSheetTips = [
            ...[canvasTalbleChanged ? '数据表' : ''],
            ...[canvasTalbleRelationChanged ? '表关联' : ''],
            ...[filterChanged ? '筛选字段' : ''],
            ...[calcFieldChanged ? '计算字段' : ''],
          ].filter(Boolean);

          setRebuildTips(rebuildSheetTips);
          save11ModalControl._toggle(true);
        } else {
          doSave(config, isSaveAs, errCb);
        } */
        doSave(config, syncFlag, errCb, isSaveAs);
        /* } else {
          notSaveWideModalControl._toggle(true);
        } */
      } else {
        doSave(config, syncFlag, errCb, isSaveAs);
      }
    }
  };

  const onShowSaveAsModal = () => {
    if (validateWideTableColumns(previewXOriginColumns)) {
      const checkResult = checkValidate();
      if (checkResult) {
        setSaveAsModalVisible(true);
      }
    }
  };

  const onShowSaveModal = () => {
    if (validateWideTableColumns(previewXOriginColumns)) {
      const checkResult = checkValidate();

      if (checkResult) {
        setSaveModalVisible(true);
      }
    }
  };

  const onShowSaveSyncModal = () => {
    if (validateWideTableColumns(previewXOriginColumns)) {
      const checkResult = checkValidate();

      if (checkResult) {
        setSaveSyncModalVisible(true);
      }
    }
  };

  // [V2.3] 接口待联调
  // 宽表编辑
  // 保存 & 保存并同步 wide/indexMaking/update
  // 另存为 - 保存 & 保存并同步 wide/indexMaking/saveAs
  const handleChangeConfigIndex = (tableIndexs) => {
    const config = deepClone(lastSaveConfig);
    const next = {
      ...config,
      tableIndexs,
    };
    if (next.jobCronJson) {
      next.jobCronJson = JSON.parse(next.jobCronJson);
    }

    doSave(next, saveSyncFlag, (err) => {
      notification.error({
        description: err?.data?.msg,
      });
    });
  };

  const onOnlySave = (syncFlag) => {
    if (validateWideTableColumns(previewXOriginColumns)) {
      setSaveSyncFlag(syncFlag);
      // 导入的数据表 需要重新打开字段匹配弹窗
      // saveType 为创建的新的数据表
      if (lastSaveConfig?.saveType === 2) {
        setFieldMatchModalVisible(true);
      } else {
        setConfigIndexVisible(true);
      }
    }
  };

  return (
    <Page
      css={css`
        border: 1px solid #dfdfdf;
        border-radius: 2px;
        padding: 6px 12px 12px;
        display: flex;
        flex-direction: column;
      `}
      id='previewTableWraper'
    >
      <div
        style={{height: 36, display: 'flex', justifyContent: 'space-between'}}
      >
        <div
          css={css`
            display: flex;
            justify-content: flex-start;
            align-items: center;
          `}
        >
          <IconFont
            style={{
              cursor: 'pointer',
              color:
                previewTableDirection === 'X' ? iconActiveColor : iconColor,
              fontSize: '16px',
            }}
            type='icon-system'
            onClick={showXPreviewTable}
          />

          <IconFont
            style={{
              cursor: 'pointer',
              color:
                previewTableDirection === 'Y' ? iconActiveColor : iconColor,
              fontSize: '22px',
              margin: '0 9px',
            }}
            type='icon-bulletpoint'
            onClick={showYPreviewTable}
          />

          <Checkbox checked={showHideFields} onChange={toggleShowHideFields}>
            显示隐藏字段({hidedColumnCount})
          </Checkbox>

          <div
            style={{color: iconActiveColor, cursor: 'pointer'}}
            onClick={showfilterPreviewModal}
          >
            筛选器({filterColumnCount})
          </div>
        </div>
        <Button
          disabled={tableStructureList.length === 0}
          type='primary'
          onClick={() => {
            setTotalStatus((prev) => {
              return {
                ...prev,
                curComputedColumn: {
                  visible: true,
                  columnInfo: {
                    curShowName: '',
                    columnAlias: '',
                    pointNum: 2,
                    oriFormula: ' ',
                    placeholder: ' ',
                    mentionsData: [],
                    formulas: [],
                    uniqueAliasList: [],
                    indexType: '',
                  },
                },
              };
            });
          }}
        >
          创建计算字段
        </Button>
      </div>

      {previewTableDirection === 'X' ? <PreviewTableX /> : <PreviewTableY />}

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: 6,
          height: 40,
        }}
      >
        <Space>
          <span>
            {showNoTableStructure ? (
              <span style={{margin: '0 10px 0 0', color: errorHolderColor}}>
                请至少选择一个数据表进行关联！
              </span>
            ) : null}

            <Button onClick={onCancelHandle}>取消</Button>
          </span>

          {(!wideId || (wideId && status === 1)) && (
            <Button
              ghost
              disabled={tableStructureList.length === 0}
              type='primary'
              onClick={() => {
                setTempModalVisible(true);
              }}
            >
              暂存
            </Button>
          )}

          <Button
            disabled={tableStructureList.length === 0}
            loading={isSaving}
            type='primary'
            onClick={
              status === 1 || !status
                ? onShowSaveModal
                : () => onOnlySave(false)
            }
          >
            保存
          </Button>

          {wideId && status !== 1 && lastSaveConfig?.saveType !== 2 && (
            <Button
              disabled={tableStructureList.length === 0}
              loading={isSaving}
              type='primary'
              onClick={
                status === 1 || !status
                  ? onShowSaveSyncModal
                  : () => onOnlySave(true)
              }
            >
              保存并同步
            </Button>
          )}

          {wideId && status !== 1 && (
            <Button
              disabled={tableStructureList.length === 0}
              loading={isSaving}
              type='primary'
              onClick={onShowSaveAsModal}
            >
              另存为
            </Button>
          )}
        </Space>
      </div>

      {filterPreviewModalVisible && <FilterPreviewModal />}

      {/* 计算字段窗体 */}
      <ComputedFieldEditor />

      {/* 未保存取消 */}
      <WarningModal
        confirm={ensureCancelHandle}
        description='当前操作内容未保存，请确认是否离开'
        modalControl={cancelModalControl}
        title='确认是否离开'
      />

      {/* 正常保存时确认是否重新制表 */}
      {/* <WarningModal
        confirm={doSave}
        description='当前数据逻辑发生以下变化，数据表将重新制作，请确认：'
        modalControl={save11ModalControl}
        reason={rebuildTips}
        title='确认是否重新制表'
        width={400}
        zIndex={9999}
      /> */}

      <WarningModal
        description='以下内容会因为当前数据表的修改操作而更新失效，请取消当前编辑操作或另存数据表'
        ensureBtnText='知道了'
        isNeedCancelBtn={false}
        modalControl={notSaveWideModalControl}
        reason={wideQuoteReson}
        title='提示'
        width={714}
      />

      <TempModal
        isSaving={isSaving}
        tableData={lastSaveConfig}
        visible={tempModalVisible}
        onCancel={() => {
          setTempModalVisible(false);
        }}
        onOk={(_tableName) => {
          onTempSaveHandle(_tableName);
        }}
      />

      {/* 保存 */}
      <SaveWideTableModal
        isWideList
        isSaving={isSaving}
        onlyFolder={(data) => {
          if (
            tableStructureList[0].dbIp !== data.dbIp ||
            +tableStructureList[0].dbPort !== +data.dbPort
          ) {
            data.disabled = true;
          }
          return (
            (data.pathId === null && data.draftFlag !== 0) ||
            [1, 2].includes(data.pathType)
          );
        }}
        // tableData={lastSaveConfig}
        title='保存数据表'
        visible={saveModalVisible}
        onCancel={() => {
          setSaveModalVisible(false);
        }}
        onOk={(config, flag, errCb) => {
          onSaveHandle(config, flag, errCb);
        }}
      />

      {/* 保存并同步= */}
      <SaveTableModal
        isWideList
        syncFlag
        isSaving={isSaving}
        onlyFolder={(data) => {
          if (
            tableStructureList[0].dbIp !== data.dbIp ||
            +tableStructureList[0].dbPort !== +data.dbPort
          ) {
            data.disabled = true;
          }
          return (
            (data.pathId === null && data.draftFlag !== 0) ||
            [1, 2].includes(data.pathType)
          );
        }}
        tableData={lastSaveConfig}
        title='保存数据表'
        visible={saveSyncModalVisible}
        onCancel={() => {
          setSaveSyncModalVisible(false);
        }}
        onOk={(config, flag, errCb) => {
          onSaveHandle(config, true, errCb);
        }}
      />

      <SaveWideTableModal
        isSaveAs
        isWideList
        isSaving={isSaving}
        onlyFolder={(data) => {
          if (
            tableStructureList[0].dbIp !== data.dbIp ||
            +tableStructureList[0].dbPort !== +data.dbPort
          ) {
            data.disabled = true;
          }
          return (
            (data.pathId === null && data.draftFlag !== 0) ||
            [1, 2].includes(data.pathType)
          );
        }}
        tableData={lastSaveConfig}
        title='另存为'
        visible={saveAsModalVisible}
        onCancel={() => {
          setSaveAsModalVisible(false);
        }}
        onOk={(config, flag, errCb) => {
          onSaveHandle(config, flag, errCb, true);
        }}
      />

      <FieldMatchModal
        isSaving={isSaving}
        tableData={lastSaveConfig}
        tableId={lastSaveConfig?.targetTableId}
        visible={fieldMatchModalVisible}
        onCancel={() => setFieldMatchModalVisible(false)}
        onOk={(config, flag, errCb) => {
          onSaveHandle(config, flag, errCb);
        }}
      />
      <ModalConfigIndex
        createWideTableType={createWideTableType}
        visible={configIndexVisible}
        onCancel={setConfigIndexVisible}
        onChange={handleChangeConfigIndex}
      />
    </Page>
  );
};

export default PreviewTable;
