import {SearchOutlined} from '@ant-design/icons';
import {css} from '@emotion/react';
import {Button, Input, Tree} from 'antd';
import React, {useEffect, useCallback, useState, forwardRef} from 'react';

import GlobalModal from '../../../../../components/GlobalModal';
import {overflowScrollBar} from '../../../../../global.css';
import {deepClone, filterTree} from '../../../../../helpers/utils';
import useSafeState from '../../../../../hooks/useSafeState';
import {generateIcon} from '../MicroDataQueryMenu/MenuTree';

const {DirectoryTree} = Tree;
// function getParentNode(key, tree) {
//   let parentNode;
//   for (let i = 0; i < tree.length; i++) {
//     const node = tree[i];
//     if (node.children) {
//       if (node.children.some((item) => item.id === key)) {
//         parentNode = node;
//       } else if (getParentNode(key, node.children)) {
//         parentNode = getParentNode(key, node.children);
//       }
//     }
//   }
//   return parentNode;
// }

const mapTree = (org) => {
  const haveChildren = Array.isArray(org.children) && org.children.length > 0;

  const disabled = !org.managePerm;

  return {
    ...org,
    title: () => (
      <span
        css={css`
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre;
          width: 0;
          font-size: 14px;
          color: '#1A2230';
        `}
      >
        {org.showName || org.name || org.tableName}
      </span>
    ),
    key: `${org.id}`,
    checkable: !(org.pathId === null || [1, 2].includes(org.pathType)),
    children: haveChildren ? org.children?.map((i) => mapTree(i)) : [],
    icon: ({expanded}) => {
      return generateIcon(org, expanded, disabled);
    },
    disabled,
    disableCheckbox: disabled,
  };
};

function ChooseTargetFolder(props, ref) {
  const {modalControl, confirm = (v) => v, dataSource, batchDel} = props;

  const [update, setUpdate] = useState(1);
  const [showData, setShowData] = useSafeState([]);
  const [selectedKeys, setSelectedKeys] = useSafeState([]);
  const [showErrMsg, setShowErrMsg] = useSafeState(false);

  const onModalOkHandle = () => {
    if (selectedKeys.length === 0) {
      setShowErrMsg(true);
      return;
    }
    confirm([...selectedKeys]);
  };
  const onBatchDelete = () => {
    if (selectedKeys.length === 0) {
      setShowErrMsg(true);
      return;
    }
    batchDel([...selectedKeys]);
  };
  const onCancelHandle = () => {
    modalControl._toggle(false);
  };

  const onCheck = (obj) => {
    setShowErrMsg(false);
    setSelectedKeys(obj.checked ?? []);
  };

  const renderTree = useCallback(
    (list) => {
      const mapData = list.map((org) => mapTree(org));
      setShowData(mapData);
    },
    [setShowData]
  );

  const onSearch = (value) => {
    const originDataCopy = deepClone(dataSource);
    if (value) {
      const searchList = filterTree(originDataCopy, (item) => {
        return (
          item?.name?.includes(value) ||
          item?.showName?.includes(value) ||
          item?.tableName?.includes(value)
        );
      });

      renderTree(searchList);
    } else {
      renderTree(originDataCopy);
    }
  };

  useEffect(() => {
    renderTree(dataSource);
  }, [dataSource, renderTree]);

  useEffect(() => {
    if (modalControl.visible) {
      setUpdate(Date.now());
    }
    setSelectedKeys([]);
  }, [modalControl.visible, setSelectedKeys]);

  return (
    <GlobalModal
      centered
      cancelButtonProps={{
        type: 'ghost',
        danger: true,
      }}
      key={update}
      okButtonProps={{className: 'ensure-btn-text-color', type: 'primary'}}
      title='批量操作'
      width={566}
      {...modalControl}
      footer={[
        <Button danger key='del' type='ghost' onClick={onBatchDelete}>
          移除
        </Button>,
        <Button key='move' type='primary' onClick={onModalOkHandle}>
          移动至
        </Button>,
      ]}
      zIndex={99}
      onCancel={onCancelHandle}
    >
      <div>
        <div
          css={css`
            height: 360px;
            display: flex;
            flex-direction: column;
            padding: 2px 14px 0;
            padding-top: 0;
            border: 1px solid #eeeeee;
            overflow: hidden;
          `}
        >
          <div
            css={css`
              height: 44.5px;
              border-bottom: 1px solid #eeeeee;
              display: flex;
              padding: 6px 0;
              align-items: center;
            `}
          >
            <Input
              allowClear
              placeholder='检索数据表名称'
              size='small'
              suffix={
                <SearchOutlined style={{color: 'rgba(26, 34, 48, 0.25)'}} />
              }
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
          </div>
          <div
            css={css`
              flex: 1;
              overflow: auto;
              ${overflowScrollBar}
            `}
          >
            {modalControl.visible ? (
              <DirectoryTree
                blockNode
                checkStrictly
                checkable
                defaultExpandAll
                showIcon
                draggable={false}
                expandAction={false}
                selectable={false}
                treeData={showData}
                onCheck={onCheck}
              />
            ) : null}
          </div>
        </div>
        {showErrMsg ? (
          <div
            css={css`
              position: absolute;
              bottom: 33px;
              color: #e34d59;
            `}
          >
            请至少选择一个数据表
          </div>
        ) : null}
      </div>
    </GlobalModal>
  );
}

export default forwardRef(ChooseTargetFolder);
