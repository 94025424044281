/*
 * @Author: chenhu ch@qhdata.cn
 * @Date: 2024-10-21 16:44:52
 * @LastEditors: chenhu ch@qhdata.cn
 * @LastEditTime: 2024-10-21 17:26:43
 * @FilePath: \zhtj-vue3-fronte:\work\zhtj-react\zhihuitongji-react\src\components\IconFont\index.jsx
 * @Description: 兼容项目地不能访问外网，本地开发可以用第一种方式，但部署时需要记得下载更新静态图标资源
 */

// export default createFromIconfontCN({
//   scriptUrl: "//at.alicdn.com/t/c/font_3612639_v2u319sl13s.js",
// });

import {cx} from '@emotion/css';
import '../../assets/ALiIcon/iconfont';

export default function IconFont({type, className, ...restProps}) {
  return (
    <span className={cx('anticon', className)} role='img'>
      <svg
        aria-hidden='true'
        className='icon'
        fill='currentColor'
        focusable='false'
        height='1em'
        width='1em'
        {...restProps}
      >
        <use xlinkHref={`#${type}`} />
      </svg>
    </span>
  );
}
