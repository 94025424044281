import Watermark from '@uiw/react-watermark';
import {useAtom} from 'jotai';
import React, {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

import {fetchSysSettingAtom} from '../../atoms/sysSettingAtom';

const notShowWatermarkPaths = ['dataCanteen'];

const WaterMarks = ({children}) => {
  const [sysSettingDetail] = useAtom(fetchSysSettingAtom);
  const sysName = sysSettingDetail?.name;
  const sysNameWidth = sysName.length * 20;

  const locationHook = useLocation();

  const showWatermark = useMemo(() => {
    return !notShowWatermarkPaths.some((path) =>
      locationHook.pathname.includes(path)
    );
  }, [locationHook.pathname]);

  return showWatermark ? (
    <Watermark
      content={sysName}
      fontColor='rgba(0,0,0,.12)'
      gapX={sysNameWidth - sysNameWidth / 3}
      gapY={sysNameWidth}
      rotate={-30}
      style={{background: '#fff'}}
    >
      {children}
    </Watermark>
  ) : (
    children
  );
};

export default WaterMarks;
