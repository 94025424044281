/* eslint-disable react-hooks/exhaustive-deps */
import {css} from '@emotion/react';
import {Form, Input} from 'antd';
import React, {useContext, useEffect} from 'react';

import {updateUserInfo} from '../../../../api/system/user';
import FormErrMsg from '../../../../components/FormErrMsg';
import FormRequireLabel from '../../../../components/FormRequireLabel';
import GlobalModal from '../../../../components/GlobalModal';
import {REG_EMAIL, REG_ID, REG_MOBILE} from '../../../../constants/regExp';
import {encrypt} from '../../../../helpers/jsencrypt';
import useCheckValueChanged from '../../../../hooks/useValueChanged';
import MyContext from '../helpers/MyContext';

const EditAccountModal = (props) => {
  /** @type {{modalControl: import('../../../../hooks/useModalControl').modalControlObject}} */
  const {modalControl, detail} = props;

  const [form] = Form.useForm();

  const {freshUserInfo, userInfo = {}} = useContext(MyContext);
  const {rootFlag} = userInfo;
  const isRootAccount = rootFlag === '1';

  const [changed, checkChanged] = useCheckValueChanged();

  const handleOnCancel = () => {
    form.resetFields();
    modalControl._toggle(false);
  };

  const handleOnOk = async () => {
    const {formErrMsg, ...formValues} = await form.validateFields();

    const changedValues = checkChanged(detail, formValues);

    const values = {
      ...changedValues,
      userId: detail.userId,
      userName: detail.userName,
      realName: formValues.realName,
    };

    if (changedValues.phonenumber) {
      values.phonenumber = encrypt(formValues.phonenumber);
    }
    if (changedValues.idCard) {
      values.idCard = encrypt(formValues.idCard);
    }

    if (Object.keys(changedValues).length > 0) {
      updateUserInfo(values)
        .then(() => {
          freshUserInfo();
          handleOnCancel();
        })
        .catch((err) => FormErrMsg.disposeErr(form, err));
    }
  };

  // 寻找改过的属性，又改回去的属性需要重置状态
  const onValuesChangeHandle = (_, allValues) => {
    const {formErrMsg, ...formValues} = allValues;

    checkChanged(detail, formValues);
  };

  useEffect(() => {
    form.setFieldsValue({...detail});
  }, [detail]);

  return (
    <GlobalModal
      title='编辑账号信息'
      {...modalControl}
      forceRender
      cancelButtonProps={{style: {color: 'rgba(26, 34, 48, 0.6)'}}}
      okButtonProps={{disabled: !changed}}
      okText='保存'
      wrapClassName='editPersonalAccountModal'
      onCancel={handleOnCancel}
      onOk={handleOnOk}
    >
      <Form
        form={form}
        requiredMark={false}
        onValuesChange={onValuesChangeHandle}
      >
        <Form.Item
          label={<FormRequireLabel title='姓名' />}
          labelCol={{span: 4}}
          name='realName'
          rules={[{required: true, message: '请输入姓名'}]}
          validateTrigger='onBlur'
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          label={<FormRequireLabel title='身份证号' />}
          labelCol={{span: 4}}
          name='idCard'
          rules={[
            // {required: userType === 1, message: '请输入身份证号'},
            isRootAccount && form.getFieldValue('idCard') !== detail.idCard
              ? {
                  pattern: REG_ID,
                  message: '身份证号码有误，请修改',
                }
              : false,
          ].filter(Boolean)}
        >
          <Input autoComplete='off' disabled={!isRootAccount} />
        </Form.Item>
        <Form.Item
          label='手机号码'
          labelCol={{span: 4}}
          name='phonenumber'
          rules={[
            {
              pattern: REG_MOBILE,
              message: '手机号码有误，请修改',
            },
          ]}
          validateTrigger='onBlur'
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          css={css`
            margin-bottom: 0px;
          `}
          label='电子邮箱'
          labelCol={{span: 4}}
          name='email'
          rules={[{pattern: REG_EMAIL, message: '电子邮箱有误，请修改'}]}
          validateTrigger='onBlur'
        >
          <Input allowClear />
        </Form.Item>

        <FormErrMsg />
      </Form>
    </GlobalModal>
  );
};

export default EditAccountModal;
