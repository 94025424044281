import {SearchOutlined} from '@ant-design/icons';
import {css} from '@emotion/react';
import {Button, Input, Switch} from 'antd';
import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import {useSearchParams} from 'react-router-dom';

import GlobalModal from '../../../../../components/GlobalModal';
import IconFont from '../../../../../components/IconFont';
import {filterTree} from '../../../../../helpers/utils';
import useSafeState from '../../../../../hooks/useSafeState';
import MenuTree from '../MicroDataQueryMenu/MenuTree';

// function getParentNode(key, tree) {
//   let parentNode;
//   for (let i = 0; i < tree.length; i++) {
//     const node = tree[i];
//     if (node.children) {
//       if (node.children.some((item) => item.id === key)) {
//         parentNode = node;
//       } else if (getParentNode(key, node.children)) {
//         parentNode = getParentNode(key, node.children);
//       }
//     }
//   }
//   return parentNode;
// }

// eslint-disable-next-line no-unused-vars
const defaultErrMsg = '请选择文件夹';

const actionType = ['移动至', '保存至', '收藏至'];

function ChooseTargetFolder(props, ref) {
  const {
    title,
    treeApi,
    modalControl,
    modalType = 0, // 对应actionType索引 0 1 2
    confirm = (v) => v,
    onChange = (v) => v,
    addFolderApi,
  } = props;

  const [searchParams] = useSearchParams();

  // 是否需要权限控制
  const needPermission = useMemo(
    () => searchParams.get('tabIndex') === '2' && ![2].includes(modalType),
    [searchParams, modalType]
  );

  const [update, setUpdate] = useSafeState(1);
  const [showErrMsg, setShowErrMsg] = useSafeState('');
  const [switchStatus, setSwitchStatus] = useSafeState(true);
  const [selectFolderInfo, setSelectFolderInfo] = useSafeState({});
  const [addFolderDisabled, setAddFolderDisabled] =
    useSafeState(needPermission);

  const treeRef = useRef();

  const onModalOkHandle = () => {
    if (Object.keys(selectFolderInfo).length === 0) {
      setShowErrMsg(defaultErrMsg);
      return;
    }
    const params = {...selectFolderInfo};
    confirm(params);
  };

  const onSelectCallBack = (keys, info) => {
    setAddFolderDisabled(needPermission ? !info.node?.managePerm : false);
    setSelectFolderInfo(info?.node || {});
    onChange(info);
    setShowErrMsg('');
  };

  const onSearch = (value) => {
    const originTreeData = treeRef.current?.getOriginList();
    if (value) {
      const searchList = filterTree(originTreeData, (item) => {
        return item?.name?.includes(value) && item.tableType !== 2;
      });
      treeRef.current.handleTreeData(searchList);
    } else {
      treeRef.current.handleTreeData(originTreeData);
    }
    treeRef.current.handleExpand(true, originTreeData);
  };

  useImperativeHandle(ref, () => ({
    showSameError: (msg) => {
      setShowErrMsg(msg);
    },
  }));

  useEffect(() => {
    if (modalControl.visible) {
      setUpdate(Date.now());
    } else {
      setShowErrMsg('');
    }
    setAddFolderDisabled(needPermission);
    setSelectFolderInfo({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalControl.visible]);

  return (
    <GlobalModal
      centered
      cancelButtonProps={{className: 'cancel-btn-text-color'}}
      key={update}
      okButtonProps={{className: 'ensure-btn-text-color'}}
      title={actionType[modalType]}
      width={566}
      {...modalControl}
      zIndex={100}
      onOk={onModalOkHandle}
    >
      <div>
        <p style={{marginBottom: 12}}>
          <span
            css={css`
              color: #1a2230;
            `}
          >
            将
          </span>
          <span
            css={css`
              color: rgba(26, 34, 48, 0.7);
            `}
          >
            {modalType ? title : `“${title}”`}
          </span>
          <span
            css={css`
              color: #1a2230;
            `}
          >
            {actionType[modalType]}
          </span>
        </p>
        <div
          css={css`
            height: 296px;
            display: flex;
            flex-direction: column;
            padding: 8px 14px;
            padding-top: 0;
            border: 1px solid #eeeeee;
            overflow: hidden;
          `}
        >
          <div
            css={css`
              height: 44.5px;
              border-bottom: 1px solid #eeeeee;
              display: flex;
              padding: 2px 0;
              align-items: center;
            `}
          >
            <div>
              <span
                css={css`
                  margin-right: 10px;
                `}
              >
                {switchStatus ? '展开' : '折叠'}
              </span>
              <Switch
                onChange={(checked) => {
                  const originTreeData = treeRef.current?.getOriginList();
                  setSwitchStatus(checked);
                  treeRef.current.handleExpand(checked, originTreeData);
                }}
              />
            </div>
            <Button
              css={css`
                & > .text {
                  margin-left: 5px !important;
                }
                background-color: #4d5eff;
                border: none;
                color: #ffffff;
                margin-left: 22px;
              `}
              disabled={addFolderDisabled}
              size='small'
              type='primary'
              onClick={() => {
                treeRef.current.newFolder();
              }}
            >
              <IconFont
                style={{fontSize: 14, marginLeft: 0}}
                type='icon-a-new_folder'
              />
              <span className='text'>文件夹</span>
            </Button>
            <Input
              allowClear
              placeholder='检索文件夹名称'
              size='small'
              style={{width: 253, marginLeft: 'auto'}}
              suffix={
                <SearchOutlined style={{color: 'rgba(26, 34, 48, 0.25)'}} />
              }
              onChange={(e) => {
                onSearch(e.target.value);
                setAddFolderDisabled(false);
                setSelectFolderInfo({});
              }}
            />
          </div>
          <div
            css={css`
              overflow: auto;
            `}
          >
            {/* 移动 & 批量移动 & 收藏 */}
            {modalControl.visible ? (
              <MenuTree
                onlyView
                // onlyFolder={(data) => {
                //   return (
                //     (data.pathId === null && data.draftFlag !== 0) ||
                //     [1, 2].includes(data.pathType)
                //   );
                // }}
                addFolderApi={addFolderApi}
                needPermission={needPermission}
                ref={treeRef}
                treeApi={treeApi}
                onSelectCallBack={onSelectCallBack}
              />
            ) : null}
          </div>
        </div>
        {showErrMsg ? (
          <div
            css={css`
              position: absolute;
              bottom: 33px;
              color: #e34d59;
            `}
          >
            {showErrMsg}
          </div>
        ) : null}
      </div>
    </GlobalModal>
  );
}

export default forwardRef(ChooseTargetFolder);
