import {css} from '@emotion/react';
import React, {useRef} from 'react';
import ReactDOM from 'react-dom';

import useSafeState from '../../hooks/useSafeState';

const tipContentCss = css`
  background: rgb(22, 35, 78, 0.8);
  box-shadow: 0 2px 4px 0 rgba(38, 45, 115, 0.1);
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  border-radius: 2px;
  position: absolute;
  height: auto !important;
`;

export default function Tooltip(props) {
  const {title, children, ...rest} = props;

  const tipContentRef = useRef();

  const [contentVisible, setContentVisible] = useSafeState(false);
  const [tipsPosition, setTipsPosition] = useSafeState({
    left: 0,
    right: 0,
    top: 0,
  });

  const onMouseEnter = () => {
    setContentVisible(true);
  };

  const onMouseLeave = () => {
    setContentVisible(false);
  };

  const onMouseMove = (e) => {
    e.stopPropagation();
    const docWidth = document.documentElement.clientWidth;
    const getBoundingClientRect4Content =
      tipContentRef.current?.getBoundingClientRect();
    if (getBoundingClientRect4Content) {
      const overflow =
        e.clientX + getBoundingClientRect4Content.width > docWidth;
      setTipsPosition({
        left: overflow ? 'auto' : e.clientX,
        right: overflow ? docWidth - e.clientX : 'auto',
        top: e.clientY + 10,
      });
    }
  };

  return (
    <>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
      >
        {children}
      </div>
      {contentVisible &&
        ReactDOM.createPortal(
          <div
            className='tooltip-content'
            css={tipContentCss}
            ref={tipContentRef}
            style={{
              visibility: contentVisible ? 'visible' : 'hidden',
              zIndex: contentVisible ? 9999 : -1,
              left: tipsPosition.left,
              right: tipsPosition.right,
              top: tipsPosition.top,
              maxWidth: `${document.documentElement.clientWidth / 2}px`,
            }}
            {...rest}
          >
            {title}
          </div>,
          document.getElementById('root')
        )}
    </>
  );
}
