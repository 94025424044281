/*
 * @Author: chenhu ch@qhdata.cn
 * @Date: 2023-12-26 10:32:50
 * @LastEditors: chenhu ch@qhdata.cn
 * @LastEditTime: 2024-11-19 12:00:11
 * @FilePath: \zhtj-vue3-fronte:\work\zhtj-react\zhihuitongji-react\src\containers\DataManagement\DataTableManagement\components\MicroDataQueryInfo\WideTableInfo.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {css} from '@emotion/react';
import {Button, Dropdown, Menu, notification} from 'antd';
import {useAtom} from 'jotai';
import React, {useMemo, useEffect, useCallback, useContext} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';

import {MicroDataQueryContext} from '../..';
import {
  scAddFolder,
  scFolderList,
  wideTableDetail,
} from '../../../../../api/dataManagement/wideTable';
import {
  editSyncConfig,
  getSyncConfig,
  invokeOnceJob,
  pauseJob,
  startJob,
} from '../../../../../api/dataQuery/microDataQuery';
import {authMenusAtom} from '../../../../../atoms/authMenus';
import {logicEditAtom} from '../../../../../atoms/logicEditAtom';
import {AuthButton} from '../../../../../components/AuthButton';
import WarningModal from '../../../../../components/GlobalModal/WarningModal';
import IconFont from '../../../../../components/IconFont';
import {
  getMenuByCompUrl,
  routeHistory,
  routeHistoryPush,
} from '../../../../../helpers/history';
import {flattern} from '../../../../../helpers/utils';
import useModalControl from '../../../../../hooks/useModalControl';
import useSafeState from '../../../../../hooks/useSafeState';
import SyncConfigModal from '../../../MicroIndex/components/SaveTableModal/SyncConfigModal';
import {
  JOB_STATUS_TEXT_MAP,
  JOB_STATUS_TEXT_UPLOAD_MAP,
  SYNC_TYPE_MAP,
} from '../../helpers/constant';
import {useApi} from '../../hooks/useApi';
import {
  flexEndBox,
  infoBox,
  infoItem,
  infoItemLabel,
  infoItemText,
} from '../../style.css';
import ChooseTargetFolder from '../ChooseTargetFolder';

// jobStatus 0,队列中，1，执行中，2同步成功 3，失败 -1, 暂停
const menuTypeText = (type) => {
  if (type === -1) {
    return '启动同步';
  }
  return '暂停同步';
};

const tableTypeTimeKey = [
  /**
   * tableType:1:宽表2：同步的数据表3：直取的数据表4：上传的数据表
   * 0    无
   * 1 2  syncTime
   * 3    createTime || addTime
   * 4    updateTime
   */
  'addTime',
  'syncTime',
  'syncTime',
  'createTime',
  'updateTime',
];

const tableTypeTimeName = [
  '',
  '最近一次同步时间',
  '最近一次同步时间',
  '添加时间',
  '最近一次更新时间',
];

function StatusText({color, status}) {
  return (
    <span
      css={css`
        display: flex;
        align-items: center;
        span {
          color: rgba(26, 34, 48, 0.7);
        }
      `}
    >
      <span
        css={css`
          background-color: ${color};
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 7px;
          display: block;
        `}
      />
      <span style={{color: '#1A2230'}}>{status}</span>
    </span>
  );
}

export const iconMap = {
  // creat
  wideIcon1: {icon: 'icon-wide-table1', color: '#4D5EFF'},
  // sync
  wideIcon2: {icon: 'icon-copy-table', color: '#FFB533'},
  // direct
  wideIcon3: {icon: 'icon-s-2', color: '#FFB533'},
  // upload
  wideIcon4: {icon: 'icon-upload-table', color: '#00A870'},
  // expand
  expand: {icon: 'icon-fold-folder', color: '#4D5EFF'},
  // shrink
  shrink: {icon: 'icon-expand-folder', color: '#4D5EFF'},
  // sketchFolder
  draftFolder: {icon: 'icon-draft-folder', color: '#4D5EFF'},
  // sketchFile
  draftFile: {icon: 'icon-draft', color: '#4D5EFF'},
  // database
  database: {icon: 'icon-archive', color: '#4D5EFF'},
  // collect
  collect: {icon: 'icon-wujiaoxing-', color: '#4D5EFF'},
  // uncollect
  uncollect: {icon: 'icon-star', color: '#4D5EFF'},
};

const WideTableInfo = () => {
  const {wideOverview, setWideOverview, queryMenuRef} = useContext(
    MicroDataQueryContext
  );

  const apis = useApi();

  const locationHook = useLocation();
  const [searchParams] = useSearchParams();

  const [updateWideErrorMsg, setUpdateWideErrorMsg] = useSafeState('');
  const [currentSyncConfig, setCurrentSyncConfig] = useSafeState();

  const updateWideModalControl = useModalControl();
  const wideSyncConfigModalControl = useModalControl();
  const cancelAttentionModalControl = useModalControl();
  const followWideTableModalControl = useModalControl();

  const [, setTotalStatus] = useAtom(logicEditAtom);
  const [authMenus] = useAtom(authMenusAtom);

  const wideId = useMemo(() => {
    return searchParams.get('wideId');
  }, [searchParams]);

  const dbId = useMemo(() => {
    return searchParams.get('dbId');
  }, [searchParams]);

  const tableId = useMemo(() => {
    return wideOverview?.tableId ?? searchParams.get('tableId');
  }, [searchParams, wideOverview?.tableId]);

  const tabIndex = useMemo(() => {
    return searchParams.get('tabIndex');
  }, [searchParams]);

  const tableInfoMemo = useMemo(() => {
    const isExcel = wideOverview?.tableType === 4;
    return {
      syncStatusTip: isExcel ? '更新状态：' : '同步状态：',
      syncStatusKey: isExcel
        ? wideOverview.excelStatus
        : wideOverview.jobStatus,
      syncTypeText: SYNC_TYPE_MAP.get(wideOverview.syncJobType)?.text || '--',
      syncOptions: isExcel ? JOB_STATUS_TEXT_UPLOAD_MAP : JOB_STATUS_TEXT_MAP,
    };
  }, [
    wideOverview.excelStatus,
    wideOverview.jobStatus,
    wideOverview.syncJobType,
    wideOverview?.tableType,
  ]);

  /**
  * 后端数据规则定义：
  * 1 我的收藏时
  * pathId为空代表文件夹
  * pathId.不为空时：
  * tableType:1:宽表2：同步的数据表3：直取的数据表4：上传的数据表

  * 2 数据库时：
  * pathType:I:文件夹2：数据库3：数据表
  * pathType为3时：
  * tableType:1:宽表2：同步的数据表3：直取的数据表4：上传的数据表

  * 草稿文件 draftFlag = 0 && parentId !== -1
  */
  const isDraft =
    (queryMenuRef?.current?.curSelectNode?.draftFlag === 0 &&
      queryMenuRef?.current?.curSelectNode?.parentId !== -1) ||
    searchParams.get('isDraftFile') === 'true';

  const hasManagePerm = wideOverview?.managePerm === 1;

  // 展示创建的表编辑按钮
  const showEditBtnFlag =
    isDraft || (wideId && wideOverview?.tableType === 1 && hasManagePerm);

  // 展示上传的表编辑按钮
  const showUploadTableEditBtnFlag =
    wideId && wideOverview?.tableType === 4 && hasManagePerm;

  const reqWideOverview = useCallback(async () => {
    try {
      const res = wideId ? await wideTableDetail(wideId) : {};
      setWideOverview(res?.data ?? {});
      return res?.data ?? {};
    } catch (err) {
      if (err?.code === 512 || err?.data?.code === 512) {
        notification.error({
          description: err?.msg || err?.data?.msg || '未知错误',
        });
      }
      return {};
    }
  }, [setWideOverview, wideId]);

  const syncOnceHandle = async () => {
    try {
      await invokeOnceJob({id: wideId});
      const newWideOverview = await reqWideOverview();
      notification.success({
        description: `${
          newWideOverview?.tableShowName || newWideOverview?.tableNameEn
        }(id:${wideId})数据表启动成功`,
      });
      queryMenuRef.current?.refresh();
    } catch (err) {
      throw new Error('invokeOnce Job failed');
    }
  };

  const handleReqUpdate = useCallback(
    async (status) => {
      // jobStatus 0,队列中，1，执行中，2同步成功 3，失败 -1, 暂停

      if (status === -1) {
        try {
          const res = await startJob({id: wideId});
          if (res?.code === 200) {
            const newWideOverview = await reqWideOverview();
            notification.success({
              description: `${
                newWideOverview?.tableShowName || newWideOverview?.tableNameEn
              }(id:${wideId})数据表启动成功`,
            });
            queryMenuRef.current?.refresh();
          }
        } catch (err) {
          setUpdateWideErrorMsg(err?.msg ?? err?.data.msg);
          setTimeout(() => {
            updateWideModalControl._toggle(true);
          }, 50);
        }
      } else {
        try {
          const res = await pauseJob({id: wideId});
          if (res?.code === 200) {
            const newWideOverview = await reqWideOverview();
            notification.success({
              description: `${
                newWideOverview?.tableShowName || newWideOverview?.tableNameEn
              }(id:${wideId})数据表暂停成功`,
            });
            queryMenuRef.current?.refresh();
          }
        } catch (err) {
          notification.error({
            description: `${
              wideOverview?.tableShowName || wideOverview?.tableNameEn
            }(id:${wideId})数据表暂停失败`,
          });
        }
      }
    },
    [
      wideOverview?.tableShowName,
      wideOverview?.tableNameEn,
      queryMenuRef,
      wideId,
      reqWideOverview,
      setUpdateWideErrorMsg,
      updateWideModalControl,
    ]
  );

  const showSyncConfigModal = useCallback(async () => {
    try {
      const {data} = await getSyncConfig({widePathId: wideId});
      setCurrentSyncConfig(data);
      setTimeout(() => {
        wideSyncConfigModalControl._toggle(true);
      }, 60);
    } catch (err) {
      throw new Error('get config failed');
    }
  }, [setCurrentSyncConfig, wideId, wideSyncConfigModalControl]);

  const saveSyncConfig = async (value) => {
    try {
      await editSyncConfig({
        email: value?.syncResultRemind.includes(2) ? value.mail : '',
        cronVO: value?.syncJobType === 1 ? null : value.jobCronJson,
        jobId: wideOverview.jobId,
        jobType: value?.syncJobType,
        sendEmailFlag: value?.syncResultRemind.includes(2),
      });

      wideSyncConfigModalControl._toggle(false);
    } catch (error) {
      if (error.data.code === 512) {
        notification.error({description: error.data.msg});
      }
    }
  };

  // 收藏
  const handleFollowWideTableConfirm = async (info) => {
    try {
      const res = await apis.collectDo({
        pathId: +wideId,
        targetId: info.id,
      });
      if (res?.code === 200) {
        await queryMenuRef.current.refresh();
        reqWideOverview();
        followWideTableModalControl._toggle(false);
      }
    } catch (error) {
      if (error.data.code === 512) {
        notification.error({description: error.data.msg});
      }
      throw new Error('follow wideTable failed');
    }
  };

  // 取消收藏
  const cancelAttentionConfirm = async () => {
    try {
      const res = await apis.cancelCollect({
        id: wideOverview.collectPathId,
      });
      if (res?.code === 200) {
        await queryMenuRef.current?.refresh();
        reqWideOverview();
        cancelAttentionModalControl._toggle(false);
      }
    } catch (error) {
      if (error.data.code === 512) {
        notification.error({description: error.data.msg});
      }
      throw new Error('unFollow wideTable failed');
    }
  };

  const menu2 = useMemo(() => {
    const {jobStatus, syncJobType} = wideOverview;
    const {tableType} = wideOverview ?? {};

    return tableType === 4 ? (
      <Menu>
        <Menu.Item
          key='1'
          onClick={() => {
            routeHistory.push(
              `${locationHook.pathname}/replaceExcelPage?type=replace&wideId=${wideId}&dbId=${dbId}&tableId=${tableId}`
            );
          }}
        >
          替换数据
        </Menu.Item>
        <Menu.Item
          key='2'
          onClick={() => {
            routeHistory.push(
              `${locationHook.pathname}/addExcelPage?type=add&wideId=${wideId}&dbId=${dbId}&tableId=${tableId}`
            );
          }}
        >
          追加数据
        </Menu.Item>
      </Menu>
    ) : (
      <Menu>
        {syncJobType === 0 ? (
          <Menu.Item
            key='1'
            onClick={() => {
              handleReqUpdate(jobStatus);
            }}
          >
            {menuTypeText(jobStatus)}
          </Menu.Item>
        ) : null}
        <Menu.Item key='2' onClick={showSyncConfigModal}>
          配置同步
        </Menu.Item>
      </Menu>
    );
  }, [
    dbId,
    handleReqUpdate,
    locationHook.pathname,
    showSyncConfigModal,
    tableId,
    wideId,
    wideOverview,
  ]);

  useEffect(() => {
    if (wideId || searchParams.get('_t')) {
      reqWideOverview();
    }
  }, [reqWideOverview, searchParams, wideId]);

  return (
    <div
      className='pageActionBar'
      css={css`
        ${infoBox}
        /* height: ${infoBox ? '75px' : '105px'}; */
        white-space: nowrap;
      `}
    >
      <div css={flexEndBox}>
        <div style={{fontWeight: 600}}>
          <IconFont
            style={{
              fontSize: 26,
              marginRight: 6,
              verticalAlign: 'middle',
              color: iconMap[`wideIcon${wideOverview.tableType}`]?.color,
            }}
            type={iconMap[`wideIcon${wideOverview.tableType}`]?.icon}
          />
          {wideOverview?.tableShowName ||
            queryMenuRef?.current?.curSelectNode?.name ||
            queryMenuRef?.current?.curSelectNode?.showName ||
            '--'}
        </div>

        <div
          style={{
            display: 'flex',
          }}
        >
          <IconFont
            style={{
              fontSize: 20,
              marginRight: 10,
              cursor: 'pointer',
              display: isDraft || !wideId ? 'none' : 'block',
              color: '#FFCB01',
            }}
            title={wideOverview?.collectPathId ? '已收藏' : '未收藏'}
            type={
              wideOverview?.collectPathId
                ? iconMap.collect.icon
                : iconMap.uncollect.icon
            }
            onClick={() =>
              wideOverview?.collectPathId
                ? cancelAttentionModalControl._toggle(true)
                : followWideTableModalControl._toggle(true)
            }
          />

          <AuthButton
            css={css`
              margin-right: 10px;
              display: ${isDraft || !hasManagePerm ? 'none' : 'block'};
            `}
            size='small'
            type='ghost'
            onClick={() => {
              const hasAuthPage = getMenuByCompUrl(authMenus, [
                'containers/DataManagement/AuthPage',
                'containers/DataManagement/AuthPage/index',
              ]);

              // containers/DataManagement/AuthPage/index2
              routeHistoryPush({
                menus: authMenus,
                compUrl: `containers/DataManagement/AuthPage/${
                  hasAuthPage ? `index` : `index2`
                }`,
                search: {wideId},
                params: {
                  tableName: wideOverview?.tableShowName ?? '',
                },
              });
            }}
          >
            权限管理
          </AuthButton>

          {/* syncJobType 0：定时同步，首次会立即执行，1：手动同步（单次） */}
          {/* 只有创建或同步的宽表才能编辑和同步 */}
          {[1, 2].includes(wideOverview?.tableType) &&
            wideOverview.syncJobType === 1 &&
            hasManagePerm && (
              <AuthButton
                css={css`
                  margin-right: 10px;
                  display: ${isDraft ? 'none' : 'block'};
                `}
                disabled={[0, 1].includes(wideOverview?.jobStatus)}
                size='small'
                type='primary'
                onClick={syncOnceHandle}
              >
                同步数据
              </AuthButton>
            )}

          {wideId && !isDraft && (
            <AuthButton
              css={css`
                margin-right: 10px;
              `}
              size='small'
              type='primary'
              onClick={() => {
                const originTreeData = queryMenuRef.current.getOriginList();

                // 如果是收藏需要使用pathId
                const isCollectTree = !(searchParams.get('tabIndex') === '2');

                const sheet = flattern(originTreeData).find(
                  (d) => +(isCollectTree ? d.pathId : d.id) === +wideId
                );

                setTotalStatus((pre) => ({
                  ...pre,
                  createWideTableInfo: sheet,
                }));
                routeHistory.push(
                  `${
                    locationHook.pathname
                  }/LogicEdit?actionType=creatWide&tabIndex=${
                    searchParams.get('tabIndex') ?? 1
                  }`
                );
              }}
            >
              创建宽表
            </AuthButton>
          )}

          <AuthButton
            css={css`
              margin-right: 10px;
              display: ${showEditBtnFlag ? 'block' : 'none'};
            `}
            disabled={[0, 1].includes(wideOverview.jobStatus)}
            size='small'
            type='primary'
            onClick={() => {
              if (wideOverview.wideType === 2) {
                routeHistory.push(
                  `${
                    locationHook.pathname
                  }/SqlWideEdit?isDraftFile=${isDraft}&wideId=${wideId}&tabIndex=${
                    searchParams.get('tabIndex') ?? 1
                  }`
                );
              } else {
                routeHistory.push(
                  `${
                    locationHook.pathname
                  }/LogicEdit?isDraftFile=${isDraft}&wideId=${wideId}&tabIndex=${
                    searchParams.get('tabIndex') ?? 1
                  }`
                );
              }
            }}
          >
            编辑
          </AuthButton>

          <AuthButton
            css={css`
              margin-right: 10px;
              display: ${showUploadTableEditBtnFlag ? 'block' : 'none'};
            `}
            disabled={wideOverview.excelStatus === 3}
            size='small'
            type='primary'
            onClick={() => {
              routeHistory.push(
                `${
                  locationHook.pathname
                }/EditFieldPage?wideId=${wideId}&dbId=${dbId}&tableId=${tableId}&tabIndex=${
                  tabIndex ?? 1
                }`
              );
            }}
          >
            字段编辑
          </AuthButton>

          <AuthButton
            css={css`
              margin-right: 10px;
              display: ${showUploadTableEditBtnFlag && wideOverview.showDataEdit
                ? 'block'
                : 'none'};
            `}
            disabled={
              wideOverview.excelStatus === 3 && showUploadTableEditBtnFlag
            }
            size='small'
            type='primary'
            onClick={() => {
              routeHistory.push(
                `${
                  locationHook.pathname
                }/EditDataPage?wideId=${wideId}&dbId=${dbId}&tableId=${tableId}&isDraft=${isDraft}&tabIndex=${
                  tabIndex ?? 1
                }`
              );
            }}
          >
            数据编辑
          </AuthButton>

          {wideOverview?.tableType === 3 || !hasManagePerm || isDraft ? null : (
            <Dropdown disabled={wideOverview.excelStatus === 3} overlay={menu2}>
              {showUploadTableEditBtnFlag ? (
                <Button size='small' type='primary'>
                  更新数据
                </Button>
              ) : (
                <Button
                  icon={<IconFont type='icon-ellipsis' />}
                  size='small'
                  type='default'
                />
              )}
            </Dropdown>
          )}
        </div>
      </div>
      <div>
        <p css={infoItem} style={{display: isDraft ? 'none' : 'flex'}}>
          <span css={infoItemLabel}>所有者：</span>
          <span css={infoItemText}>{wideOverview?.tableCreator || ''}</span>
          <span css={infoItemLabel} style={{marginLeft: 20}}>
            英文名称：
          </span>
          <span css={infoItemText}>{wideOverview?.tableNameEn || ''}</span>

          <span css={infoItemLabel} style={{marginLeft: 20}}>
            数据库：
          </span>
          <span css={infoItemText}>{wideOverview?.dbName || ''}</span>
        </p>
        <p css={infoItem} style={{display: isDraft ? 'flex' : 'none'}}>
          <span css={infoItemLabel}>创建时间：</span>
          <span css={infoItemText}>
            {wideOverview.addTime || wideOverview.createTime || '--'}
          </span>
        </p>
      </div>
      <div
        css={css`
          margin-top: 6px;
          display: ${isDraft ? 'none' : 'flex'};
        `}
      >
        <span
          style={{
            display: [1, 2, 4].includes(wideOverview?.tableType)
              ? 'flex'
              : 'none',
          }}
        >
          <p
            css={css`
              ${infoItem};
              display: ${[1, 2].includes(wideOverview?.tableType)
                ? 'flex'
                : 'none'};
            `}
          >
            <span css={infoItemLabel}>同步方式：</span>
            <span css={infoItemText}>{tableInfoMemo.syncTypeText}</span>
          </p>
          <p css={infoItem}>
            <span css={infoItemLabel}>{tableInfoMemo.syncStatusTip}</span>
            <span css={infoItemText}>
              <StatusText
                color={
                  tableInfoMemo.syncOptions.get(tableInfoMemo.syncStatusKey)
                    ?.color ?? '#E34D59'
                }
                status={
                  tableInfoMemo.syncOptions.get(tableInfoMemo.syncStatusKey)
                    ?.text ?? '错误'
                }
              />
            </span>
          </p>
        </span>
        <p css={infoItem}>
          <span css={infoItemLabel}>
            {tableTypeTimeName[wideOverview.tableType]}：
          </span>
          <span css={infoItemText}>
            {wideOverview[tableTypeTimeKey[wideOverview.tableType]] ||
              wideOverview[tableTypeTimeKey[0]] ||
              '--'}
          </span>
        </p>
        <p css={infoItem}>
          <span css={infoItemLabel}>数据表大小：</span>
          <span css={infoItemText}>{wideOverview.tableSize || '--'}</span>
        </p>
      </div>

      <WarningModal
        description={updateWideErrorMsg}
        ensureBtnText='知道了'
        isNeedCancelBtn={false}
        modalControl={updateWideModalControl}
        title='提示'
        width={444}
      />

      <WarningModal
        confirm={cancelAttentionConfirm}
        description='取消收藏后数据表将从我的收藏中移除,您仍可以在数据库中再次收藏,是否确认取消收藏?'
        modalControl={cancelAttentionModalControl}
        title='取消收藏'
        width={397}
      />

      <SyncConfigModal
        values={{
          jobCronJson: currentSyncConfig?.cronVO,
          syncJobType: currentSyncConfig?.jobType,
          mail: currentSyncConfig?.email,
          sendMailFlag: currentSyncConfig?.sendEmailFlag,
          syncResultRemind: currentSyncConfig?.sendEmailFlag ? [2] : [],
        }}
        visible={wideSyncConfigModalControl.visible}
        onCancel={() => wideSyncConfigModalControl._toggle(false)}
        onOk={saveSyncConfig}
      />

      {/* 收藏 */}
      <ChooseTargetFolder
        addFolderApi={scAddFolder}
        confirm={handleFollowWideTableConfirm}
        modalControl={followWideTableModalControl}
        modalType={2}
        title={wideOverview.name || wideOverview.tableShowName || '--'}
        treeApi={scFolderList}
      />
    </div>
  );
};

export default WideTableInfo;
