import {css} from '@emotion/react';
import {notification, Table, Tooltip} from 'antd';
import React, {useContext, useEffect, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

import {EditContext, TECH_INDEX, useLogicAtom} from '..';
import {parseCalIndex} from '../../../api/dataManagement/microIndex';
import {debounce} from '../../../helpers/utils';
import useSafeState from '../../../hooks/useSafeState';
import IconFont from '../../IconFont';
import {
  formatMentionsData,
  insertPlaceholder,
  HAS_COLUMN_PERM,
} from './editorContentConfig';
import TooltipTitle from './TooltipTitle';

export default function Preview() {
  const [columns, setColumns] = useSafeState([]);
  const [dataSource, setDataSource] = useSafeState([]);

  const [searchParams] = useSearchParams();
  const wideId = searchParams.get('wideId');
  const {
    // calAlias,
    isValid,
    formulas,
    mentionsData,
    headerForm,
    config,
    indexType,
    previewCollapsed,
    setPreviewCollapsed,
    canFetchPreviewData,
    editorState,
    isInMicroIndexPage,
    totalStatusProps,
    preivewDataApi,
  } = useContext(EditContext);
  const {totalStatus, tableStructureList, visible, columnInfo} = useLogicAtom(
    isInMicroIndexPage,
    totalStatusProps
  );

  const getCalIndexDataFunc = useMemo(() => {
    return debounce(
      ({
        aliasNames: _aliasNames,
        calVOS: _calVOS,
        curShowName,
        tableStructureList: structureVOS,
        mentionsData: _mentionStates,
        calculates,
      }) => {
        preivewDataApi({
          calVOS: _calVOS,
          calculates,
          structureVOS,
          wideId: wideId ? +wideId : null,
        })
          .then((res) => {
            setColumns(() => {
              const resColumns = [];
              for (let i = 0; i < _calVOS.length; i++) {
                const col = _calVOS[i];
                if (i === 0) {
                  resColumns.push({
                    dataIndex: col.aliasName,
                    title: curShowName,
                    align: 'right',
                  });
                } else if (
                  resColumns.every((_col) => _col.dataIndex !== col.aliasName)
                ) {
                  resColumns.push({
                    dataIndex: col.aliasName,
                    align: 'right',
                    title: () => {
                      const f = _mentionStates.find(
                        (field) =>
                          field.originColumnAlias === col.aliasName ||
                          field.formula === col.aliasName
                      );
                      const Title =
                        f.formula || f.indexClassify === TECH_INDEX ? (
                          <TooltipTitle line1={f.curShowName} />
                        ) : (
                          <TooltipTitle
                            line1={`${f.instanceName}(${f.instanceType},${f.instanceUrl}:${f.instancePort})`}
                            line2={`${f.dbName}(${f.dbRemark})`}
                            line3={`${f.tableName}(${f.tableRemark})`}
                            line4={`${f.columnAlias}(${f.curShowName})`}
                            line5={f.indexType}
                          />
                        );
                      return (
                        <Tooltip
                          getPopupContainer={() =>
                            document.querySelector('.computedEditor')
                          }
                          placement='bottom'
                          title={Title}
                        >
                          {f.curShowName}
                        </Tooltip>
                      );
                    },
                  });
                }
              }
              return resColumns;
            });
            setDataSource(res.data);
          })
          .catch((err) => {
            if (err.code === 512) {
              notification.error({
                description: err.msg,
              });
            }
          });
      },
      300
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isValid &&
      visible &&
      mentionsData.length > 0 &&
      canFetchPreviewData &&
      /^[_a-z0-9]+$/gi.test(config.columnAlias) &&
      !/^[0-9]+/gi.test(config.columnAlias)
    ) {
      if (
        (indexType.includes('bigint') || indexType.includes('decimal')) &&
        !/^([0-9]|1[0-6])$/.test(config.pointNum)
      )
        return;
      parseCalIndex({
        elements: formulas,
        pointNum: indexType.includes('decimal') ? config.pointNum : null,
      }).then((res) => {
        let canSelectFlag = true;
        const calVOS = [
          {
            fieldName: res.data,
            aliasName: headerForm.getFieldValue('columnAlias'),
            pointNum:
              indexType.includes('bigint') || indexType.includes('decimal')
                ? config.pointNum
                : '',
          },
        ].concat(
          mentionsData.map((item) => {
            if (item.canSelect !== undefined && canSelectFlag) {
              canSelectFlag = item.canSelect === HAS_COLUMN_PERM;
            }
            return {
              fieldName: item.formula ?? item.originColumnAlias,
              aliasName: item.originColumnAlias,
              pointNum: item.pointNum,
            };
          })
        );
        if (!canSelectFlag || !headerForm.getFieldValue('columnAlias')) {
          return;
        }
        const isAdd = !columnInfo.columnAlias;
        const _nameSource =
          isAdd ||
          (!isAdd &&
            columnInfo.curShowName === headerForm.getFieldValue('curShowName'))
            ? 5
            : 1;
        const newMentionsData = formatMentionsData(editorState, mentionsData);
        let calculates = totalStatus.previewXOriginColumns
          .filter(
            (item) =>
              item.formulas &&
              item.columnAlias !== headerForm.getFieldValue('columnAlias')
          )
          .map((item) => {
            const _formulas = JSON.parse(item.formulas);
            const ret = {
              calAlias: item.originColumnAlias,
              calOriName: item.calOriName,
              curShowName: item.curShowName,
              formula: _formulas.formula,
              formulas: item.formulas,
              indexRank: item.indexRank,
              indexType: item.indexType,
              nameSource: item.nameSource,
              pointNum: item.pointNum,
              uniqueAliasList: item.uniqueAliasList,
            };
            if (item.calId) {
              ret.calId = item.calId;
            }
            return ret;
          });
        const cur = {
          calAlias: headerForm.getFieldValue('columnAlias'),
          calOriName: headerForm.getFieldValue('curShowName'),
          curShowName: headerForm.getFieldValue('curShowName'),
          formula: res.data,
          formulas: JSON.stringify({
            placeholder: insertPlaceholder(
              editorState.getCurrentContent().getPlainText(),
              newMentionsData
            ),
          }),
          indexRank: columnInfo._index
            ? columnInfo._index
            : totalStatus.previewXOriginColumns.length,
          indexType,
          nameSource: _nameSource,
          pointNum:
            indexType.includes('bigint') || indexType.includes('decimal')
              ? config.pointNum
              : '',
          uniqueAliasList: newMentionsData.map(
            (item) => item.originColumnAlias
          ),
        };
        if (columnInfo.calId) {
          cur.calId = columnInfo.calId;
        }
        calculates = calculates.concat([cur]);
        getCalIndexDataFunc({
          aliasNames: [res.data].concat(
            mentionsData.map((item) => item.columnAlias)
          ),
          curShowName: config.curShowName,
          calVOS,
          tableStructureList,
          mentionsData,
          calculates,
        });
      });
    } else if (!isValid || !visible) {
      setColumns([]);
      setDataSource([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    indexType,
    visible,
    isValid,
    formulas,
    mentionsData,
    getCalIndexDataFunc,
    tableStructureList,
    setDataSource,
    config.columnAlias,
    config.curShowName,
    config.pointNum,
    canFetchPreviewData,
  ]);

  useEffect(() => {
    if (columns.length) {
      setColumns((prev) => {
        const first = prev.slice(0, 1);
        return [
          {
            ...first[0],
            title: config.curShowName,
          },
          ...prev.slice(1),
        ];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.curShowName]);

  return (
    <div
      css={css`
        padding: 0 9px 5px 13px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 31px;
        `}
      >
        <span>计算结果预览区</span>
        <IconFont
          css={css`
            font-size: 14px;
          `}
          style={
            previewCollapsed
              ? {transform: 'rotate(-90deg)', transformOrigin: 'center'}
              : {}
          }
          type='icon-below'
          onClick={() => {
            setPreviewCollapsed(!previewCollapsed);
          }}
        />
      </div>
      <div
        css={css`
          height: 156px;
          display: ${previewCollapsed ? 'none' : 'block'};
        `}
      >
        <Table
          columns={mentionsData.length && isValid ? columns : []}
          css={css`
            height: 100%;
            .ant-table-header {
              display: ${mentionsData.length && columns.length && isValid
                ? 'block'
                : 'none'};
            }
            thead tr th:first-of-type {
              background: ${mentionsData.length > 0 &&
              columns.length > 0 &&
              isValid
                ? '#00a870'
                : 'none'};
              color: #fff;
            }
            .ant-table-cell {
              height: 26px !important;
              padding-top: 0 !important;
              padding-bottom: 0 !important;
            }
            .ant-empty-normal {
              margin: 30px 0;
            }
            th {
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
              overflow: hidden;
            }
            clear: none;
          `}
          dataSource={mentionsData.length && isValid ? dataSource : []}
          pagination={false}
          scroll={{y: 130}}
        />
      </div>
    </div>
  );
}
