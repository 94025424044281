import {css} from '@emotion/react';
import {Form, Input, Button} from 'antd';
import {useAtom} from 'jotai';
import {useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useTitle} from 'react-use';

import {login, rootLogin} from '../../../api/login';
import {fetchSysSettingAtom} from '../../../atoms/sysSettingAtom';
import FormErrMsg from '../../../components/FormErrMsg';
import IconFont from '../../../components/IconFont';
import {storageKeys} from '../../../constants/storageKeys';
import {PUBLIC_PATH, ROOT_LOGIN, selfInfoPath} from '../../../constants/system';
import {logoutHandle, routeHistory} from '../../../helpers/history';
import {encrypt} from '../../../helpers/jsencrypt';
import {jwtDecode} from '../../../helpers/utils';
import {useToken} from '../../../hooks/useToken';
import {useCaptcha} from './hooks/useCaptcha';
import {SetNewPwdModal} from './SetNewPwdModal';

const idealWidth = '430px';

const formWraper = css`
  display: flex;
  flex-direction: column;
  height: 466px;
  width: ${idealWidth};
  padding: 40px;
  user-select: none;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;

const sysIntroduction = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  text-align: left;
`;

const sysTitle = css`
  font-size: 28px;
  font-weight: 500;
  color: #1a2230;
`;

const sysSlogan = css`
  margin-top: 10px;
  font-size: 18px;
  max-width: ${idealWidth};
  font-weight: 400;
  color: #1a2230;
  word-break: break-all;
`;

const loginCodeCss = css`
  height: 40px;
  width: 110px;
  margin-left: 10px;
  margin-bottom: 24px;
  border-radius: 4px;
  overflow: hidden;
`;

const loginCodeImgCss = css`
  width: 100%;
  height: 100%;
  cursor: pointer;
  vertical-align: middle;
  border-radius: 4px;
`;

const loginFormCss = css`
  margin-top: 41px;
  .ant-input {
    height: 30px;
  }
  .ant-form-item {
    margin-bottom: 24px;
  }
`;

const iconStyle = css`
  svg {
    height: 16px;
    width: 16px;
    color: rgba(26, 34, 48, 0.4);
  }
`;

/*
  1. 绑定表单状态
  2. 获取验证码
  3. 提交登录
*/
export default function LoginForm() {
  const [form] = Form.useForm();
  const [isLoging, setIsLoging] = useState(false);
  const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false);
  const captcha = useCaptcha();
  const {setToken} = useToken();
  const [searchParams] = useSearchParams();
  const pathName = window.location.pathname;

  const [sysSettingDetail] = useAtom(fetchSysSettingAtom);

  useTitle(sysSettingDetail.name);

  /**
   * @description: 调登录接口后或改密码后的回调函数
   * @param {Boolean} [hadUpdatePwdByFirstLogin] - 是否操作过改过密码
   * @return {void}
   */
  const onCompleteHandle = (hadUpdatePwdByFirstLogin = false, payload = {}) => {
    // 是否为默认密码 defaultPwdFlag 0 否 1是
    const {defaultPwdFlag, isFromGuide} = payload;
    if (hadUpdatePwdByFirstLogin) {
      logoutHandle(false);
      form.resetFields();
      captcha.refresh();
      setIsFirstTimeLogin(false);
    } else if (!hadUpdatePwdByFirstLogin) {
      if (defaultPwdFlag === 1) {
        setIsFirstTimeLogin(true);
      } else if (defaultPwdFlag === 0) {
        setTimeout(() => {
          if (isFromGuide) {
            routeHistory.push('/guide');
          } else {
            routeHistory.push(PUBLIC_PATH, {replace: true});
          }
        }, 20);
      } else {
        setIsFirstTimeLogin(false);
      }
    }
  };

  async function handleLogin() {
    const formValues = await form.validateFields();
    setIsLoging(true);
    FormErrMsg.clearFormErrMsg(form);
    window.sessionStorage.clear();
    window.localStorage.removeItem(storageKeys.TOKEN);
    const isFromGuide = searchParams.get('redirect');

    if (pathName === ROOT_LOGIN) {
      try {
        const tokenRes = await rootLogin({
          username: formValues?.username?.trim(),
          password: encrypt(formValues.password),
          code: formValues.code,
          uuid: captcha.uuid,
        });
        setToken(tokenRes?.data);
        setIsLoging(false);
        setTimeout(() => {
          routeHistory.push(selfInfoPath, {replace: true});
        }, 20);
      } catch (err) {
        captcha.refresh();
        setIsLoging(false);
        if ((err?.code ?? err?.data.code) === 510) {
          FormErrMsg.setFormErrMsg(form, 'code', '请重新输入验证码');
        } else {
          FormErrMsg.disposeErr(form, err);
        }
      }
    } else {
      try {
        const tokenRes = await login({
          username: formValues?.username?.trim(),
          password: encrypt(formValues.password),
          code: formValues.code,
          uuid: captcha.uuid,
        });
        setToken(tokenRes?.data);
        setIsLoging(false);
        onCompleteHandle(false, {...jwtDecode(tokenRes?.data), isFromGuide});
      } catch (err) {
        console.log('err: ', err);
        captcha.refresh();
        setIsLoging(false);
        /* if ((err?.code ?? err?.data.code) === 512) {
          FormErrMsg.setFormErrMsg(form, 'code', '请重新输入验证码');
        } else { */
        FormErrMsg.disposeErr(form, err);
        // }
      }
    }
  }

  return (
    <div css={formWraper}>
      <div css={sysIntroduction}>
        <div css={sysTitle}>{sysSettingDetail.name}</div>
        <div css={sysSlogan}>{sysSettingDetail.slogan}</div>
      </div>
      <Form css={loginFormCss} form={form} onFinish={handleLogin}>
        <Form.Item
          name='username'
          rules={[
            {
              required: true,
              message: '请输入账号名',
              validateTrigger: 'onBlur',
            },
          ]}
        >
          <Input
            autoComplete='off'
            placeholder='请输入用户账号'
            prefix={<IconFont css={iconStyle} type='icon-user' />}
          />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[
            {
              required: true,
              message: '请输入密码',
              validateTrigger: 'onBlur',
            },
          ]}
        >
          <Input.Password
            autoComplete='off'
            placeholder='请输入登录密码'
            prefix={<IconFont css={iconStyle} type='icon-lock-on' />}
            onPressEnter={handleLogin}
          />
        </Form.Item>
        <div
          className='clearfix'
          css={{
            display: 'flex',
          }}
        >
          <Form.Item
            css={{
              flex: 1,
            }}
            name='code'
            rules={[
              {
                required: true,
                message: '请输入验证码',
                validateTrigger: 'onChange',
              },
            ]}
          >
            <Input
              autoComplete='off'
              placeholder='请输入验证码'
              prefix={<IconFont css={iconStyle} type='icon-secured1' />}
              onPressEnter={handleLogin}
            />
          </Form.Item>
          <div css={loginCodeCss}>
            <img
              css={loginCodeImgCss}
              src={captcha.imgSrc}
              onClick={() => captcha.refresh()}
            />
          </div>
        </div>

        <FormErrMsg />

        <Form.Item style={{marginTop: 28}}>
          <Button
            block
            disabled={isLoging}
            htmlType='submit'
            loading={isLoging}
            size='large'
            type='primary'
          >
            登 录
          </Button>
        </Form.Item>
      </Form>

      <SetNewPwdModal
        completeHandle={onCompleteHandle}
        visible={isFirstTimeLogin}
      />
    </div>
  );
}
