import React from 'react';

import {download} from '../../helpers/request/download';
import TwiceCheckButton from '../TwiceCheckButton';

/**
 * @typedef {import('../../helpers/request/download').downloadParams} downloadParams
 */

/**
 * @param {import('../TwiceCheckButton').TwiceCheckButton & {
 *  twiceCheck?: Boolean;
 *  name: String;
 *  onClick: () => downloadParams;
 * }} props
 */
export function ExportButton(props) {
  const {twiceCheck = false, name, onClick, ...rest} = props;

  function exportHandler() {
    const {
      url = '',
      params = {},
      filename = '',
      requestConfig = {},
    } = onClick();

    if (url) {
      download(url, params, filename, requestConfig);
    }
  }

  return (
    <TwiceCheckButton
      twiceCheck={twiceCheck}
      type='primary'
      onClick={exportHandler}
      {...rest}
    >
      {name}
    </TwiceCheckButton>
  );
}
