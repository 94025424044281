import Editor from '@draft-js-plugins/editor';
import {css} from '@emotion/react';
import {Button, Tooltip} from 'antd';
import {
  getDefaultKeyBinding,
  EditorState,
  SelectionState,
  Modifier,
} from 'draft-js';
import {Resizable} from 're-resizable';
// eslint-disable-next-line import/order
import React, {
  useRef,
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from 'react';
import '../../../helpers/@draft-js-plugins-mention/plugin.css';

import {useMount, useUnmount} from 'react-use';

import {EditContext, TECH_INDEX, useLogicAtom} from '..';
import {
  getCalIndexType,
  parseCalIndex,
} from '../../../api/dataManagement/microIndex';
import {sortComputedColumns} from '../../../containers/DataManagement/MicroIndex/LogicEditPage/helpers';
import {overflowScrollBar} from '../../../global.css';
import createMentionPlugin from '../../../helpers/@draft-js-plugins-mention/index.esm';
import {debounce, deepClone} from '../../../helpers/utils';
import useSafeState from '../../../hooks/useSafeState';
import IconFont from '../../IconFont';
import {COLUMN_TYPE} from '../constants';
import {arithmeticValidator} from '../rules';
import {
  DataTypeMap,
  Entry,
  sortMentionsData,
  _addMention,
  filterName,
  insertPlaceholder,
  validate,
  formatFomulas,
  formatMentionsData,
  findIsColumnRelied,
} from './editorContentConfig';
import TooltipTitle from './TooltipTitle';

let pageEditorState;

let pageMentionsData = [];

function handleRemove(mention, state = pageEditorState) {
  const currentSelectionState = state.getSelection();
  const start =
    mention.children[0].props.start < 0 ? 0 : mention.children[0].props.start;
  const mentionTextSelection = currentSelectionState.merge({
    anchorOffset: start,
    focusOffset: start + mention.decoratedText.length,
  });
  const mentionReplacedContent = Modifier.replaceText(
    state.getCurrentContent(),
    mentionTextSelection,
    ''
  );
  const newEditorState = EditorState.push(
    state,
    mentionReplacedContent,
    'insert-fragment'
  );
  const res = EditorState.forceSelection(
    newEditorState,
    mentionReplacedContent.getSelectionAfter()
  );
  return res;
}

function handleSelect(mention, state = pageEditorState) {
  // const currentSelectionState = state.getSelection();
  const currentSelectionState = SelectionState.createEmpty(
    mention.children[0].props.block.getKey()
  );

  const start = mention.children[0].props.start;
  const mentionTextSelection = currentSelectionState.merge({
    anchorOffset: start,
    focusOffset: start + mention.decoratedText.length,
  });
  const res = EditorState.forceSelection(state, mentionTextSelection);
  return res;
}

function getBlockLine(targetBlock) {
  const blockArray = pageEditorState.getCurrentContent().getBlocksAsArray();
  for (let i = 0; i < blockArray.length; i++) {
    const block = blockArray[i];
    if (block.getKey() === targetBlock.getKey()) {
      return i;
    }
  }
  return 0;
}

const MentionWrapper = (props) => {
  const {mentionProps, setMentionsData, children} = props;

  useMount(() => {
    const lineIndex = getBlockLine(mentionProps.children[0].props.block);
    setMentionsData((pre) => {
      const ret = pre.concat([
        {
          ...mentionProps.mention,
          lineIndex,
          start: mentionProps.children[0].props.start,
          entityKey: mentionProps.entityKey,
        },
      ]);
      return ret;
    });
    document
      .querySelector(`#a${mentionProps.entityKey} span span span`)
      .setAttribute('contenteditable', 'false');
  });

  useEffect(() => {
    const lineIndex = getBlockLine(mentionProps.children[0].props.block);
    setMentionsData((pre) => {
      const _t = deepClone(pre);
      const i = _t.findIndex((m) => m.entityKey === mentionProps.entityKey);
      _t.splice(i, 1, {
        ...mentionProps.mention,
        lineIndex,
        start: mentionProps.children[0].props.start,
        entityKey: mentionProps.entityKey,
      });
      return _t;
    });
    document
      .querySelector(`#a${mentionProps.entityKey} span span span`)
      .setAttribute('contenteditable', 'false');
  }, [mentionProps, setMentionsData]);

  useUnmount(() => {
    setMentionsData((pre) => {
      const _t = deepClone(pre);
      const i = _t.findIndex((m) => m.entityKey === mentionProps.entityKey);
      _t.splice(i, 1);
      return _t;
    });
  });

  return children;
};

export default function EditorContent() {
  const {
    // calAlias,
    config,
    mentions,
    formulas,
    setFormulas,
    isValid,
    setIsValid,
    headerForm,
    indexType,
    setIndexType,
    draggingSize,
    setDraggingSize,
    settingCollapsed,
    previewCollapsed,
    rawFormulaAndMention,
    mentionsData,
    setMentionsData,
    setReadyToUpdate,
    canFetchPreviewData,
    setCanFetchPreviewData,
    editorState,
    setEditorState,
    isInMicroIndexPage,
    totalStatusProps,
    sideAdditionItem,
    setSideAdditionItem,
    // setRawFormulaAndMention,
  } = useContext(EditContext);

  const isHorizonMounted = useRef(false);
  const isVerticalMounted = useRef(false);
  const isRender = useRef(false);

  const {totalStatus, setTotalStatus, tableStructureList, visible, columnInfo} =
    useLogicAtom(isInMicroIndexPage, totalStatusProps);

  const resizezableRef = useRef(null);

  const selectionStateRef = useRef(null);

  const editorRef = useRef(null);
  /* const [editorState, setEditorState] = useSafeState(() =>
    EditorState.createEmpty()
  ); */
  const [open, setOpen] = useSafeState(false);
  const [suggestions, setSuggestions] = useSafeState([]);
  const [confirmLoading, setConfirmLoading] = useSafeState(false);
  const [prevDraggingSize, setPrevDraggingSize] = useSafeState(draggingSize);

  const {MentionSuggestions, plugins} = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      popperOptions: {
        placement: 'bottom-start',
        strategy: 'fixed',
      },
      mentionComponent(mentionProps) {
        const {mention} = mentionProps;

        const Title =
          mention.formula || mention.indexClassify === TECH_INDEX ? (
            <TooltipTitle line1={mention.curShowName} />
          ) : (
            <TooltipTitle
              // line1={`${mention.instanceName}(${mention.instanceType},${mention.instanceUrl}:${mention.instancePort})`}
              line1={`${mention.dbIp}:${mention.dbPort})`}
              // line2={`${mention.dbName}(${mention.dbRemark})`}
              line3={`${mention.tableName}(${
                mention.tableRemark || mention.tableComment
              })`}
              line4={`${mention.columnAlias}(${mention.curShowName})`}
              line5={mention.indexType}
            />
          );

        const child = (
          <span
            className={`mention ${mentionProps.className}`}
            css={css`
              color: ${mention._del ? '#fff !important' : '#575f67'};
              background: ${mention._del
                ? 'red !important'
                : 'rgba(77, 94, 255, 0.2) !important'};
              position: relative;
              &:hover {
                .icon-wrapper {
                  display: flex;
                }
              }
            `}
            id={`a${mentionProps.entityKey}`}
            onMouseEnter={(e) => {
              selectionStateRef.current = pageEditorState
                .getCurrentContent()
                .getSelectionAfter();
              const res = handleSelect(mentionProps);
              pageEditorState = res;
              setEditorState(res);
            }}
            onMouseOut={() => {
              const res = EditorState.forceSelection(
                pageEditorState,
                selectionStateRef.current
              );
              setEditorState(res);
            }}
          >
            <span
              css={css`
                & *::selection {
                  color: ${mention._del ? '#fff' : '#575f67'};
                  background: transparent;
                }
              `}
              onDragLeave={(e) => {
                e.target.removeAttribute('contenteditable');
              }}
              onDragOver={(e) => {
                e.target.setAttribute('contenteditable', false);
              }}
              onMouseDown={(e) => {
                const res = handleSelect(mentionProps);
                pageEditorState = res;
                setEditorState(res);
              }}
            >
              {mentionProps.children}
            </span>
            <span
              className='icon-wrapper'
              css={css`
                width: 18px;
                height: 100%;
                display: none;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 0;
                top: 0;
                background: ${mention._del ? '#fd4646' : '#dcdfff'};
                z-index: 1;
              `}
              onClickCapture={(e) => {
                const res = handleRemove(mentionProps);
                // pageEditorState = res;
                setEditorState(res);
              }}
            >
              <IconFont
                css={css`
                  color: ${mention._del ? '#fff' : '#4d5eff'};
                `}
                type='icon-close'
              />
            </span>
          </span>
        );

        if (mention._del) {
          return child;
        }

        return (
          <MentionWrapper
            mentionProps={mentionProps}
            setMentionsData={setMentionsData}
          >
            <Tooltip
              getPopupContainer={() =>
                document.querySelector('.computedEditor')
              }
              placement='bottom'
              title={Title}
            >
              {child}
            </Tooltip>
          </MentionWrapper>
        );
      },
    });

    // eslint-disable-next-line no-shadow
    const {MentionSuggestions} = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];

    return {plugins, MentionSuggestions};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenChange = useCallback(
    (_open) => {
      setOpen(_open);
    },
    [setOpen]
  );

  const onSearchChange = useCallback(
    ({trigger, value}) => {
      const res = filterName(value, mentions);
      if (res.length > 0) {
        setSuggestions(res);
      } else {
        setSuggestions([
          {
            name: '无字段结果',
          },
        ]);
      }
    },
    [mentions, setSuggestions]
  );

  useEffect(() => {
    if (mentions.length) {
      setSuggestions(mentions);
    }
  }, [mentions, setSuggestions]);

  // 判断是否有效 isValid
  const makeOtherState = useCallback(
    (state, _mentionsData) => {
      /** @type {string} */
      const content = state.getCurrentContent().getPlainText();
      const _isValid = validate(content, _mentionsData, state);
      if (_isValid !== false) {
        const res = formatFomulas(content, _mentionsData, state);
        if (res) {
          const typesArr = [];
          for (let i = 0; i < res.length; i++) {
            const item = res[i];
            // 收集参与计算的元素类型
            if (typesArr.indexOf(item.type) === -1) {
              typesArr.push(item.type);
            }
          }
          const formulaValid = arithmeticValidator(res);
          if (
            typesArr.length <= 1 ||
            typesArr.indexOf(COLUMN_TYPE) === -1 ||
            !formulaValid
          ) {
            setIsValid(false);
            return;
          }
          setFormulas(res);
        }
      }
      setIsValid(_isValid !== false);
    },
    [setIsValid, setFormulas]
  );

  useUnmount(() => {
    if (editorState?.getCurrentContent()?.entityMap) {
      pageEditorState = null;
      pageMentionsData = [];
    }
  });

  useEffect(() => {
    const prev = pageEditorState?.getCurrentContent()?.getPlainText();
    const next = editorState?.getCurrentContent()?.getPlainText();
    let returnFlag = mentionsData.length === pageMentionsData.length;
    if (returnFlag) {
      for (let i = 0; i < mentionsData.length; i++) {
        if (
          mentionsData[i].originColumnAlias !==
          pageMentionsData[i]?.originColumnAlias
        ) {
          returnFlag = false;
          break;
        }
      }
    }
    if (((prev === next && prev && next) || (!prev && !next)) && returnFlag) {
      setCanFetchPreviewData(false);
      return;
    }
    const newMentionsData = formatMentionsData(editorState, mentionsData);
    makeOtherState(editorState, newMentionsData);
    pageMentionsData = mentionsData;
    pageEditorState = editorState;
    setCanFetchPreviewData(true);
  }, [editorState, mentionsData, makeOtherState, setCanFetchPreviewData]);

  // 编辑回显
  useEffect(() => {
    if (
      rawFormulaAndMention.oriFormula &&
      rawFormulaAndMention.placeholder &&
      mentions.length &&
      !isRender.current
    ) {
      isRender.current = true;
      let tempPlaceholder = rawFormulaAndMention.placeholder;
      let newEditorState = EditorState.createEmpty();
      // 1、插入完整公式
      const range =
        EditorState.moveSelectionToEnd(newEditorState).getSelection();
      const mentionReplacedContent = Modifier.replaceText(
        newEditorState.getCurrentContent(),
        range,
        rawFormulaAndMention.oriFormula
      );
      newEditorState = EditorState.push(newEditorState, mentionReplacedContent);
      for (let i = 0; i < rawFormulaAndMention.mentionsData.length; i++) {
        const item = rawFormulaAndMention.mentionsData[i];
        let newItem;
        let breakFlag = false;
        for (let j = 0; j < mentions.length; j++) {
          const mentionItem = mentions[j];
          if (mentionItem.type === 'container') {
            for (let k = 0; k < mentionItem.children.length; k++) {
              const innerMentionItem = mentionItem.children[k];
              if (item.id) {
                if (item.id === innerMentionItem.id) {
                  newItem = innerMentionItem;
                  breakFlag = true;
                  break;
                }
              } else if (
                item.columnAlias === innerMentionItem.originColumnAlias
              ) {
                newItem = innerMentionItem;
                breakFlag = true;
                break;
              }
            }
          }
          if (breakFlag) break;
        }
        if (!newItem) {
          newItem = item;
          newItem._del = true;
        }
        const start = tempPlaceholder.indexOf('{}');
        tempPlaceholder = tempPlaceholder.replace('{}', newItem.curShowName);
        // 先删除
        newEditorState = handleRemove(
          {
            children: [
              {
                props: {
                  start,
                },
              },
            ],
            decoratedText: item.curShowName,
          },
          newEditorState
        );

        // 再插入
        newEditorState = _addMention(newEditorState, newItem, start);
      }
      setEditorState(newEditorState);
    }
  }, [
    mentions,
    setEditorState,
    rawFormulaAndMention.mentionsData,
    rawFormulaAndMention.placeholder,
    rawFormulaAndMention.oriFormula,
  ]);

  useEffect(() => {
    if (sideAdditionItem) {
      /* 字段前插入空格 */
      const state = editorState ? editorState : EditorState.createEmpty();
      const mentionTextSelection0 = state.getSelection();
      const mentionReplacedContent0 = Modifier.insertText(
        state.getCurrentContent(),
        mentionTextSelection0,
        ' '
      );
      const state2 = EditorState.push(
        state,
        mentionReplacedContent0,
        'insert-fragment'
      );
      /* 字段前插入空格 */

      /* 插入字段 */
      const newEditorState = _addMention(
        state2,
        sideAdditionItem.item,
        state.getSelection().getAnchorOffset() + 1
      );
      /* 插入字段 */

      /* 字段后插入空格 */
      const mentionTextSelection = newEditorState.getSelection();
      const mentionReplacedContent = Modifier.insertText(
        newEditorState.getCurrentContent(),
        mentionTextSelection,
        ' '
      );
      const newEditorState2 = EditorState.push(
        newEditorState,
        mentionReplacedContent,
        'insert-fragment'
      );
      /* 字段后插入空格 */

      setSideAdditionItem(null);
      setEditorState(newEditorState2);
    }
  }, [editorState, setEditorState, setSideAdditionItem, sideAdditionItem]);

  const handleKeyCommand = useCallback(
    (command, state) => {
      const anchorOffset = state.getSelection().getAnchorOffset();
      const selectionState = state.getSelection();
      const blockKey = selectionState.getStartKey();
      const contentState = state.getCurrentContent();
      const blockArray = contentState.getBlocksAsArray();
      let currentBlockIndex;
      const currentBlock = blockArray.find((item, index) => {
        currentBlockIndex = index;
        return item.key === blockKey;
      });
      if (command === 'handle-backward' || command === 'handle-forward') {
        const nextPosition =
          command === 'handle-backward' ? anchorOffset - 1 : anchorOffset;
        const nextPosition2 =
          command === 'handle-backward' ? anchorOffset - 2 : anchorOffset + 1;
        const nextContent = currentBlock.getText()[nextPosition];
        const nextEntityKey = currentBlock.getEntityAt(nextPosition2);
        const nextEntity = nextEntityKey
          ? contentState.getEntity(nextEntityKey)
          : null;
        const textLength =
          nextEntity && nextContent === ' '
            ? 2 + nextEntity.data.mention.curShowName.length
            : 1;
        const position =
          command === 'handle-backward'
            ? anchorOffset - textLength
            : anchorOffset + textLength;
        const selection = selectionState.merge({
          anchorOffset: position,
          focusOffset: position,
        });
        const res = EditorState.forceSelection(state, selection);
        setEditorState(res);
        return 'handled';
      }
      if (command === 'handle-upward' || command === 'handle-downward') {
        const nextBlockIndex =
          command === 'handle-upward'
            ? currentBlockIndex - 1
            : currentBlockIndex + 1;
        const nextBlock = blockArray[nextBlockIndex];
        if (nextBlock?.key) {
          let selection = SelectionState.createEmpty(nextBlock.key);
          const position =
            command === 'handle-upward' ? nextBlock.getLength() : 0;
          selection = selection.merge({
            anchorOffset: position,
            focusOffset: position,
          });
          const res = EditorState.forceSelection(state, selection);
          setEditorState(res);
        } else {
          const position =
            command === 'handle-upward' ? 0 : currentBlock.getLength();
          const selection = selectionState.merge({
            anchorOffset: position,
            focusOffset: position,
          });
          const res = EditorState.forceSelection(state, selection);
          setEditorState(res);
        }
        return 'handled';
      }
      if (command === 'backspace') {
        const nextPosition = anchorOffset - 2;
        const nextContent = currentBlock.getText()[anchorOffset - 1];
        const nextEntityKey = currentBlock.getEntityAt(nextPosition);
        const nextEntity = nextEntityKey
          ? contentState.getEntity(nextEntityKey)
          : null;
        if (nextEntity && nextContent === ' ') {
          const newEditorState = handleRemove(
            {
              children: [
                {
                  props: {
                    start:
                      anchorOffset -
                      nextEntity.data.mention.curShowName.length -
                      2,
                  },
                },
              ],
              decoratedText: ` ${nextEntity.data.mention.curShowName} `,
            },
            state
          );
          setEditorState(newEditorState);
          return 'handled';
        }
        return 'not-handled';
      }
      return 'not-handled';
    },
    [setEditorState]
  );

  /** 获取计算字段类型开始 */
  const getCalIndexTypeFunc = useMemo(() => {
    return debounce(({elements: _elements, indexTypes: _indexTypes}) => {
      getCalIndexType({
        elements: _elements,
        indexTypes: _indexTypes,
      }).then((res) => {
        setIndexType(res.data);
      });
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isValid && visible && mentionsData.length > 0 && canFetchPreviewData) {
      getCalIndexTypeFunc({
        elements: formulas,
        indexTypes: mentionsData.map((item) => {
          return item.indexType;
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    visible,
    isValid,
    formulas,
    mentionsData,
    getCalIndexTypeFunc,
    tableStructureList,
    canFetchPreviewData,
  ]);
  /** 获取计算字段类型结束 */

  useEffect(() => {
    if (!resizezableRef.current) return;
    // 下方预览区域折叠
    if (previewCollapsed) {
      resizezableRef.current.updateSize({
        width: '100%',
        height: resizezableRef.current.state.height + 156,
      });
      resizezableRef.current.state.height += 156;
    } else if (isVerticalMounted.current) {
      // 展开且非初始化
      resizezableRef.current.updateSize({
        width: '100%',
        height: resizezableRef.current.state.height - 156,
      });
      resizezableRef.current.state.height -= 156;
      if (draggingSize.height < 0) {
        setDraggingSize((pre) => ({
          ...pre,
          height: 0,
        }));
      }
    }
    isVerticalMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewCollapsed, setDraggingSize]);

  useEffect(() => {
    if (!resizezableRef.current) return;
    // 左侧提示区域折叠
    if (settingCollapsed) {
      resizezableRef.current.updateSize({
        height: resizezableRef.current.state.height,
        width: resizezableRef.current.state.width + 286,
      });
      resizezableRef.current.state.width += 286;
    } else if (isHorizonMounted.current) {
      // 展开且非初始化
      resizezableRef.current.updateSize({
        height: resizezableRef.current.state.height,
        width: resizezableRef.current.state.width - 286,
      });
      resizezableRef.current.state.width -= 286;
      if (draggingSize.width < 0) {
        setDraggingSize((pre) => ({
          ...pre,
          width: 0,
        }));
      }
    }
    isHorizonMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingCollapsed, setDraggingSize]);

  async function onOk() {
    if (isValid) {
      try {
        const newMentionsData = formatMentionsData(editorState, mentionsData);
        setConfirmLoading(true);
        await headerForm.validateFields();
        parseCalIndex({
          elements: formulas,
          pointNum: indexType.includes('decimal') ? config.pointNum : null,
        }).then(async (res) => {
          const resCurShowName = headerForm.getFieldValue('curShowName');
          // 判断新增还是编辑
          const isAdd = !columnInfo.columnAlias;
          const _nameSource =
            isAdd || (!isAdd && columnInfo.curShowName === resCurShowName)
              ? 5
              : 1;
          const resFormula = res.data;
          const resMentionsData = sortMentionsData(newMentionsData).map(
            (item) => {
              // 如果引用的字段是计算字段则为数组否则为单个
              const tableId = item.formulas
                ? [
                    ...new Set(
                      JSON.parse(item.formulas)
                        .mentionsData.map((data) => data.tableId)
                        .flat()
                    ),
                  ]
                : item.tableId;
              const newItem = {
                start: item.start,
                name: item.name,
                curShowName: item.curShowName,
                columnAlias: item.originColumnAlias,
                originColumnAlias: item.originColumnAlias,
                calId: item.calId,
                entityKey: item.entityKey,
                indexType: item.indexType,
                instanceName: item.instanceName,
                instanceType: item.instanceType,
                instanceUrl: item.instanceUrl,
                instancePort: item.instancePort,
                dbName: item.dbName,
                dbRemark: item.dbRemark,
                tableId,
                tableName: item.tableName,
                tableRemark: item.tableRemark,
                formula: item.formula,
                id: item.id,
              };
              if (item.canSelect !== undefined) {
                newItem.canSelect = item.canSelect;
              }
              if (item.isEncrypt !== undefined) {
                newItem.isEncrypt = item.isEncrypt;
              }
              return newItem;
            }
          );

          const resPointNum =
            indexType.includes('bigint') || indexType.includes('decimal')
              ? config.pointNum
              : '';
          const resFormulas = JSON.stringify({
            formulas,
            placeholder: insertPlaceholder(
              editorState.getCurrentContent().getPlainText(),
              newMentionsData
            ),
            oriFormula: editorState
              .getCurrentContent()
              .getPlainText() /* .trim() */,
            mentionsData: resMentionsData,
            formula: resFormula,
            pointNum: resPointNum,
          });

          /* 修改columnAlias和curShowName */
          const newYData = totalStatus.previewYData.map((col) => {
            if (col.originColumnAlias === columnInfo.originColumnAlias) {
              return {
                ...col,
                title: config.curShowName,
                curShowName: config.curShowName,
                columnAlias: config.columnAlias,
              };
            }
            return col;
          });
          const tableXColumn = deepClone(newYData).sort(
            (a, b) => a.indexRank - b.indexRank
          );
          const resColumn = {
            calAlias: columnInfo.originColumnAlias || config.columnAlias,
            calOriName: columnInfo.curShowName || config.curShowName,
            curShowName: columnInfo.curShowName || config.curShowName,
            formula: resFormula,
            formulas: resFormulas,
            indexRank: columnInfo._index
              ? columnInfo._index
              : totalStatus.previewXOriginColumns.length,
            indexType,
            nameSource: _nameSource,
            pointNum: resPointNum,
            // indexIds: fields.map((item) => item.id),
            uniqueAliasList: newMentionsData.map(
              (item) => item.originColumnAlias
            ),
          };
          if (columnInfo.calId) {
            resColumn.calId = columnInfo.calId;
          }
          const newModifiedComputedColumns = deepClone(
            totalStatus.modifiedComputedColumns
          );
          const matchIndex = newModifiedComputedColumns.findIndex((col) => {
            // return col.calAlias === headerForm.getFieldValue('columnAlias');
            return col.calAlias === columnInfo.originColumnAlias;
          });
          if (matchIndex > -1) {
            newModifiedComputedColumns.splice(matchIndex, 1);
          }
          // 检查其他字段，如果有依赖本字段的，同步修改
          // 所有字段重新生成公式和类型
          // 收集需要更改的字段
          let finalModifiedComputedColumns = [];
          for (let i = 0; i < totalStatus.previewXOriginColumns.length; i++) {
            const col = totalStatus.previewXOriginColumns[i];
            if (
              col.columnAlias !== headerForm.getFieldValue('columnAlias') &&
              col.formulas
            ) {
              // 页面当前存在的某个计算字段
              const parsedFormulas = JSON.parse(col.formulas);
              const _formulas = parsedFormulas.formulas;
              // 遍历所依赖的元素
              for (let j = 0; j < _formulas.length; j++) {
                const _formulasItem = _formulas[j];
                if (_formulasItem.type === COLUMN_TYPE) {
                  const _content = JSON.parse(_formulasItem.content);
                  // 在某个计算字段中依赖了本计算字段
                  // 或者
                  // 一直向下递归找到嵌套的子计算字段是否依赖了本计算字段
                  const foundCol = _content.id
                    ? null
                    : totalStatus.previewXOriginColumns.find(
                        (_col) => _col.originColumnAlias === _content.calAlias
                      );
                  const isColumnRelied = foundCol
                    ? findIsColumnRelied(
                        totalStatus.previewXOriginColumns,
                        foundCol,
                        headerForm.getFieldValue('columnAlias')
                      )
                    : false;
                  if (
                    headerForm.getFieldValue('columnAlias') ===
                      _content.calAlias ||
                    isColumnRelied
                  ) {
                    _formulasItem.content = isColumnRelied
                      ? _formulasItem.content
                      : JSON.stringify({
                          ..._content,
                          name: resFormula,
                          dataType: DataTypeMap.get(indexType.toLowerCase()),
                        });
                    const _col = {
                      calAlias: col.originColumnAlias,
                      calOriName: col.calOriName,
                      curShowName: col.curShowName,
                      formula: '',
                      formulas: JSON.stringify({
                        formulas: _formulas,
                        placeholder: parsedFormulas.placeholder /* .trim() */,
                        mentionsData: parsedFormulas.mentionsData,
                        oriFormula: parsedFormulas.oriFormula /* .trim() */,
                        pointNum: col.pointNum,
                      }),
                      indexRank: col.indexRank,
                      indexType: '',
                      nameSource: col.nameSource,
                      pointNum: col.pointNum,
                      // indexIds: fields.map((item) => item.id),
                      uniqueAliasList: col.uniqueAliasList,
                    };
                    if (col.calId) {
                      _col.calId = col.calId;
                    }
                    finalModifiedComputedColumns.push(_col);
                    const index = newModifiedComputedColumns.findIndex(
                      (col2) => {
                        return col2.calAlias === col.columnAlias;
                      }
                    );
                    if (index > -1) {
                      newModifiedComputedColumns.splice(index, 1);
                    }
                    break;
                  }
                }
              }
            }
          }
          if (finalModifiedComputedColumns.length > 0) {
            newModifiedComputedColumns.push(resColumn);
            finalModifiedComputedColumns = finalModifiedComputedColumns.concat(
              newModifiedComputedColumns
            );
            finalModifiedComputedColumns = sortComputedColumns(
              finalModifiedComputedColumns
            );
            for (let i = 0; i < finalModifiedComputedColumns.length; i++) {
              const col = finalModifiedComputedColumns[i];
              const parsedFormulas = JSON.parse(col.formulas);
              const _formulas = parsedFormulas.formulas;
              // 修改indexType
              const _indexTypes = [];
              for (let j = 0; j < _formulas.length; j++) {
                const item = _formulas[j];
                if (item.type === COLUMN_TYPE) {
                  const _content = JSON.parse(item.content);
                  if (!_content.id) {
                    // 计算字段
                    const matchCol = finalModifiedComputedColumns.find(
                      (col2) => col2.calAlias === _content.calAlias
                    );
                    if (matchCol) {
                      _content.indexType = matchCol.indexType;
                      _content.dataType = DataTypeMap.get(
                        matchCol.indexType.toLowerCase()
                      );
                    }
                  }
                  _indexTypes.push(_content.indexType);
                  item.content = JSON.stringify(_content);
                }
              }
              const newIndexType = await getCalIndexType({
                elements: _formulas,
                indexTypes: _indexTypes,
              }).then((_res) => _res.data);
              col.indexType = newIndexType;
              col.pointNum = col.indexType.includes('decimal')
                ? col.pointNum
                : null;

              // 修改公式
              for (let j = 0; j < _formulas.length; j++) {
                const element = _formulas[j];
                if (element.type === COLUMN_TYPE) {
                  const content = JSON.parse(element.content);
                  if (!content.id) {
                    // 计算字段
                    const matchCol = finalModifiedComputedColumns.find(
                      (col2) => col2.calAlias === content.calAlias
                    );
                    if (matchCol) {
                      content.name = matchCol.formula;
                    }
                  }
                  element.content = JSON.stringify(content);
                }
              }
              const _mentionsData = parsedFormulas.mentionsData;
              for (let k = 0; k < _mentionsData.length; k++) {
                const element = _mentionsData[k];
                if (!element.id) {
                  // 计算字段
                  const matchCol = finalModifiedComputedColumns.find(
                    (col2) => col2.calAlias === element.columnAlias
                  );
                  if (matchCol) {
                    element.formula = matchCol.formula;
                  }
                }
              }
              col.uniqueAliasList = _mentionsData.map(
                (item) => item.originColumnAlias
              );
              const newFormula = await parseCalIndex({
                elements: _formulas,
                pointNum: col.pointNum,
              }).then((_res) => _res.data);
              parsedFormulas.formula = newFormula;
              col.formulas = JSON.stringify(parsedFormulas);
              col.formula = newFormula;
            }
            setConfirmLoading(false);
            setTotalStatus((prev) => {
              const _curComputedColumn = {
                columnInfo: {
                  ...totalStatus.curComputedColumn.columnInfo,
                  columnAlias: headerForm.getFieldValue('columnAlias'),
                  curShowName: resCurShowName,
                  pointNum: resPointNum,
                  indexType,
                  oriFormula: editorState.getCurrentContent().getPlainText(),
                  /* .trim() */ formula: resFormula,
                  formulas: resFormulas,
                  mentionsData: resMentionsData,
                },
                visible: false,
                isAfterEditComputedColumn: true,
              };
              return {
                ...prev,
                modifiedComputedColumns: finalModifiedComputedColumns,
                curComputedColumn: _curComputedColumn,
                previewYData: deepClone(newYData),
                previewXOriginColumns: tableXColumn,
              };
            });
            setReadyToUpdate(true);
          } else {
            newModifiedComputedColumns.push(resColumn);
            setConfirmLoading(false);
            setTotalStatus((prev) => {
              const _curComputedColumn = {
                columnInfo: {
                  ...totalStatus.curComputedColumn.columnInfo,
                  columnAlias: headerForm.getFieldValue('columnAlias'),
                  curShowName: resCurShowName,
                  pointNum: resPointNum,
                  indexType,
                  oriFormula: editorState.getCurrentContent().getPlainText(),
                  /* .trim() */ formula: resFormula,
                  formulas: resFormulas,
                  mentionsData: resMentionsData,
                },
                visible: false,
                isAfterEditComputedColumn: true,
              };
              return {
                ...prev,
                modifiedComputedColumns: newModifiedComputedColumns,
                curComputedColumn: _curComputedColumn,
                previewYData: deepClone(newYData),
                previewXOriginColumns: tableXColumn,
              };
            });
            setReadyToUpdate(true);
          }
        });
      } catch (err) {
        setConfirmLoading(false);
      }
    }
  }

  function onCancel() {
    setTotalStatus((pre) => {
      return {
        ...pre,
        curComputedColumn: {
          visible: false,
          columnInfo: {
            curShowName: '',
            pointNum: '2',
            formulas: [],
            uniqueAliasList: [],
            indexType: '',
            oriFormula: '',
            placeholder: '',
            mentionsData: [],
          },
        },
      };
    });
  }

  function handleDrop(targetSelection) {
    if (editorRef?.current) {
      const targetAnchorOffset = targetSelection.getAnchorOffset();
      const targetBlockKey = targetSelection.getStartKey();
      const contentState = editorState.getCurrentContent();
      const blockArray = contentState.getBlocksAsArray();
      const targetBlock = blockArray.find((item, index) => {
        return item.key === targetBlockKey;
      });
      const targetEntityKey = targetBlock.getEntityAt(targetAnchorOffset);
      if (targetEntityKey) {
        const prevOffset = targetAnchorOffset - 1;
        let finalOffset = targetAnchorOffset;
        if (prevOffset >= 0 && targetBlock.getEntityAt(prevOffset)) {
          while (targetBlock.getEntityAt(finalOffset)) {
            finalOffset++;
          }
        } else {
          finalOffset = prevOffset >= 0 ? prevOffset : 0;
        }
        targetSelection = targetSelection.merge({
          anchorOffset: finalOffset,
          focusOffset: finalOffset,
        });
      }
      const newContentState = Modifier.moveText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        targetSelection
      );
      editorRef.current.editor.update(
        EditorState.push(editorState, newContentState, 'insert-fragment')
      );
    }
    const selection = window.getSelection();
    selection.empty();
    return 'handled';
  }

  return (
    <div>
      <Resizable
        css={css`
          margin-bottom: 9px;
          width: 100% !important;
          position: relative;
        `}
        defaultSize={{
          width: '100%',
          height: 203,
        }}
        enable={{
          top: false,
          right: false,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: true,
          bottomLeft: false,
          topLeft: false,
        }}
        maxHeight={260 + (previewCollapsed ? 156 : 0)}
        maxWidth={888 + (settingCollapsed ? 286 : 0)}
        minHeight={203}
        minWidth={680}
        ref={resizezableRef}
        onResize={(e, direction, resizeRef, d) => {
          setDraggingSize((pre) => {
            return {
              width: prevDraggingSize.width + d.width + 2,
              height: prevDraggingSize.height + d.height,
            };
          });
        }}
        onResizeStart={(e, direction, resizeRef, d) => {
          setPrevDraggingSize(draggingSize);
        }}
      >
        <div
          className='editor'
          css={css`
            border: 1px solid #d8d8d8;
            border-radius: 2px;
            box-sizing: border-box;
            cursor: text;
            width: 100%;
            height: 100%;
            padding: 10px;
            overflow-y: scroll;
            .DraftEditor-root + div {
              max-width: max-content;
            }
            .mnw6qvm {
              max-height: 480px;
              overflow: auto;
              ${overflowScrollBar}
            }
          `}
          onClick={() => {
            editorRef.current?.focus();
          }}
        >
          <Editor
            editorState={editorState}
            handleDrop={handleDrop}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={(e) => {
              if (e.keyCode === 37) {
                return 'handle-backward';
              } else if (e.keyCode === 39) {
                return 'handle-forward';
              } else if (e.keyCode === 38) {
                return 'handle-upward';
              } else if (e.keyCode === 40) {
                return 'handle-downward';
              }
              return getDefaultKeyBinding(e);
            }}
            plugins={plugins}
            ref={editorRef}
            onChange={(state) => {
              // pageEditorState = state;
              setEditorState(state);
            }}
          />
          <MentionSuggestions
            entryComponent={Entry}
            open={open}
            suggestions={suggestions}
            onOpenChange={onOpenChange}
            onSearchChange={onSearchChange}
          />
        </div>
        <IconFont
          css={css`
            position: absolute;
            right: 0;
            bottom: 0;
          `}
          type='icon-mark'
        />
      </Resizable>
      <div
        css={css`
          text-align: right;
          .ant-btn {
            height: 26px;
            line-height: 26px;
            padding: 0 15px;
          }
        `}
      >
        <span
          css={css`
            color: ${isValid ? '#00A870' : '#E34D59'};
            margin-right: 15px;
          `}
        >
          {isValid ? '计算有效' : '计算无效'}
        </span>
        <Button
          css={css`
            margin-right: 10px;
          `}
          onClick={onCancel}
        >
          取消
        </Button>
        <Button loading={confirmLoading} type='primary' onClick={onOk}>
          确认
        </Button>
      </div>
    </div>
  );
}
