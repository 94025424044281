import {css} from '@emotion/react';
import {useAtom} from 'jotai';
import React, {useMemo} from 'react';

import {logicEditAtom} from '../../../atoms/logicEditAtom';
import {calcColHideRepeatStyle} from '../../../containers/DataManagement/MicroIndex/LogicEditPage/helpers';
import {textOverFlow} from '../../../global.css';
import useSafeState from '../../../hooks/useSafeState';
import {TableCellInput} from '../../TextHoverToInput';
import FilterButton from './FilterButton';
import {previewBlingStyle} from './styles.css';

/**
 * @param {object} props
 * @param {import('../type').CustomColumnType} props.column
 * @param {string} props.tableKey
 */
export default function FilterColumn(props) {
  const {column, tableKey} = props;
  const {
    title,
    colCss = '',
    dataIndex,
    toolTip,
    hasRepeat,
    filterType,
  } = column;

  const [titleEditing, setTitleEditing] = useSafeState(false);

  const showFilter = useMemo(() => {
    return filterType !== 'Sort';
  }, [filterType]);

  const [totalStatus, setTotalStatus] = useAtom(logicEditAtom);
  const {previewXOriginColumns, previewBling} = totalStatus;

  const onCancelEditTitle = () => {
    setTitleEditing(false);
  };

  const onSaveEditTitle = (newVal) => {
    const newTxt = newVal.trim();

    const newColumns = previewXOriginColumns.map((col) => {
      if (col.dataIndex === dataIndex) {
        col.title = newTxt || col.title;
        col.curShowName = newTxt || col.title;
      }

      return col;
    });

    setTitleEditing(false);

    setTotalStatus((pre) => ({
      ...pre,
      previewXOriginColumns: calcColHideRepeatStyle(newColumns),
    }));
  };

  return (
    <div
      css={css`
        ${colCss}
        ${hasRepeat && previewBling ? previewBlingStyle : null}
      `}
    >
      <FilterButton
        column={column}
        filterTableKey={tableKey}
        setTitleEditing={setTitleEditing}
        showFilter={showFilter}
      />

      <div
        css={css`
          ${textOverFlow}
          margin-top:  ${column?.extraFilterItems?.length > 0 ? 0 : '2px'};
          line-height: 14px;
        `}
      >
        {titleEditing ? (
          <TableCellInput
            defaultValue={title}
            size='small'
            onCancel={onCancelEditTitle}
            onSave={onSaveEditTitle}
          />
        ) : toolTip ? (
          toolTip(column)
        ) : (
          title
        )}
      </div>
    </div>
  );
}
