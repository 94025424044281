import {css} from '@emotion/react';
import {Dropdown, Image, Layout as ALayout, Menu} from 'antd';
import {useAtom} from 'jotai';
import React from 'react';
import {useMount} from 'react-use';

import Icon_Head from '../../../assets/images/Icon_Head@2x.png';
import {fetchSysSettingAtom} from '../../../atoms/sysSettingAtom';
import {fetchUserInfoAtom} from '../../../atoms/userInfoAtom';
import {filterAtom} from '../../../components/Filter/atoms/filterAtom';
import {layoutConfig, triggerType} from '../../../constants/layout';
import {selfInfoPath} from '../../../constants/system';
import {logoutHandle, routeHistory} from '../../../helpers/history';
import useSafeState from '../../../hooks/useSafeState';
import {headerCSS, logoCSS, userCSS, userInfoCss} from '../layout.css';
import Deadline from './Deadline';

/* const getHelpDoc = () => {
  downloadFileByUrl(DOWNLOAD_HELP_DOC);
}; */

function UserMenu({clearFilterAtom, setDropdownVisible}) {
  const onLogoutHandle = () => {
    clearFilterAtom({
      visible: false,
      tableKey: '',
      conditions: {},
      column: {},
    });
    logoutHandle();
  };
  return (
    <Menu
      css={css`
        text-align: center;
        width: 140px;
        color: #253145;
        padding: 0;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15) !important;
        .ant-dropdown-menu-item {
          padding: 0;
          height: 34px;
          line-height: 34px;
          text-align: center;
        }
        .ant-menu-item-selected {
          background-color: rgba(37, 49, 69, 0.1) !important;
          color: #253145 !important;
          border-radius: 2px;
        }
        .ant-dropdown-menu-item:hover,
        .ant-dropdown-menu-submenu-title:hover {
          background: rgba(37, 49, 69, 0.1);
        }
        .ant-menu-item:hover {
          color: #253145;
        }
        .ant-menu-item:not(:last-child) {
          margin-top: 0px;
        }
        .ant-menu-item {
          margin-bottom: 0px;
        }
      `}
    >
      <Menu.Item
        key='1'
        onClick={() => {
          routeHistory.push(selfInfoPath);
          setDropdownVisible(false);
        }}
      >
        个人中心
      </Menu.Item>
      <Menu.Item
        key='2'
        onClick={() => {
          onLogoutHandle();
          setDropdownVisible(false);
        }}
      >
        退出登录
      </Menu.Item>
    </Menu>
  );
}

export default function Header({breadcrumbsMenu, collapse}) {
  const {TITLE, LOGO, SHOW_LOGO} = layoutConfig.sidebar;

  const [dropdownVisible, setDropdownVisible] = useSafeState(false);

  const [sysSettingDetail, freshSysSettingAtom] = useAtom(fetchSysSettingAtom);

  const [, clearFilterAtom] = useAtom(filterAtom);

  const [userInfo, freshUserInfo] = useAtom(fetchUserInfoAtom);
  // console.log('breadcrumbsMenu', breadcrumbsMenu);

  useMount(() => {
    freshUserInfo();
    freshSysSettingAtom();
  });

  return (
    <ALayout.Header css={headerCSS}>
      <div className='headerLeft'>
        {SHOW_LOGO && (
          <div
            className='logo'
            css={css`
              ${logoCSS}
              min-width: ${collapse ? '56px' : '202px'};
            `}
            // onClick={() => routeHistory.push('/')}
          >
            <Image
              height={28}
              preview={false}
              src={sysSettingDetail.logo ?? LOGO}
              width={28}
            />
            {!collapse && (
              <div className='sysName'>{sysSettingDetail.name ?? TITLE}</div>
            )}
          </div>
        )}

        {/* {breadcrumbsMenu?.length > 0 && (
          <Breadcrumb className='breadcrumb'>
            {(Array.isArray(breadcrumbsMenu)
              ? breadcrumbsMenu
              : breadcrumbsMenu?.split('/')
            )?.map((menu) => {
              return <Breadcrumb.Item key={menu}>{menu}</Breadcrumb.Item>;
            })}
          </Breadcrumb>
        )} */}
      </div>

      <Deadline />

      <div className='user' css={userCSS}>
        {/* <FullScreenButton />
        <div className='downloadDoc'>
          <span
            css={css`
              cursor: pointer;
              ${normalLink}
              &:hover {
                ${hoverLink}
              }
            `}
            onClick={getHelpDoc}
          >
            <IconFont
              className='icon icon-doc'
              css={css`
                width: 12px;
                height: 12px;
              `}
              type='icon-assist1'
            />
            帮助文档
          </span>
        </div> */}
        {/* <LetterButton /> */}
        {userInfo && (
          <Dropdown
            destroyPopupOnHide
            overlay={
              <UserMenu
                clearFilterAtom={clearFilterAtom}
                setDropdownVisible={setDropdownVisible}
              />
            }
            trigger={triggerType}
            visible={dropdownVisible}
            onVisibleChange={(flag) => {
              setDropdownVisible(flag);
            }}
          >
            <div css={userInfoCss}>
              <span
                className='name'
                title={`${userInfo.realName}(${userInfo.userName})`}
              >
                {userInfo.realName}({userInfo.userName})
              </span>
              <img className='avatar' src={Icon_Head} />
              {/* <IconFont className='icon-dropdown' type='icon-drop-down' /> */}
            </div>
          </Dropdown>
        )}
      </div>
    </ALayout.Header>
  );
}
