import {SearchOutlined} from '@ant-design/icons';
import styled from '@emotion/styled';
import {Input} from 'antd';
import {useAtom} from 'jotai';
import React, {useRef} from 'react';
import {useSearchParams} from 'react-router-dom';

import {logicEditAtom} from '../../../../../atoms/logicEditAtom';
import {overflowScrollBar} from '../../../../../global.css';
import {filterTree} from '../../../../../helpers/utils';
import MenuTree from '../../../DataTableManagement/components/MicroDataQueryMenu/MenuTree';

const Content = styled.div`
  height: 296px;
  display: flex;
  flex-direction: column;
  border: 1px solid #eeeeee;
  padding: 12px;
  overflow: hidden;
  border-radius: 2px;
`;

const TreeWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  ${overflowScrollBar}
`;

export default function SheetTree({
  visible,
  treeApi,
  isWideList,
  wideIdProp,
  onSelect,
}) {
  const [searchParams] = useSearchParams();

  const [totalStatus] = useAtom(logicEditAtom);

  const {graphData} = totalStatus;

  const {tableStructureList} = graphData;
  console.log('🚀 ~ tableStructureList:', tableStructureList);
  const treeRef = useRef();

  const onSelectCallBack = (keys, info) => {
    onSelect(info);
  };

  const onSearch = (value) => {
    const originTreeData = treeRef.current.getOriginList();

    if (value) {
      const searchList = filterTree(originTreeData, (item) => {
        return item?.name?.includes(value) || item?.tableName?.includes(value);
      });
      treeRef.current.handleTreeData(searchList);
    } else {
      treeRef.current.handleTreeData(originTreeData);
    }
    treeRef.current.handleExpand(true);
  };

  return (
    <Content>
      <Input
        placeholder='搜索'
        size='small'
        suffix={<SearchOutlined style={{color: 'rgba(26, 34, 48, 0.25)'}} />}
        onChange={(e) => {
          onSearch(e.target.value);
          onSelect({});
        }}
      />
      <TreeWrapper>
        {visible && (
          <MenuTree
            onlyView
            isWideList={isWideList}
            needPermission={searchParams.get('tabIndex') === '2'}
            onlyFolder={(data) => {
              return (
                data.pathType === 3 &&
                data.tableType === 3 &&
                data.managePerm === true &&
                !tableStructureList
                  .map((t) => t.tableId)
                  .some((structureId) => structureId === data.id)
              );
            }}
            ref={treeRef}
            // treeApi={treeApi}
            selectFolderable={false}
            // ???
            visible={visible}
            wideIdProp={wideIdProp}
            onSelectCallBack={onSelectCallBack}
          />
        )}
      </TreeWrapper>
    </Content>
  );
}
