import {Select, Spin} from 'antd';
import * as React from 'react';
import {useThrottle} from 'react-use';
import useSWR from 'swr';

export default function DebounceSelect({
  fetchService,
  paramFiled = 'curName',
  fieldNames = {label: 'curName', value: 'sourceRid'},
  throttleTime = 1000,
  ...props
}) {
  const [keyWord, setKeyWord] = React.useState('');
  const debouncedKeyword = useThrottle(keyWord, throttleTime);

  const {data, isValidating} = useSWR(
    debouncedKeyword ? debouncedKeyword : null,
    (name) => fetchService({[paramFiled]: name})
  );

  const optionsList = data?.data?.map((v) => ({
    label: v[fieldNames.label],
    value: v[fieldNames.value],
  }));

  return (
    <Select
      allowClear
      showSearch
      filterOption={false}
      notFoundContent={
        debouncedKeyword && isValidating ? <Spin size='small' /> : null
      }
      options={optionsList}
      onSearch={(val) => setKeyWord(val)}
      {...props}
    />
  );
}
