import {css} from '@emotion/react';
import {Form, Input} from 'antd';
import {EditorState} from 'draft-js';
import Immutable from 'immutable';
import {useAtom} from 'jotai';
import React, {createContext, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useLocalStorage} from 'react-use';

import {getCalIndexData} from '../../api/dataManagement/microIndex';
import {logicEditAtom} from '../../atoms/logicEditAtom';
import useFetchWideTable from '../../containers/DataManagement/MicroIndex/LogicEditPage/hooks/useFetchWideTable';
import {overflowScrollBar, textOverFlow} from '../../global.css';
import useSafeState from '../../hooks/useSafeState';
import GlobalModal from '../GlobalModal';
import Editor from './components/Editor';
import {findIsColumnRelied} from './components/editorContentConfig';
import {HAS_COLUMN_PERM} from './components/editorContentConfig';
import Preview from './components/Preview';
import Setting from './components/Setting';
const {Map: IMap} = Immutable;

const containerCss = css`
  /* min-height: 488px; */
  &::after {
    content: '';
    clear: both;
  }
`;

const contentCss = css`
  border: 1px solid rgba(26, 34, 48, 0.15);
`;

export const TECH_INDEX = 3;

export const EditContext = createContext();

export const useLogicAtom = (
  isInMicroIndexPage,
  totalStatusProps,
  updateTable
) => {
  const {updatePreviewTable} = useFetchWideTable();
  const [totalStatus, setTotalStatus] = useAtom(logicEditAtom);
  const {tableStructureList} = totalStatus.graphData;
  const {visible, columnInfo} = totalStatus.curComputedColumn;
  const {previewAutoUpdate} = totalStatus;

  if (isInMicroIndexPage) {
    return {
      totalStatus,
      setTotalStatus,
      tableStructureList,
      visible,
      columnInfo,
      previewAutoUpdate,
      updatePreviewTable,
    };
  }
  const {totalStatus: _totalStatus, setTotalStatus: _setTotalStatus} =
    totalStatusProps;
  return {
    totalStatus: _totalStatus,
    setTotalStatus: _setTotalStatus,
    tableStructureList: _totalStatus.graphData.tableStructureList,
    visible: _totalStatus.curComputedColumn.visible,
    columnInfo: _totalStatus.curComputedColumn.columnInfo,
    previewAutoUpdate: _totalStatus.previewAutoUpdate,
    updatePreviewTable: updateTable,
    tableName: _totalStatus.tableName,
  };
};

const validIndexTypes = [
  'int',
  'bigint',
  'decimal',
  'decimal(32,16)',
  'varchar',
  'text',
  'datetime',
];

/**
 * @param {object} props
 * @param {boolean=} props.isInMicroIndexPage
 * @param {object} props.totalStatus
 * @param {function=} props.updateTable
 * @param {function=} props.preivewDataApi
 */
export default function ComputedFieldEditor(props) {
  const {
    isInMicroIndexPage = true,
    totalStatus: totalStatusProps,
    updateTable,
    preivewDataApi = getCalIndexData,
  } = props;

  const {
    totalStatus,
    setTotalStatus,
    tableStructureList,
    visible,
    columnInfo,
    previewAutoUpdate,
    updatePreviewTable,
    tableName,
  } = useLogicAtom(isInMicroIndexPage, totalStatusProps, updateTable);

  /** 字段列表搜索 */
  const [searchValue, setSearchValue] = useSafeState('');
  /** 选择新增字段列表 */
  const [sideAdditionItem, setSideAdditionItem] = useSafeState(null);
  /** 左侧展开折叠 */
  const [settingCollapsed, setSettingCollapsed] = useLocalStorage(
    '_Computed-Setting-collapsed_'
  );

  /** 编辑框 */
  const [editorState, setEditorState] = useSafeState(() =>
    EditorState.createEmpty()
  );

  /** 字段列表数据 */
  const [mentions, setMention] = useSafeState([]);
  const [config, setConfig] = useSafeState({
    curShowName: '',
    columnAlias: '',
    pointNum: '2',
  });
  /** 计算是否有效 */
  const [isValid, setIsValid] = useSafeState(false);
  /** 取 columnInfo.formulas */
  const [formulas, setFormulas] = useSafeState([]);
  /** 取 columnInfo... */
  const [rawFormulaAndMention, setRawFormulaAndMention] = useSafeState({
    oriFormula: '',
    placeholder: '',
    mentionsData: [],
  });

  const [mentionsData, setMentionsData] = useSafeState([]);
  /** 取 columnInfo.indexType */
  const [indexType, setIndexType] = useSafeState([]);
  /** 调用 updatePreviewTable */
  const [readyToUpdate, setReadyToUpdate] = useSafeState(false);
  const [canFetchPreviewData, setCanFetchPreviewData] = useSafeState(false);
  /** 拖拽 */
  const [draggingSize, setDraggingSize] = useSafeState({width: 0, height: 0});
  /** 预览展开折叠 */
  const [previewCollapsed, setPreviewCollapsed] = useLocalStorage(
    '_Computed-Preview-collapsed_'
  );

  /* const calAlias = useMemo(() => {
    const isAdd = !columnInfo.columnAlias;
    // 生成别名判断
    // 如果是新增，在最后一个计算字段上加一
    // 如果是编辑沿用原有字段
    const allComputedColumns = totalStatus.previewXOriginColumns.filter(
      (item) => {
        return !!item.formulas;
      }
    );
    for (let i = 0; i < totalStatus.modifiedComputedColumns.length; i++) {
      const col = totalStatus.modifiedComputedColumns[i];
      if (
        allComputedColumns.every((col2) => col2.columnAlias !== col.calAlias)
      ) {
        allComputedColumns.push({
          ...col,
          columnAlias: col.calAlias,
        });
      }
    }
    const finalIndex =
      allComputedColumns.length > 0
        ? allComputedColumns
            .map((item) => {
              const splitItems = item.columnAlias.split('_');
              return splitItems[splitItems.length - 1];
            })
            .sort((a, b) => a - b)[allComputedColumns.length - 1]
        : 0;
    return isAdd
      ? `${calNameFmt}${Number(finalIndex) + 1}`
      : columnInfo.columnAlias;
  }, [
    columnInfo.columnAlias,
    totalStatus.previewXOriginColumns,
    totalStatus.modifiedComputedColumns,
    calNameFmt,
  ]); */

  const [searchParams] = useSearchParams();
  const wideId = searchParams.get('wideId');

  const [headerForm] = Form.useForm();

  useEffect(() => {
    if (visible) {
      const columnList = totalStatus.previewXOriginColumns;
      const columns = new Map([]);
      for (let i = 0; i < columnList.length; i++) {
        const item = columnList[i];
        // 跳过隐藏的字段或者本字段或者页面上已删除的已入库字段
        if (
          !item.isShow ||
          item.curShowName === columnInfo.curShowName ||
          (item.calId &&
            totalStatus.hasDelComputedColumns.findIndex(
              (col2) => col2.calId === item.calId
            ) > -1) ||
          (item.fieldType &&
            validIndexTypes.indexOf(item.fieldType.toLowerCase()) === -1)
        )
          continue;

        // 跳过依赖本字段的字段
        if (item.uniqueAliasList?.length) {
          let flag = false;
          for (let j = 0; j < item.uniqueAliasList.length; j++) {
            const alias = item.uniqueAliasList[j];
            if (alias === columnInfo.originColumnAlias) {
              flag = true;
              break;
            } else if (
              columnList.find((col) => col.originColumnAlias === alias)?.formula
            ) {
              flag = findIsColumnRelied(
                columnList,
                columnList.find((col) => col.originColumnAlias === alias),
                columnInfo.originColumnAlias
              );
            }
          }
          if (flag) continue;
        }

        let key = `${item.instanceId}${item.dbId}${item.tableId}`;
        if (item.formulas) {
          key = '计算字段';
        } else if (
          (item.formula && !item.formulas) ||
          item.indexClassify === TECH_INDEX
        ) {
          key = tableName;
        }

        const mapping = columns.get(key);
        if (mapping) {
          const t = mapping[1];
          columns.set(key, [
            mapping[0],
            t.concat([
              {
                ...item,
                mentionName: `${item.curShowName}(${item.columnAlias})`,
                name: item.curShowName,
                keyWords: [
                  item.curShowName,
                  item.sysShowName,
                  item.comment,
                  item.indexOriName,
                ],
                type: 'column',
              },
            ]),
          ]);
        } else {
          /* let name = `${item.instanceName}(${item.instanceType},${
            item.instanceUrl
          }:${item.instancePort}):${item.dbName}.${item.tableName}(${
            item.tableRemark || item.tableComment
          })`; */
          let name = `${item.dbName}.${item.tableName}(${
            item.tableRemark || item.tableComment
          })`;
          if (item.formulas) {
            name = '计算字段';
          } else if (
            (item.formula && !item.formulas) ||
            item.indexClassify === TECH_INDEX
          ) {
            name = tableName;
          }
          columns.set(key, [
            name,
            [
              {
                ...item,
                mentionName: `${item.curShowName}(${item.columnAlias})`,
                name: item.curShowName,
                keyWords: [
                  item.curShowName,
                  item.sysShowName,
                  item.comment,
                  item.indexOriName,
                ],
                type: 'column',
              },
            ],
          ]);
        }
      }

      // [...columns.entries()].forEach(([key, [name, children]]) => {
      //   let delFlag = true;
      //   children.forEach((c) => {
      //     if (
      //       c.canSelect === undefined ||
      //       (c.canSelect !== undefined && c.canSelect === HAS_COLUMN_PERM) ||
      //       c.isEncrypt === undefined ||
      //       (c.isEncrypt !== undefined && c.isEncrypt !== null)
      //     ) {
      //       delFlag = false;
      //     }
      //   });
      //   if (delFlag) {
      //     columns.delete(key);
      //   }
      // });

      const _mentions = [...columns.entries()].map(
        ([key, [name, children]]) => {
          let delFlag = true;
          children.forEach((c) => {
            if (
              c.canSelect === undefined ||
              (c.canSelect !== undefined && c.canSelect === HAS_COLUMN_PERM) ||
              c.isEncrypt === undefined ||
              (c.isEncrypt !== undefined && c.isEncrypt !== null)
            ) {
              delFlag = false;
            }
          });
          return {
            type: 'container',
            name,
            delFlag,
            children,
          };
        }
      );
      setMention(_mentions);
      // });
      const formValues = {
        curShowName: columnInfo.curShowName,
        columnAlias: columnInfo.columnAlias,
        pointNum: columnInfo.pointNum,
      };
      setFormulas(columnInfo.formulas);
      setConfig(formValues);
      setIndexType(columnInfo.indexType);
      setRawFormulaAndMention({
        oriFormula: columnInfo.oriFormula,
        placeholder: columnInfo.placeholder,
        mentionsData: columnInfo.mentionsData,
      });
      headerForm.setFieldsValue(formValues);
    } else {
      setIsValid(false);
      setFormulas([]);
      setIndexType('');
      setDraggingSize({
        width: 0,
        height: 0,
      });
      setRawFormulaAndMention({
        oriFormula: '',
        placeholder: '',
        mentionsData: [],
      });
      setMentionsData([]);
      setSearchValue('');
      setSideAdditionItem(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    totalStatus.previewXOriginColumns,
    columnInfo.placeholder,
    columnInfo.oriFormula,
    columnInfo.mentionsData,
    columnInfo.columnAlias,
    columnInfo.indexType,
    setIndexType,
    visible,
    tableStructureList,
    wideId,
    setMention,
    setFormulas,
    setConfig,
    columnInfo.curShowName,
    columnInfo.formulas,
    columnInfo.pointNum,
    columnInfo.uniqueAliasList,
    headerForm,
    setIsValid,
    totalStatus.modifiedComputedColumns,
    setDraggingSize,
    setRawFormulaAndMention,
    setMentionsData,
    totalStatus.hasDelComputedColumns,
    setSearchValue,
    setSideAdditionItem,
  ]);

  useEffect(() => {
    if (readyToUpdate) {
      setReadyToUpdate(false);
      if (previewAutoUpdate) updatePreviewTable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToUpdate]);

  useEffect(() => {
    if (!visible && editorState?.getCurrentContent()?.entityMap) {
      // eslint-disable-next-line new-cap
      editorState.getCurrentContent().entityMap.__loadWithEntities(IMap());
      setEditorState(EditorState.createEmpty());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  function onCancel() {
    setTotalStatus((pre) => {
      return {
        ...pre,
        curComputedColumn: {
          visible: false,
          columnInfo: {
            curShowName: '',
            pointNum: '2',
            formulas: [],
            uniqueAliasList: [],
            indexType: '',
            oriFormula: '',
            placeholder: '',
            mentionsData: [],
          },
        },
      };
    });
  }

  if (!visible) {
    return null;
  }

  return (
    <GlobalModal
      footer={null}
      title='计算字段编辑'
      visible={visible}
      width={1450 + draggingSize.width}
      onCancel={onCancel}
    >
      <EditContext.Provider
        value={{
          // calAlias,
          config,
          visible,
          setConfig,
          isValid,
          setIsValid,
          formulas,
          setFormulas,
          mentions,
          headerForm,
          settingCollapsed,
          setSettingCollapsed,
          indexType,
          setIndexType,
          draggingSize,
          setDraggingSize,
          previewCollapsed,
          setPreviewCollapsed,
          rawFormulaAndMention,
          setMentionsData,
          mentionsData,
          setRawFormulaAndMention,
          readyToUpdate,
          setReadyToUpdate,
          canFetchPreviewData,
          setCanFetchPreviewData,
          editorState,
          setEditorState,
          isInMicroIndexPage,
          totalStatusProps,
          preivewDataApi,
          sideAdditionItem,
          setSideAdditionItem,
        }}
      >
        <div
          className='computedEditor'
          css={css`
            ${containerCss}
            .ant-tooltip {
              max-width: max-content;
            }
            height: ${`${480 + draggingSize.height}px`};
          `}
        >
          <Setting />
          <div
            css={css`
              ${contentCss}
              margin-left: ${settingCollapsed ? 0 : '286px'};
              height: 100%;
              &::after {
                content: '';
                clear: both;
              }
            `}
          >
            <div
              css={css`
                width: 240px;
                float: left;
              `}
            >
              <div
                css={css`
                  display: flex;
                  gap: 5px;
                  height: 54px;
                  line-height: 28px;
                  padding-top: 10px;
                  padding-left: 5px;
                `}
              >
                <Form.Item label='字段列表'>
                  <Input
                    allowClear
                    css={css`
                      width: 160px;
                    `}
                    maxLength={50}
                    placeholder='搜索'
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </Form.Item>
              </div>
              <div
                css={css`
                  padding: 0 5px 0 15px;
                  ${overflowScrollBar}
                  overflow: scroll;
                  max-height: 420px;
                `}
              >
                {mentions.map((mention) => {
                  return (
                    <>
                      {mention.delFlag ? null : (
                        <h2
                          css={textOverFlow}
                          style={{
                            height: '24px',
                            lineHeight: '24px',
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                            letterSpacing: 0,
                          }}
                          title={mention.name}
                        >
                          {mention.name}
                        </h2>
                      )}
                      {mention.children?.map((item) => {
                        return (
                          <div
                            css={css`
                              display: ${item.delFlag === 1 ||
                              (item.canSelect !== undefined &&
                                item.canSelect !== HAS_COLUMN_PERM) ||
                              (item.isEncrypt !== undefined &&
                                item.isEncrypt) ||
                              (item.isVisibleInCal !== undefined &&
                                !item.isVisibleInCal) ||
                              (searchValue &&
                                !item.mentionName.includes(searchValue))
                                ? 'none'
                                : 'block'};
                            `}
                            key={item.mentionName}
                          >
                            <div
                              css={css`
                                color: rgba(37, 49, 69, 0.7);
                                letter-spacing: 0;
                                white-space: nowrap;
                                cursor: pointer;
                                ${textOverFlow}
                                height: 24px;
                                line-height: 24px;
                                &:hover {
                                  background-color: lightblue;
                                  color: #fff;
                                }
                              `}
                              title={item.mentionName}
                              onClick={() => {
                                setSideAdditionItem({
                                  item,
                                  offset: editorState
                                    .getSelection()
                                    .getAnchorOffset(),
                                });
                              }}
                            >
                              {item.mentionName}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  );
                })}
              </div>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <Editor />
              <Preview />
            </div>
          </div>
        </div>
      </EditContext.Provider>
    </GlobalModal>
  );
}
