import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {Form, Spin} from 'antd';
import {useAtom} from 'jotai';
import {useCallback, useEffect, useMemo, useRef} from 'react';

import {getColumnSchemeList} from '../../../../../api/dataManagement/wideTable';
import {logicEditAtom} from '../../../../../atoms/logicEditAtom';
import {sqlWideEditAtom} from '../../../../../atoms/sqlWideEditAtom';
import EditableTable from '../../../../../components/EditableTable';
import FormRequireLabel from '../../../../../components/FormRequireLabel';
import {deepClone} from '../../../../../helpers/utils';
import useSafeState from '../../../../../hooks/useSafeState';
import {INDEX_TYPE} from '../../helpers/constant';

const MatchWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleStyle = styled.div`
  padding-left: 0px;
`;

const TargetSheetField = styled.div`
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
`;

const FieldMatchWrapper = ({value, onChange, children}) => {
  return <MatchWrapper>{children}</MatchWrapper>;
};

export default function FieldMatchFormItem({
  tableId,
  form,
  visible,
  isSqlWide,
}) {
  const [totalStatus, setTotalStatus] = useAtom(logicEditAtom);
  const {previewXOriginColumns} = totalStatus;

  const [allState] = useAtom(sqlWideEditAtom);
  const {previewXColumn} = allState;

  const [columnSchemeList, setColumnSchemeList] = useSafeState([]);
  const [loading, setLoading] = useSafeState(false);

  const formTableRef = useRef();

  const filterHideXColumns = useMemo(() => {
    return previewXOriginColumns.filter((c) => c.isShow);
  }, [previewXOriginColumns]);

  const sourceFieldOptions = useMemo(() => {
    return (isSqlWide ? previewXColumn : filterHideXColumns).map((col) => ({
      value: `${col.sortKeys}-${col.columnAlias}`,
      label: (
        <TargetSheetField>
          <span>{col.curShowName || col.columnAlias}</span>
          <span>{INDEX_TYPE.get(col?.indexType.toUpperCase())}</span>
        </TargetSheetField>
      ),
      key: col.curShowName || col.columnAlias,
      // label: col.curShowName || col.columnAlias,
    }));
  }, [isSqlWide, previewXColumn, filterHideXColumns]);

  const onMatchField = useCallback(
    (targetList = []) => {
      const originColumns = deepClone(
        (isSqlWide ? previewXColumn : previewXOriginColumns).filter(
          (col) => col.isShow
        )
      );
      const alReadyMatched = []; // 已经匹配成功的字段id
      targetList.forEach((tItem) => {
        originColumns.forEach((col) => {
          // 已经匹配过的不需要再匹配
          if (!alReadyMatched.includes(`${col.sortKeys}-${col.columnAlias}`)) {
            if (
              col?.columnAlias.toUpperCase() ===
                tItem?.columnName.toUpperCase() ||
              col.curShowName === tItem.curShowName
            ) {
              tItem.left = `${col.sortKeys}-${col.columnAlias}`;
              alReadyMatched.push(`${col.sortKeys}-${col.columnAlias}`);
            }
          }
        });
      });
      return targetList;
    },
    [isSqlWide, previewXColumn, previewXOriginColumns]
  );

  const fetchColumnSchemeList = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const {data} = await getColumnSchemeList({tableId: id});
        const targetList = onMatchField(data);
        setColumnSchemeList(targetList);
        setTotalStatus((pre) => ({
          ...pre,
          matchFieldColumns: targetList,
        }));
        setLoading(false);
      } catch (e) {
        setLoading(false);
        throw new Error('Get column scheme list failed');
      }
    },
    [onMatchField, setColumnSchemeList, setLoading, setTotalStatus]
  );

  const validateFieldMatchResult = useCallback(() => {
    const hasMatchField = columnSchemeList.filter((col) => col.left);
    if (hasMatchField.length === 0)
      return Promise.reject(new Error('至少选择一个匹配字段！'));
    return Promise.resolve();
  }, [columnSchemeList]);

  const onDataChange = (newVal) => {
    const formInTable = formTableRef.current.getFormInstance();
    const formValue = formInTable.getFieldsValue()?.data ?? [];

    setTotalStatus((pre) => ({
      ...pre,
      matchFieldColumns: formValue,
    }));
    form.setFields([{name: 'matchFieldResult', errors: null}]);
    setColumnSchemeList(formValue);
  };

  useEffect(() => {
    setColumnSchemeList([]);
    if (tableId && visible) {
      fetchColumnSchemeList(tableId);
    }
  }, [fetchColumnSchemeList, setColumnSchemeList, tableId, visible]);

  useEffect(() => {
    if (!visible) {
      setColumnSchemeList([]);
    }
  }, [setColumnSchemeList, visible]);

  const columns = useMemo(
    () => [
      {
        title: () => <TitleStyle>源表字段</TitleStyle>,
        dataIndex: 'left',
        editable: true,
        allowClear: true,
        showSearch: true,
        componentType: 'Select',
        align: 'left',
        dropdownStyle: {
          // maxWidth: 660,
          whiteSpace: 'nowrap',
        },
        filterOption: (inputValue, option) => {
          const [, name] = option.value.split('-');
          return name.includes(inputValue);
        },
        selectStyle: {width: 230},
        dropdownMatchSelectWidth: false,
        options: (record, index) => {
          const formInTable = formTableRef.current.getFormInstance();
          const alReadyMatchedValue = (formInTable.getFieldsValue()?.data ?? [])
            ?.filter((d) => d.left)
            .map((d) => d.left)
            .filter((d) => d !== record.left);

          return sourceFieldOptions.filter(
            (option) => !alReadyMatchedValue.includes(option?.value)
          );
        },
      },
      {
        title: ' ',
        dataIndex: 'equal',
        align: 'center',
        editable: false,
        width: 36,
        render: () => <span style={{fontSize: 16}}>=</span>,
      },
      {
        title: () => <TitleStyle>目标表字段</TitleStyle>,
        dataIndex: 'right',
        editable: false,
        width: 230,
        render: (val, row) => (
          <TargetSheetField>
            <span>{row?.curShowName || row?.columnName}</span>
            <span>{INDEX_TYPE.get(row?.indexType.toUpperCase())}</span>
          </TargetSheetField>
        ),
      },
    ],
    [sourceFieldOptions]
  );

  return (
    <Spin spinning={loading}>
      <Form.Item
        label={<FormRequireLabel title='字段匹配' />}
        name='matchFieldResult'
        rules={[{validator: validateFieldMatchResult}]}
        validateTrigger={['onBlur', 'onChange']}
      >
        <FieldMatchWrapper>
          {columnSchemeList.length > 0 && (
            <EditableTable
              bordered
              columns={columns}
              css={css`
                th.ant-table-cell {
                  text-align: center !important;
                }
                th.ant-table-cell:nth-of-type(1) {
                  border-right: 0 !important;
                }

                th.ant-table-cell:nth-of-type(2),
                th.ant-table-cell:nth-of-type(3) {
                  border-left: 0 !important;
                  border-right: 0 !important;
                }

                td.ant-table-cell {
                  padding: 0 !important;
                }
              `}
              // dataFieldName='matchFieldColumns'
              dataSource={columnSchemeList}
              ref={formTableRef}
              onDataChange={onDataChange}
            />
          )}
        </FieldMatchWrapper>
      </Form.Item>
    </Spin>
  );
}
