import {InfoCircleOutlined} from '@ant-design/icons';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {arrayMove, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {css} from '@emotion/react';
import {Checkbox, Form, Input} from 'antd';
import {useAtom} from 'jotai';
import React, {useRef, useMemo, useEffect, useCallback} from 'react';

import {logicEditAtom} from '../../../../../../atoms/logicEditAtom';
import {TableComponentsBodyRow} from '../../../../../../components/DragSorter';
import {filterAtom} from '../../../../../../components/Filter/atoms/filterAtom';
import IconFont from '../../../../../../components/IconFont';
import FilterButton from '../../../../../../components/TableSheet/components/FilterButton';
import {
  showRowTitleStyle,
  hidedRowTitleStyle,
  delRowStyle,
} from '../../../../../../components/TableSheet/components/styles.css';
import TableSheetCopy from '../../../../../../components/TableSheetCopy';
import Tooltip from '../../../../../../components/Tooltip';
import {deepClone, isObj} from '../../../../../../helpers/utils';
import SelectFieldType from '../../../components/SelectFieldType';
import {calcColHideRepeatStyle, tableCellRender} from '../../helpers';

const hoverDeleteStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .delIcon {
    font-size: 14px;
    color: #f5222d;
  }
`;

const PreviewTableY = () => {
  const tableRef = useRef();

  const [form] = Form.useForm();

  const [totalStatus, setTotalStatus] = useAtom(logicEditAtom);
  const {
    showHideFields,
    previewYData,
    previewYOriginColumns,
    previewTableHeight,
    previewTableLoading,
  } = totalStatus;

  const [filterParams, setFilterParams] = useAtom(filterAtom);
  const {conditions} = filterParams;

  const showYDataSource = useMemo(() => {
    return showHideFields
      ? calcColHideRepeatStyle(previewYData)
      : calcColHideRepeatStyle(previewYData.filter((col) => col.isShow));
  }, [previewYData, showHideFields]);

  // 是否有被删除的字段
  const hasDelFields = useMemo(() => {
    return previewYData.some((col) => col?.delFlag === 1);
  }, [previewYData]);

  const computeRowClassName = (record, index) => {
    const {isShow, delFlag} = record;
    return delFlag === 1
      ? 'delRowStyle'
      : isShow
      ? `showRowTitleStyle ${
          index === showYDataSource.length - 1 &&
          record.formula &&
          !record.calId
            ? 'blink'
            : ''
        }`
      : 'hidedRowTitleStyle';
  };

  // 拖拽排序
  const onDragEnd = ({active, over}) => {
    if (active.id !== over?.id) {
      setTotalStatus((pre) => {
        const activeIndex = pre?.previewYData?.findIndex(
          (i) => i.sortKeys === active.id
        );
        const overIndex = pre?.previewYData?.findIndex(
          (i) => i.sortKeys === over?.id
        );

        const newPreviewYData = arrayMove(
          deepClone(pre.previewYData),
          activeIndex,
          overIndex
        ).map((p, idx) => ({...p, indexRank: idx}));

        const tableXColumn = deepClone(newPreviewYData).sort(
          (a, b) => a.indexRank - b.indexRank
        );
        return {
          ...pre,
          previewYData: deepClone(newPreviewYData),
          previewXOriginColumns: tableXColumn,
        };
      });
    }
  };

  // 修改字段
  const onEditField = useCallback(
    ({newVal, fieldKey, row}) => {
      const newTxt = newVal.trim();
      const {sortKeys} = row;
      console.log(previewYData);
      const newYData = previewYData.map((col) => {
        if (col.sortKeys === sortKeys) {
          if (!newTxt) {
            col[`${fieldKey}Repeat`] = true;
          }
          if (
            !/^[a-zA-Z][a-zA-Z0-9_]*$/g.test(newTxt) &&
            fieldKey === 'columnAlias'
          ) {
            col[`${fieldKey}Error`] = true;
          }
          if (fieldKey === 'curShowName') col.title = newTxt;
          col[fieldKey] = newTxt;
        }

        const repeatTitleCol = previewYData.find(
          (ocol) => ocol[fieldKey] === newTxt && ocol.sortKeys !== sortKeys
        );

        if (repeatTitleCol) {
          if (
            col.sortKeys === sortKeys ||
            col.sortKeys === repeatTitleCol.sortKeys
          ) {
            col[`${fieldKey}Repeat`] = true;
          }
        }
        return col;
      });

      const tableXColumn = deepClone(newYData).sort(
        (a, b) => a.indexRank - b.indexRank
      );

      setTotalStatus((pre) => ({
        ...pre,
        previewYData: deepClone(newYData),
        previewXOriginColumns: tableXColumn,
      }));
    },
    [previewYData, setTotalStatus]
  );

  // 修改字段类型
  const onEditIndexType = useCallback(
    (value, row) => {
      const replaceIndex = previewYData.findIndex(
        (d) => (d.formula ? d.sortKeys === row.sortKeys : d.id === row.id) // 计算字段没有id
      );
      const newVal = isObj(value) ? value : {indexType: value};
      let replaceData = previewYData.find(
        (d) => (d.formula ? d.sortKeys === row.sortKeys : d.id === row.id) // 计算字段没有id
      );
      const clone = deepClone(previewYData);

      if (replaceData) {
        replaceData = {...replaceData, ...newVal};
        clone.splice(replaceIndex, 1, replaceData);
      }

      const tableXColumn = deepClone(clone).sort(
        (a, b) => a.indexRank - b.indexRank
      );
      setTotalStatus((pre) => ({
        ...pre,
        previewYData: clone,
        previewXOriginColumns: tableXColumn,
      }));
    },
    [previewYData, setTotalStatus]
  );

  // 字段是否被计算字段引用
  const handleQuoted2Calc = useCallback(
    (row) => {
      const isQuoted2Calc = previewYData.some((acol) =>
        acol?.uniqueAliasList?.includes(row.originColumnAlias)
      );
      return isQuoted2Calc;
    },
    [previewYData]
  );

  // 删除字段
  const handleDeleteField = useCallback(
    (row) => {
      const aliasName = row.aliasName || row.dataIndex || row.key;
      const conditionsCopy = deepClone(conditions);

      const newPreviewYData = previewYData.filter(
        (col) => col.sortKeys !== row.sortKeys
      );
      const tableXColumn = deepClone(newPreviewYData).sort(
        (a, b) => a.indexRank - b.indexRank
      );

      setTotalStatus((pre) => ({
        ...pre,
        previewYData: newPreviewYData,
        previewXOriginColumns: tableXColumn,
        deleteFilterFlag: true,
        deletedColumns: [...pre.deletedColumns, row],
      }));

      // 清除筛选器
      if (conditionsCopy.microIndexPreviewTable?.[aliasName]) {
        conditionsCopy.microIndexPreviewTable[aliasName] = {};
        setFilterParams({
          ...filterParams,
          conditions: conditionsCopy,
        });
      }
    },
    [conditions, filterParams, previewYData, setFilterParams, setTotalStatus]
  );

  useEffect(() => {
    setTotalStatus((pre) => ({
      ...pre,
      previewYOriginColumns: [
        {
          title: '显示',
          dataIndex: 'isShow',
          width: 50,
          fixWidth: true,
          align: 'center',
          render: (val, row) => {
            return (
              <Checkbox
                checked={row.isShow}
                css={css`
                  .ant-checkbox {
                    top: 0;
                  }
                `}
                disabled={
                  row?.formula || handleQuoted2Calc(row) || row?.delFlag === 1
                }
                onChange={() =>
                  row.onExtraFilterClick({key: 'toggleShow', column: row})
                }
              />
            );
          },
        },
        {
          key: 'sort',
          title: '排序',
          width: 60,
          fixWidth: true,
          align: 'center',
        },
        {
          title: '显示名(当前)',
          dataIndex: 'curShowName',
          render: (val, row, index) => {
            return (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  padding-right: 5px;
                `}
              >
                <Form.Item noStyle name={`curShowName_${row.sortKeys}`}>
                  <Input
                    defaultValue={val}
                    disabled={row?.delFlag === 1}
                    maxLength={50}
                    size='small'
                    style={{
                      ...(row?.delFlag === 1
                        ? {border: '1px solid #f9b1b4', background: '#f9b1b4'}
                        : row?.curShowNameRepeat
                        ? {border: '1px solid red'}
                        : {}),
                    }}
                    suffix={
                      row.curShowNameError ? (
                        <Tooltip
                          placement='rightLeft'
                          title={`不支持输入'更新时间(系统)', '标签'`}
                        >
                          <InfoCircleOutlined style={{color: 'red'}} />
                        </Tooltip>
                      ) : null
                    }
                    onBlur={(e) => {
                      if (!e.target.value) {
                        form.setFieldsValue({
                          [`curShowName_${row.sortKeys}`]: val,
                        });
                        return;
                      }
                      onEditField({
                        newVal: e.target.value ? e.target.value : val,
                        fieldKey: 'curShowName',
                        row,
                      });
                    }}
                  />
                </Form.Item>
                {row?.delFlag && (
                  <Tooltip
                    placement='rightLeft'
                    title={
                      row?.delFlag === 1
                        ? '底层字段被删除'
                        : '计算字段的算法包含被删除的字段'
                    }
                  >
                    <InfoCircleOutlined style={{color: 'red'}} />
                  </Tooltip>
                )}
                <FilterButton
                  column={{...row, showSort: false, hasToggleShowBtn: false}}
                  disabled={row?.delFlag}
                  filterTableKey='microIndexPreviewTable'
                />
              </div>
            );
            // return <FilterCell row={row} tableKey='microIndexPreviewTable' />;
          },
        },
        {
          title: '源',
          dataIndex: 'calColumnIndexSource',
          render: (val, row) => {
            const {dbIp, dbPort, dbName, tableName, tableRemark, tableComment} =
              row;

            const textVal = `${dbIp}:${dbPort}):${dbName},${tableName}(${
              tableRemark || tableComment
            })`;

            return tableCellRender(val || textVal, row.isShow, row);
          },
        },
        {
          title: '字段名(英文)',
          dataIndex: 'columnAlias',
          render: (val, row, index) => {
            return (
              <Form.Item noStyle name={`columnAlias_${row.sortKeys}`}>
                <Input
                  defaultValue={val}
                  disabled={row?.delFlag === 1}
                  maxLength={50}
                  size='small'
                  style={{
                    ...(row?.delFlag === 1
                      ? {border: '1px solid #f9b1b4', background: '#f9b1b4'}
                      : row?.columnAliasRepeat
                      ? {border: '1px solid red'}
                      : {}),
                  }}
                  suffix={
                    row.columnAliasError ? (
                      <Tooltip
                        placement='rightLeft'
                        title='支持输入50个字符；不可使用数据库语法作为字段名 (如select、from、join、union等)，不可为空，仅支持英文大小写、数值、_，首字母必须为英文；不可重复'
                      >
                        <InfoCircleOutlined style={{color: 'red'}} />
                      </Tooltip>
                    ) : null
                  }
                  onBlur={(e) => {
                    if (!e.target.value) {
                      form.setFieldsValue({
                        [`columnAlias_${row.sortKeys}`]: val,
                      });
                      return;
                    }
                    onEditField({
                      newVal: e.target.value ? e.target.value : val,
                      fieldKey: 'columnAlias',
                      row,
                    });
                  }}
                />
              </Form.Item>
            );
          },
        },
        {
          title: '字段注释(原始)',
          dataIndex: 'tableComment',
          render: (val, row) => tableCellRender(val, row.isShow, row),
          width: 100,
        },
        {
          title: '字段类型',
          dataIndex: 'indexType',
          render: (val, row) => {
            return (
              <SelectFieldType
                defaultValue={{type: val, precision: row?.decimalPlaces}}
                delFlag={row?.delFlag}
                onChange={({type: indexType, precision: decimalPlaces = 2}) => {
                  onEditIndexType(
                    {
                      indexType,
                      ...(indexType === 'decimal' && {
                        decimalPlaces,
                      }),
                    },
                    row
                  );
                }}
              />
            );
          },
          width: 160,
        },
        {
          title: '样例数据',
          dataIndex: 'tempData',
          render: (val, row) => tableCellRender(val, row.isShow, row),
        },
        hasDelFields
          ? {
              title: ' ',
              dataIndex: 'del',
              align: 'center',
              width: 36,
              fixWidth: true,
              render: (val, row, index) => {
                return row?.delFlag === 1 ? (
                  <div
                    css={hoverDeleteStyle}
                    onClick={() => handleDeleteField(row)}
                  >
                    <IconFont className='delIcon' type='icon-close' />
                  </div>
                ) : null;
              },
            }
          : false,
      ].filter(Boolean),
    }));
  }, [
    form,
    onEditField,
    onEditIndexType,
    setTotalStatus,
    handleQuoted2Calc,
    hasDelFields,
    handleDeleteField,
  ]);

  return (
    <div className='previewTableY' style={{marginTop: 6}}>
      <Form
        css={css`
          .ant-form-item {
            margin-bottom: 0;
            flex: 1;
          }
          .ant-form-item-control-input {
            min-height: auto;
          }
        `}
        form={form}
        // onValuesChange={onValuesChange}
      >
        <TableSheetCopy
          clearFilter={false}
          columns={previewYOriginColumns}
          components={{
            body: {
              row: TableComponentsBodyRow,
            },
          }}
          css={css`
            ${showRowTitleStyle};
            ${hidedRowTitleStyle};
            ${delRowStyle};
            td.ant-table-cell {
              .leftSortIcon,
              .rightSortIcon {
                display: none;
              }
            }
          `}
          dataSource={[...showYDataSource]}
          dragSortConfig={{
            items: showYDataSource.map((i) => i.sortKeys),
            modifiers: [restrictToVerticalAxis],
            strategy: verticalListSortingStrategy,
            onDragEnd,
          }}
          loading={previewTableLoading}
          pagination={false}
          ref={tableRef}
          refresh={false}
          rowClassName={computeRowClassName}
          rowKey='sortKeys'
          scroll={{
            x: `${
              document.getElementById('previewTableWraper').offsetHeight
            }px`,
            y: previewTableHeight,
          }}
          storeColumnWidthKey='microIndexPreviewTable__Y'
        />
      </Form>
    </div>
  );
};

export default PreviewTableY;
