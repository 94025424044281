import {css} from '@emotion/react';
import {Divider, Dropdown, Menu} from 'antd';
import {useAtom} from 'jotai';
import React, {useMemo} from 'react';

import {FULLSCREEN_POINT} from '../../../constants/system';
import {theme} from '../../../constants/theme';
import {deepClone} from '../../../helpers/utils';
import {filterAtom} from '../../Filter/atoms/filterAtom';
import IconFont from '../../IconFont';

const selectedColor = '#4D5EFF';
const textColor = '#253145';

const menuStyle = css`
  /* width: 100px; */
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15) !important;
  border-radius: 2px;

  .ant-menu-item {
    margin: 0 !important;
    height: 32px;
    line-height: 32px;
    color: ${textColor};
    &.ant-menu-item-selected {
      background: ${theme.colors.SELECTED_BACKGROUND};
      color: ${selectedColor};
    }
    &:hover {
      background: ${theme.colors.SELECTED_BACKGROUND};
    }
  }
`;

const FilterButton = (props) => {
  const {filterTableKey, setTitleEditing, showFilter = true, column} = props;
  const {
    align,
    showSort = true,
    extraFilterItems = [],
    onExtraFilterClick = (v) => v,
    hasToggleShowBtn = true,
    filterDisable = false,
  } = column;

  const [filterParams, setFilterParams] = useAtom(filterAtom);
  const {conditions} = filterParams;

  // 适配微观指标，横竖向表格互相转换共享筛选器状态，不共享columns动态宽度
  const tableKey = filterTableKey.split('__')[0];

  const aliasName = column.aliasName || column.dataIndex || column.key;

  const handleMenuClick = ({item, key, keyPath, domEvent}) => {
    domEvent.stopPropagation();

    // 微观指标中添加的额外按钮
    if (extraFilterItems.some((eItem) => eItem?.key === key)) {
      switch (key) {
        // 显示 / 隐藏
        case 'toggleShow':
          break;
        // 重命名
        case 'rename':
          setTitleEditing(true);
          break;
        // 重置名称
        case 'resetName':
          break;
        // 创建计算字段
        case 'creatCalc':
          break;
        // 编辑计算字段
        case 'editCalc':
          break;
        // 删除计算字段
        case 'delCalc':
          break;

        default:
          break;
      }
      onExtraFilterClick({key, column});
      return;
    }

    const conditionsCopy = deepClone(conditions);

    if (!conditionsCopy[tableKey]) {
      conditionsCopy[tableKey] = {
        [aliasName]: {},
      };
    }
    if (!conditionsCopy[tableKey][aliasName]) {
      conditionsCopy[tableKey][aliasName] = {};
    }

    const {[aliasName]: oldColParams = {}, ...restColsParams} =
      conditionsCopy[tableKey];

    const {orderType = ''} = oldColParams;

    switch (key) {
      case 'filter':
        setFilterParams({
          conditions: conditionsCopy,
          visible: true,
          tableKey,
          column: {
            ...column,
            aliasName,
          },
        });
        break;

      case 'clearFilter':
        conditionsCopy[tableKey][aliasName] = orderType ? {orderType} : {};
        setFilterParams({
          ...filterParams,
          conditions: conditionsCopy,
        });
        Promise.resolve().then(() => {
          column.onFilterSearch();
        });
        break;

      case 'asc':
        /**
         *  对象遍历默认顺序：属性是string时按加入顺序
         *  按用户操作顺序:
         *  1 譬如操作了A升序 操作了B降序
         *  2 此时order by A asc,B desc
         *  3 然后用户操作了A降序,那么order by 变为B desc,A desc
         */
        conditionsCopy[tableKey] = {
          ...restColsParams,
          ...{[aliasName]: {...oldColParams, orderType: 'asc'}},
        };

        setFilterParams({
          ...filterParams,
          conditions: conditionsCopy,
        });

        // if (extraFilterItems.length > 0) {
        //   onExtraFilterClick({key, column});
        // } else {
        Promise.resolve().then(() => {
          column.onFilterSearch();
        });
        // }
        break;

      case 'desc':
        /**
         *  对象遍历默认顺序：属性是string时按加入顺序
         *  按用户操作顺序:
         *  1 譬如操作了A升序 操作了B降序
         *  2 此时order by A asc,B desc
         *  3 然后用户操作了A降序,那么order by 变为B desc,A desc
         */
        conditionsCopy[tableKey] = {
          ...restColsParams,
          ...{[aliasName]: {...oldColParams, orderType: 'desc'}},
        };

        setFilterParams({
          ...filterParams,
          conditions: conditionsCopy,
        });
        // if (extraFilterItems.length > 0) {
        //   onExtraFilterClick({key, column});
        // } else {
        Promise.resolve().then(() => {
          column.onFilterSearch();
        });
        // }
        break;

      case 'clearSortOrder':
        delete conditionsCopy[tableKey][aliasName].orderType;
        setFilterParams({
          ...filterParams,
          conditions: conditionsCopy,
        });
        // if (extraFilterItems.length > 0) {
        //   onExtraFilterClick({key, column});
        // } else {
        Promise.resolve().then(() => {
          column.onFilterSearch();
        });
        // }
        break;

      default:
      //
    }
  };

  const LeftSortIcon = useMemo(() => {
    let iconTypeName = '';
    const curColFilter = conditions[tableKey]?.[aliasName] || {};
    const {orderType} = curColFilter;
    if (align === 'right' && orderType === 'asc') {
      iconTypeName = 'icon-on';
    }
    if (align === 'right' && orderType === 'desc') {
      iconTypeName = 'icon-down-01';
    }

    if (iconTypeName) {
      return (
        <IconFont
          className='leftSortIcon'
          css={css`
            margin-right: 5px;
            svg {
              font-size: 12px;
              color: #9a9fa6;
            }
          `}
          type={iconTypeName}
        />
      );
    }

    return null;
  }, [aliasName, align, conditions, tableKey]);

  const RightSortIcon = useMemo(() => {
    let iconTypeName = '';
    const curColFilter = conditions[tableKey]?.[aliasName] || {};
    const {orderType} = curColFilter;
    if (align !== 'right' && orderType === 'asc') {
      iconTypeName = 'icon-on';
    }
    if (align !== 'right' && orderType === 'desc') {
      iconTypeName = 'icon-down-01';
    }
    if (iconTypeName) {
      return (
        <IconFont
          className='rightSortIcon'
          css={css`
            margin-left: 5px;
            svg {
              font-size: 12px;
              color: #9a9fa6;
            }
          `}
          type={iconTypeName}
        />
      );
    }
    return null;
  }, [aliasName, align, conditions, tableKey]);

  const hasFilter = useMemo(() => {
    const curColFilter = conditions[tableKey]?.[aliasName] || {};
    const {orderType, ...restFilter} = curColFilter;
    const hasFilterChoosed = Object.keys(restFilter).length > 0;
    const filterIconColor = hasFilterChoosed ? selectedColor : '#9A9FA6';
    const filterTextColor = hasFilterChoosed ? selectedColor : textColor;

    return {
      filterIconColor,
      filterTextColor,
      orderType,
    };
  }, [aliasName, conditions, tableKey]);

  const hasColFilerCondition = useMemo(() => {
    const con = conditions[tableKey]?.[aliasName];
    let len = 0;
    if (con) {
      const filterKeys = Object.keys(con);
      for (let i = 0; i < filterKeys.length; i++) {
        const key = filterKeys[i];
        if (key !== 'orderType') {
          len++;
        }
      }
    }
    return len > 0;
  }, [conditions, tableKey, aliasName]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      }}
    >
      {LeftSortIcon}
      <Dropdown
        disabled={filterDisable}
        getPopupContainer={() => document.getElementById(FULLSCREEN_POINT)}
        overlay={
          <Menu css={menuStyle} onClick={handleMenuClick}>
            {showFilter ? (
              <>
                <Menu.Item key='filter'>
                  <span
                    css={css`
                      color: ${hasFilter.filterTextColor};
                    `}
                  >
                    筛选
                  </span>
                </Menu.Item>
                {hasColFilerCondition && (
                  <Menu.Item key='clearFilter'>
                    <span>清除筛选</span>
                  </Menu.Item>
                )}
              </>
            ) : null}
            {showSort ? (
              <>
                <Divider key={Math.random()} style={{margin: 0}} />
                <Menu.Item key='asc'>
                  <span
                    css={css`
                      color: ${hasFilter.orderType === 'asc'
                        ? selectedColor
                        : textColor};
                    `}
                  >
                    升序
                  </span>
                </Menu.Item>
                <Menu.Item key='desc'>
                  <span
                    css={css`
                      color: ${hasFilter.orderType === 'desc'
                        ? selectedColor
                        : textColor};
                    `}
                  >
                    降序
                  </span>
                </Menu.Item>
                {conditions[tableKey]?.[aliasName]?.orderType && (
                  <Menu.Item key='clearSortOrder'>
                    <span>清除排序</span>
                  </Menu.Item>
                )}
              </>
            ) : null}
            {(hasToggleShowBtn
              ? extraFilterItems
              : extraFilterItems.filter((item) => item.key !== 'toggleShow')
            ).map((eItem) => {
              return eItem?.key ? (
                <Menu.Item
                  disabled={eItem?.key === 'rename' && column?.isShow === false}
                  key={eItem?.key}
                >
                  <span>{eItem?.label}</span>
                </Menu.Item>
              ) : null;
            })}
          </Menu>
        }
        placement={align === 'right' ? 'bottomRight' : 'bottomLeft'}
        trigger={['click']}
      >
        <IconFont
          css={css`
            svg {
              font-size: 12px;
              color: ${hasFilter.filterIconColor};
            }
          `}
          type='icon-screening-01'
        />
      </Dropdown>

      {RightSortIcon}
    </div>
  );
};

export default FilterButton;
