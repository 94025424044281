import {css} from '@emotion/react';
import {useAtom} from 'jotai';
import React, {useMemo, useRef, useEffect} from 'react';

import {logicEditAtom} from '../../../../../../atoms/logicEditAtom';
import EditableTable from '../../../../../../components/EditableTable';
import GlobalModal from '../../../../../../components/GlobalModal';
import IconFont from '../../../../../../components/IconFont';
import {deepClone} from '../../../../../../helpers/utils';
import useSafeState from '../../../../../../hooks/useSafeState';
import {findNodeChilds, getNewJoinCondition, treeFilter} from '../../helpers';
import {innerJoin, leftJoin} from '../../helpers/constants';
import CrossCircle from '../CrossCircle';
import {
  hoverDeleteStyle,
  joinType,
  joinTypeWraper,
  rulesEditWraper,
  titleStyle,
} from './styles.css';

const defaultEmptyData = [[]];

function filterOption(inputValue, option) {
  return (option.label || option.children)
    .toLowerCase()
    .includes(inputValue.toLowerCase());
}

const arr2Obj = (arr) => arr.map(([left, right]) => ({left, right}));

const obj2arr = (objArr) =>
  objArr
    .filter(({left, right}) => left || right)
    .map(({left, right}) => [left, right]);

const LogicSetModal = (props) => {
  const [totalStatus, setTotalStatus] = useAtom(logicEditAtom);
  const {
    logicSetModalVisible,
    previewXOriginColumns,
    leftNodeInfo,
    rightNodeInfo,
    graphData: {tableStructureList},
  } = totalStatus;

  const {
    joinCondition,
    joinLogicalColumns,
    tableId: rightNodeTableId,
    level: oldRightNodeLevel,
    parentTableId,
  } = rightNodeInfo;

  const formTableRef = useRef();
  const parentTableIdRef = useRef();

  const [newParentTableId, setNewParentTableId] = useSafeState(parentTableId);
  const [curJoinType, setCurJoinType] = useSafeState(leftJoin);
  const [dataSource, setDataSource] = useSafeState([]);

  // 当前右侧节点的所有子节点
  const curRightNodeChildNodes = findNodeChilds(
    tableStructureList,
    rightNodeTableId,
    oldRightNodeLevel
  );

  // const calcOptions = previewXOriginColumns
  //   .filter((col) => col.formula)
  //   .map((c) => ({label: c.title, value: c.id}));

  const leftTotalOptions = tableStructureList
    .filter(
      (ts) =>
        ts.tableId !== rightNodeTableId &&
        !curRightNodeChildNodes.some(
          (childNode) => childNode.tableId === ts.tableId
        )
    )
    .map((leftOtherTable) => {
      const {dbName, tableId, tableName} = leftOtherTable;

      const cols = previewXOriginColumns
        .filter((col) => !(col.delFlag === 1))
        .filter((col) => col.tableId === tableId && !col.formula)
        .map((c) => ({label: `${c.title}(${c.columnAlias})`, value: c.id}));

      return {
        label: `${dbName}.${tableName}`,
        tableId,
        children: cols,
      };
    });
  // .concat({
  //   label: '计算字段',
  //   tableId: -1,
  //   children: calcOptions,
  // });

  const rightTotalOptions = previewXOriginColumns
    .filter((col) => !(col.delFlag === 1))
    .filter((col) => col.tableId === rightNodeTableId)
    .map((c) => ({label: `${c.title}(${c.columnAlias})`, value: c.id}));

  const columns = useMemo(
    () => [
      {
        title: () => <div css={titleStyle}>左侧</div>,
        dataIndex: 'left',
        editable: true,
        allowClear: true,
        showSearch: true,
        filterOption,
        componentType: 'Select',
        align: 'left',
        dropdownStyle: {
          // maxWidth: 660,
          whiteSpace: 'nowrap',
        },
        selectStyle: {width: 288},
        dropdownMatchSelectWidth: false,
        options: (record, index) => {
          const formInTable = formTableRef.current.getFormInstance();
          const formValue =
            formInTable.getFieldsValue()?.joinLogicalColumns ?? [];

          const leftValues = formValue
            .map(({left}) => left)
            .slice(0, index)
            .filter(Boolean);

          const row0Val = leftValues[0];

          const notMeLeftValues = formValue
            .map(({left}) => left)
            .filter((i) => i !== record.left)
            .filter(Boolean);

          if (row0Val) {
            const row0ValColTableId = previewXOriginColumns.find(
              (c) => c.id === row0Val
            )?.tableId;

            parentTableIdRef.current = row0ValColTableId;

            const curTableKeys =
              leftTotalOptions.find((t) => t.tableId === row0ValColTableId)
                ?.children ?? [];

            setNewParentTableId(row0ValColTableId);

            return curTableKeys.filter(
              (to) => !notMeLeftValues.some((rv) => to.value === rv)
            );
          }

          return treeFilter(leftTotalOptions, (node) => {
            return !notMeLeftValues.includes(node.value);
          });
        },
      },
      {
        title: ' ',
        dataIndex: 'equal',
        align: 'center',
        editable: false,
        width: 36,
        render: () => <span style={{fontSize: 16}}>=</span>,
      },
      {
        title: () => <div css={titleStyle}>右侧</div>,
        dataIndex: 'right',
        editable: true,
        allowClear: true,
        showSearch: true,
        filterOption,
        componentType: 'Select',
        align: 'left',
        dropdownStyle: {
          // maxWidth: 660,
          whiteSpace: 'nowrap',
        },
        selectStyle: {width: 288},
        dropdownMatchSelectWidth: false,
        options: (record, index) => {
          const formInTable = formTableRef.current.getFormInstance();
          const formValue =
            formInTable.getFieldsValue()?.joinLogicalColumns ?? [];

          // const rightValues = formValue
          //   .map(({right}) => right)
          //   .slice(0, index)
          //   .filter(Boolean);

          const notMeRightValues = formValue
            .map(({right}) => right)
            .filter((i) => i !== record.right)
            .filter(Boolean);

          const rightOptions = rightTotalOptions.filter(
            (to) => !notMeRightValues.some((rv) => to.value === rv)
          );

          return rightOptions;
        },
      },
      {
        title: ' ',
        dataIndex: 'del',
        editable: false,
        align: 'center',
        width: 36,
        render: (val, row, index) =>
          index === dataSource.length - 1 ? null : (
            <div
              css={hoverDeleteStyle}
              onClick={() => {
                const newDataSource = deepClone(dataSource);
                const formInTable = formTableRef.current.getFormInstance();

                if (newDataSource.length === 1) {
                  setDataSource([{}]);
                  formInTable.setFieldsValue({joinLogicalColumns: [{}]});
                  return;
                }

                newDataSource.splice(index, 1);

                const lastData = newDataSource[newDataSource.length - 1];
                const isEmptyData = !lastData.left && !lastData.right;

                setDataSource(
                  newDataSource.length
                    ? [...newDataSource, ...(isEmptyData ? [] : [{}])]
                    : defaultEmptyData
                );

                formInTable.setFieldsValue({
                  joinLogicalColumns: newDataSource,
                });
              }}
            >
              <IconFont className='delIcon' type='icon-close' />
            </div>
          ),
      },
    ],
    [
      dataSource,
      leftTotalOptions,
      previewXOriginColumns,
      rightTotalOptions,
      setDataSource,
      setNewParentTableId,
    ]
  );

  const checkComplete = (arr) => {
    const lastItem = arr.at(-1) ?? {};
    const lastIsEmpty = !lastItem.left && !lastItem.right;

    const willCheckArr = lastIsEmpty ? arr.slice(0, -1) : arr;

    return willCheckArr.every(({left, right}) => left && right);
  };

  const onCloseModal = () => {
    const formInTable = formTableRef.current.getFormInstance();

    setDataSource(arr2Obj(joinLogicalColumns));

    formInTable.resetFields();

    setTotalStatus((pre) => ({
      ...pre,
      logicSetModalVisible: false,
    }));
  };

  const onOkClick = async () => {
    setTotalStatus((pre) => ({...pre, canvasLoading: true}));

    const rootParentNode = tableStructureList.find((t) => t.level === 0);

    const newParentNode = tableStructureList.find(
      (t) => t.tableId === newParentTableId
    );

    const {
      tableId: newParentNodeTableId,
      tableName: newParentNodeTableName,
      level: newParentNodeLevel,
    } = newParentNode;

    const isWholeSelected = checkComplete(dataSource);

    // 当前右侧节点变更左侧表后需要重新查询与父级是什么关联关系
    // 手动变更过，以变更过的为准
    const joinTypeChanged = curJoinType !== joinCondition;

    const {joinCondition: newJoinCondition} = await getNewJoinCondition(
      isWholeSelected ? newParentNode : rootParentNode,
      rightNodeInfo
    );

    const newRightNodeInfo = {
      ...rightNodeInfo,
      parentTableId: isWholeSelected
        ? newParentNodeTableId
        : rootParentNode.tableId,
      parentTableName: isWholeSelected
        ? newParentNodeTableName
        : rootParentNode.tableName,
      level: isWholeSelected ? newParentNodeLevel + 1 : 1,
      joinLogicalColumns: obj2arr(dataSource),
      joinCondition: isWholeSelected
        ? joinTypeChanged
          ? curJoinType
          : joinCondition
          ? joinCondition
          : newJoinCondition
        : '',
    };

    // 当前右侧节点的所有子节点，需要调整level
    const rightNodeLevelDiff = newRightNodeInfo.level - oldRightNodeLevel;
    const newCurRightNodeChildNodes = curRightNodeChildNodes.map((cn) => {
      return {...cn, level: cn.level + rightNodeLevelDiff};
    });

    const newCanvasTables = [
      ...tableStructureList.filter(
        (t) =>
          t.tableId !== rightNodeTableId &&
          !curRightNodeChildNodes.some((cr) => cr.tableId === t.tableId)
      ),
      newRightNodeInfo,
      ...newCurRightNodeChildNodes,
    ].sort((a, b) => a.tableSequence - b.tableSequence);

    setTotalStatus((pre) => ({
      ...pre,
      leftNodeInfo: {},
      rightNodeInfo: {},
      logicSetModalVisible: false,
      graphData: {...pre.graphData, tableStructureList: newCanvasTables},
    }));
  };

  const onTableDataChange = (newVal) => {
    const formInTable = formTableRef.current.getFormInstance();
    const formValue = formInTable.getFieldsValue()?.joinLogicalColumns ?? [];

    const firstLeftValue = formValue.map(({left}) => left)[0];

    const selectTableId = previewXOriginColumns.find(
      (c) => c.id === firstLeftValue
    )?.tableId;

    // 选择不同的表 需要清空
    if (parentTableIdRef.current !== selectTableId) {
      formInTable.setFieldsValue({
        joinLogicalColumns: formValue.map((i, index) => {
          if (index === 0) {
            return {left: i.left, right: i.right};
          }
          return {left: null, right: i.right};
        }),
      });
    }

    const lastData = formValue[formValue.length - 1];
    const isWholeSelected = lastData.left && lastData.right;

    if (isWholeSelected) {
      setDataSource((pre) => [...formValue, {}]);
    } else {
      setDataSource((pre) => [...formValue]);
    }
  };

  const getNodeFields = async (left, right) => {
    const {defaultJoinLogic} = await getNewJoinCondition(left, right);
    setDataSource([...defaultJoinLogic, {left: undefined, right: undefined}]);
  };

  useEffect(
    () => setCurJoinType(joinCondition),
    [joinCondition, setCurJoinType]
  );

  useEffect(() => {
    if (joinLogicalColumns.length > 0) {
      setDataSource(arr2Obj([...joinLogicalColumns, []]));
    } else {
      getNodeFields(leftNodeInfo, rightNodeInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinLogicalColumns, leftNodeInfo, rightNodeInfo, setDataSource]);

  return (
    <GlobalModal
      destroyOnClose
      bodyStyle={{
        padding: '0 24px 10px',
        maxHeight: '800px',
        overflow: 'auto',
      }}
      maskClosable={false}
      style={{maxWidth: '80vw'}}
      title='关联'
      visible={logicSetModalVisible}
      width={700}
      wrapClassName='logicSetModal'
      onCancel={onCloseModal}
      onOk={onOkClick}
    >
      <div css={joinTypeWraper}>
        <div
          className={curJoinType === leftJoin ? 'active' : ''}
          css={joinType}
          onClick={() => setCurJoinType(leftJoin)}
        >
          <CrossCircle rightNodeInfo={{joinCondition: leftJoin}} />
          <div>左关联</div>
        </div>
        <div
          className={curJoinType === innerJoin ? 'active' : ''}
          css={joinType}
          onClick={() => setCurJoinType(innerJoin)}
        >
          <CrossCircle rightNodeInfo={{joinCondition: innerJoin}} />
          <div>内关联</div>
        </div>
      </div>

      <div css={rulesEditWraper}>
        {dataSource.length === 0 ? null : (
          <EditableTable
            bordered
            columns={columns}
            css={css`
              th.ant-table-cell {
                text-align: center !important;
              }
              th.ant-table-cell:nth-of-type(1) {
                border-right: 0 !important;
              }

              th.ant-table-cell:nth-of-type(2),
              th.ant-table-cell:nth-of-type(3) {
                border-left: 0 !important;
                border-right: 0 !important;
              }

              td.ant-table-cell {
                padding: 0 !important;
              }
            `}
            dataFieldName='joinLogicalColumns'
            dataSource={dataSource}
            ref={formTableRef}
            onDataChange={onTableDataChange}
          />
        )}
      </div>
    </GlobalModal>
  );
};

export default LogicSetModal;
