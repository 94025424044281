import {Tooltip} from 'antd';
import React from 'react';

export const DynamicTitle = (props) => {
  const {column = {}} = props;
  const {
    // 1
    dbIp,
    dbPort,
    // 2
    dbName,
    // 3
    tableName,
    tableRemark,
    tableComment,
    // 4
    title,
    indexOriName,
    // 5
    indexType,
    // 计算字段不显示库表信息
    formula,
  } = column;

  return (
    <Tooltip
      color='rgba(22, 35, 78, .8)'
      overlayClassName='noTooltipArrow'
      overlayInnerStyle={{whiteSpace: 'nowrap', width: 'max-content'}}
      placement='bottomLeft'
      title={
        <div>
          {!formula && (
            <>
              {/* <div>
                {instanceName}({instanceType},{instanceUrl}:{instancePort})
              </div> */}
              <div>
                {dbIp}:{dbPort}
              </div>
              <div>{dbName}</div>
              <div>
                {tableName}({tableRemark || tableComment})
              </div>
            </>
          )}
          <div>
            {indexOriName}({title})
          </div>
          <div>{indexType}</div>
        </div>
      }
    >
      {title}
    </Tooltip>
  );
};
