import {css} from '@emotion/react';
import {useAtom} from 'jotai';
import React from 'react';

import {logicEditAtom} from '../../../../../../atoms/logicEditAtom';
import {filterAtom} from '../../../../../../components/Filter/atoms/filterAtom';
import IconFont from '../../../../../../components/IconFont';
import {deepClone} from '../../../../../../helpers/utils';
import {findNodeChilds} from '../../helpers';
import CrossCircle from '../CrossCircle';
import {nodeItemStyle, nodeItemWraper} from './styles.css';

const ToolTipNode = (props) => {
  const {leftNodeInfo = {}, rightNodeInfo = {}} = props;
  // 编辑的宽表有tablename，刚添加的只有name
  const {level, name, tableName, tableId, delFlag} = rightNodeInfo;

  const [filterParams, setFilterParams] = useAtom(filterAtom);
  const {tableKey, conditions} = filterParams;

  const [totalStatus, setTotalStatus] = useAtom(logicEditAtom);
  const {sheetList, graphData, previewXOriginColumns} = totalStatus;
  const {tableStructureList} = graphData;

  // 新增表节点时不需要更新筛选器缓存，删除节点时需要删掉对应字段的筛选器缓存
  const updateFilter = (delTables) => {
    const delTableCols = previewXOriginColumns.filter((col) =>
      delTables.some((dt) => dt.tableId === col.tableId)
    );

    let conditionsCopy = deepClone(conditions);

    if (delTables.length === tableStructureList.length) {
      conditionsCopy = {[tableKey]: {}};
    } else {
      delTableCols.forEach((delCol) => {
        const {aliasName} = delCol;
        const curColFilter = conditionsCopy?.[tableKey]?.[aliasName] || {};
        const {orderType} = curColFilter;

        if (conditionsCopy?.[tableKey]?.[aliasName] && orderType) {
          conditionsCopy[tableKey][aliasName] = {orderType};
        } else {
          Reflect.deleteProperty(conditionsCopy?.[tableKey] || {}, aliasName);
        }
      });
    }

    setFilterParams({
      ...filterParams,
      conditions: conditionsCopy,
    });
  };

  const onNodeXClick = () => {
    const curNodeChildNodes = findNodeChilds(
      tableStructureList,
      tableId,
      level
    );

    const willDeleteNodes = [rightNodeInfo, ...curNodeChildNodes];

    const newCanvasCfgData = tableStructureList.filter(
      (ot) => !willDeleteNodes.some((wt) => wt.tableId === ot.tableId)
    );

    const newSheetList = sheetList.map((sheet) => {
      const inSheetList = willDeleteNodes.some(
        (delSheet) => delSheet.tableId === sheet.tableId
      );
      if (inSheetList || sheet.tableId === tableId) sheet._show = true;

      return sheet;
    });

    updateFilter(willDeleteNodes);

    // 编辑或者新增过的计算字段过滤掉引用了当前删除掉的tableId
    const newModifiedComputedColumns =
      totalStatus.modifiedComputedColumns.filter((col) => {
        const parsedFormulas = JSON.parse(col.formulas);
        const tableIds =
          parsedFormulas.mentionsData.map((data) => data.tableId) ?? [];
        // 只有该计算字段不引用当前tabledId才被返回
        return tableIds.flat().indexOf(tableId) === -1;
      });

    setTotalStatus((pre) => ({
      ...pre,
      canvasLoading: true,
      sheetList: newSheetList,
      graphData: {...pre.graphData, tableStructureList: newCanvasCfgData},
      modifiedComputedColumns: newModifiedComputedColumns,
    }));
  };

  const onEditNodeJoin = () => {
    setTotalStatus((pre) => ({
      ...pre,
      leftNodeInfo,
      rightNodeInfo,
      logicSetModalVisible: true,
    }));
  };

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: flex-start;
      `}
    >
      {rightNodeInfo?.level > 0 ? (
        <>
          <CrossCircle rightNodeInfo={rightNodeInfo} onClick={onEditNodeJoin} />
          <div
            style={{
              width: 14,
              height: 2,
              background: '#A2B1C3',
            }}
          />
        </>
      ) : (
        <div style={{width: 46}} />
      )}

      <div
        css={css`
          ${nodeItemWraper};
          background: ${delFlag ? '#f3abae' : 'rgba(203, 203, 203, 0.25)'};
        `}
      >
        <div css={nodeItemStyle}>{name || tableName}</div>

        <IconFont
          className='nodeItemActBtns'
          style={{
            fontSize: '14px',
            color: 'rgba(26, 34, 48, 0.25)',
          }}
          type='icon-close'
          onClick={onNodeXClick}
        />
      </div>
    </div>
  );
};

export default ToolTipNode;
