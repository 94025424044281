import {SearchOutlined} from '@ant-design/icons';
import {css} from '@emotion/react';
import {Button, Input, Switch} from 'antd';
import React, {useEffect, useRef, forwardRef, useImperativeHandle} from 'react';

import IconFont from '../../../../../components/IconFont';
import {filterTree} from '../../../../../helpers/utils';
import useSafeState from '../../../../../hooks/useSafeState';
import MenuTree from '../../../DataTableManagement/components/MicroDataQueryMenu/MenuTree';

function FolderTree(
  {
    needPermission,
    visible,
    treeApi,
    addFolderApi,
    onlyFolder,
    onSelect,
    wideIdProp,
    freshDbList,
    isWideList = false,
  },
  ref
) {
  const [showErrMsg, setShowErrMsg] = useSafeState('');

  const [switchStatus, setSwitchStatus] = useSafeState(true);

  const [addFolderDisabled, setAddFolderDisabled] =
    useSafeState(needPermission);

  const treeRef = useRef();

  const onSelectCallBack = (keys, info) => {
    setAddFolderDisabled(needPermission ? !info.node?.managePerm : false);
    onSelect(info);
    setShowErrMsg('');
  };

  // init
  useEffect(() => {
    setAddFolderDisabled(needPermission);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onSearch = (value) => {
    const originTreeData = treeRef.current.getOriginList();

    if (value) {
      const searchList = filterTree(originTreeData, (item) => {
        return item?.name?.includes(value) && item.tableType !== 2;
      });
      treeRef.current.handleTreeData(searchList);
    } else {
      treeRef.current.handleTreeData(originTreeData);
    }
    treeRef.current.handleExpand(true);
  };

  useImperativeHandle(ref, () => ({
    showSameError: (msg) => {
      setShowErrMsg(msg);
    },
    refresh: treeRef.current?.refresh,
  }));

  return (
    <>
      <div
        css={css`
          height: 296px;
          display: flex;
          flex-direction: column;
          padding: 8px 14px;
          padding-top: 0;
          border: 1px solid #eeeeee;
          overflow: hidden;
        `}
      >
        <div
          css={css`
            height: 44.5px;
            border-bottom: 1px solid #eeeeee;
            display: flex;
            padding: 2px 0;
            align-items: center;
          `}
        >
          <div>
            <span
              css={css`
                margin-right: 10px;
              `}
            >
              {switchStatus ? '展开' : '折叠'}
            </span>
            <Switch
              onChange={(checked) => {
                setSwitchStatus(checked);
                treeRef.current?.handleExpand(checked);
              }}
            />
          </div>
          <Button
            css={css`
              & > .text {
                margin-left: 5px !important;
              }
              background-color: #4d5eff;
              border: none;
              color: #ffffff;
              margin-left: 22px;
            `}
            disabled={addFolderDisabled}
            size='small'
            type='primary'
            onClick={() => {
              treeRef.current.newFolder();
            }}
          >
            <IconFont
              style={{fontSize: 14, marginLeft: 0}}
              type='icon-a-new_folder'
            />
            <span className='text'>文件夹</span>
          </Button>
          <Input
            allowClear
            placeholder='检索文件夹名称'
            size='small'
            style={{width: 220, marginLeft: 'auto'}}
            suffix={
              <SearchOutlined style={{color: 'rgba(26, 34, 48, 0.25)'}} />
            }
            onChange={(e) => {
              onSearch(e.target.value);
              setAddFolderDisabled(true);
              onSelect({});
            }}
          />
        </div>
        <div
          css={css`
            overflow: auto;
          `}
        >
          {visible && (
            <MenuTree
              onlyView
              addFolderApi={addFolderApi}
              afterNewFolder={freshDbList}
              // onlyFolder={(data) => {
              //   return (
              //     (data.pathId === null && data.draftFlag !== 0) ||
              //     [1, 2].includes(data.pathType)
              //   );
              // }}
              isWideList={isWideList}
              needPermission={needPermission}
              onlyFolder={onlyFolder}
              ref={treeRef}
              treeApi={treeApi}
              wideIdProp={wideIdProp}
              onSelectCallBack={onSelectCallBack}
            />
          )}
        </div>
      </div>
      {showErrMsg ? (
        <div
          css={css`
            color: #e34d59;
          `}
        >
          {showErrMsg}
        </div>
      ) : null}
    </>
  );
}

export default forwardRef(FolderTree);
