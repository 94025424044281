import {css} from '@emotion/react';
import React, {useEffect, useRef, useState} from 'react';

import {OPERATION_LOG} from '../../api/system/accountManage';
import dayjs from '../../helpers/extendedDayjs';
import {ExportButton} from '../ExportButton';
import LimitRangePicker from '../LimitRangePicker';
import TableSheetCopy from '../TableSheetCopy';
import defColumns from './defColumns';

const format = 'YYYY-MM-DD HH:mm:ss';

/**
 * @description 日志表格
 * @param {import('.').LogProps & import('antd').TableProps} props
 *
 * It can be accessed using:
 *
 * ```js
 *  <LogTable
      onExportClick={() => ({url, params, filename, requestConfig})}
      handleTimeChange={handleTimeChange}
      queryParams={{}}
    >
      some component
    </LogTable>
 * ```
 */
export function LogTable(props) {
  const {
    children,
    columns,
    limit = 90,
    getLogApi = OPERATION_LOG,
    queryParams = {},
    handleTimeChange = () => ({}),
    onExportClick,
    storeColumnWidthKey,
    refreshFlag,
    hasTips = true,
    extra,
    extraParamsKey = {
      startTime: 'startTime',
      endTime: 'endTime',
    },
    ...rest
  } = props;

  const [queryTime, setQueryTime] = useState([
    dayjs().subtract(limit, 'day'),
    dayjs(),
  ]);
  const [isChangeTime, setIsChangeTime] = useState(false);

  const pickerRef = useRef();
  const tableRef = useRef();
  const jumpFristRef = useRef(false);

  useEffect(() => {
    handleTimeChange(queryTime);
  }, [queryTime, handleTimeChange]);

  useEffect(() => {
    if (refreshFlag) {
      pickerRef?.current?.reset(() => {
        setIsChangeTime(false);
      });
      jumpFristRef.current = false;
      setIsChangeTime(false);
    }
  }, [refreshFlag]);

  const extraParams = {
    [extraParamsKey.startTime]: queryTime[0].startOf('day').format(format),
    [extraParamsKey.endTime]: queryTime[1].endOf('day').format(format),
    ...queryParams,
  };

  /** @type {import('antd/lib/table').ColumnsType} */
  const tableColumns = columns ? columns : defColumns;

  return (
    <div className='logTable'>
      <div className='logTable-extra'>{children}</div>
      <TableSheetCopy
        clearFilter={() => {
          pickerRef.current?.reset();
          tableRef.current?.mutate();
        }}
        columns={tableColumns}
        dataApi={getLogApi}
        extraParams={extraParams}
        integratedDatePicker={isChangeTime}
        pagination={{pageSize: 10}}
        ref={tableRef}
        rowKey='id'
        storeColumnWidthKey={storeColumnWidthKey}
        topLeftButtons={[
          onExportClick ? (
            <ExportButton
              key={1}
              name='导出日志'
              size='small'
              type='primary'
              onClick={onExportClick}
            />
          ) : (
            false
          ),
          <LimitRangePicker
            handleChange={(ret) => {
              if (jumpFristRef.current) {
                setIsChangeTime(true);
              }
              jumpFristRef.current = true;
              setQueryTime(ret);
            }}
            key={2}
            limit={limit}
            ref={pickerRef}
            size='small'
          />,
          hasTips ? (
            <p
              css={css`
                display: flex;
                align-items: center;
                line-height: 22px;
                font-size: 12px;
                color: rgba(26, 34, 48, 0.4);
              `}
              key={3}
            >
              只支持开始日期和结束日期区间跨度最大为{limit}天的查询
            </p>
          ) : (
            false
          ),
          extra ? extra() : false,
        ].filter(Boolean)}
        {...rest}
      />
    </div>
  );
}
