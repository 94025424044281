import {Form} from 'antd';
import {useAtom} from 'jotai';
import React, {useEffect} from 'react';

import {logicEditAtom} from '../../../../../atoms/logicEditAtom';
import FormRequireLabel from '../../../../../components/FormRequireLabel';
import useSafeState from '../../../../../hooks/useSafeState';
import FieldMatchFormItem from './FieldMatchFormItem';
import SheetTree from './SheetTree';

export default function CoverSaveWayFormItem({
  visible,
  isWideList,
  treeApi,
  wideIdProp,
  onSelect,
  syncFormValues,
  form,
  isSqlWide,
}) {
  const [, setTotalStatus] = useAtom(logicEditAtom);
  const [tableId, setTableId] = useSafeState(null);

  const onSheetTreeSelect = (info) => {
    onSelect?.(info);
    setTotalStatus((pre) => ({
      ...pre,
      matchFieldColumns: [],
    }));
    setTableId(info?.node.tableId);
  };

  useEffect(() => {
    if (!visible) {
      setTotalStatus((pre) => ({
        ...pre,
        matchFieldColumns: [],
      }));
      setTableId(null);
    }
  }, [setTableId, setTotalStatus, visible]);

  return (
    <>
      <Form.Item
        label={<FormRequireLabel title='保存至数据表' />}
        name='targetTableId'
        rules={[
          {
            required: true,
            message: '请选择数据表',
          },
        ]}
      >
        <SheetTree
          isWideList={isWideList}
          treeApi={treeApi}
          visible={visible}
          wideIdProp={wideIdProp}
          onSelect={onSheetTreeSelect}
        />
      </Form.Item>
      {syncFormValues?.targetTableId && (
        <FieldMatchFormItem
          form={form}
          isSqlWide={isSqlWide}
          tableId={tableId}
          visible={visible}
        />
      )}
    </>
  );
}
