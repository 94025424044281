import {css} from '@emotion/react';
import {Button} from 'antd';

import GlobalModal from '../../../../../components/GlobalModal';
import {overflowScrollBar} from '../../../../../global.css';

export default function FailReasonDetailModal({failReason, modalControl}) {
  return (
    <GlobalModal
      centered
      css={css`
        user-select: auto;
      `}
      footer={[
        <Button
          className='cancel-btn-text-color'
          key={1}
          size='small'
          type='default'
          onClick={() => modalControl._toggle(false)}
        >
          关闭
        </Button>,
      ]}
      title='日志详情'
      width={967}
      {...modalControl}
    >
      <p style={{fontSize: 16}}>请联系管理员处理相关错误</p>
      <div
        css={css`
          height: 350px;
          overflow-y: auto;
          margin-top: 15px;
          border: 1px solid #cccccc;
          padding: 10px;
          border-radius: 2px;
          ${overflowScrollBar};
        `}
      >
        <p
          style={{
            userSelect: 'auto',
            whiteSpace: 'pre-line',
          }}
        >
          {failReason ?? ''}
        </p>
      </div>
    </GlobalModal>
  );
}
