import {Button, Checkbox, Form, Input} from 'antd';
import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useMount} from 'react-use';

import {
  dbAddFolder,
  dbPathList,
  getUserMailList,
  scAddFolder,
  scFolderList,
} from '../../../../../api/dataManagement/wideTable';
import FormErrMsg from '../../../../../components/FormErrMsg';
import FormRequireLabel from '../../../../../components/FormRequireLabel';
import GlobalModal from '../../../../../components/GlobalModal';
import {defaultDateFormat} from '../../../../../constants/base';
import dayjs from '../../../../../helpers/extendedDayjs';
import {deepClone} from '../../../../../helpers/utils';
import useSafeState from '../../../../../hooks/useSafeState';
import {MicroDataQueryContext} from '../../../DataTableManagement';
import FolderTree from './FolderTree';
import SyncFormItems from './SyncFormItems';

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 4},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 20},
  },
};

const formTailLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
    offset: 4,
  },
};

const getCurStartTime = () => dayjs().add(2, 'm').format(defaultDateFormat);
export const defaultFrequency = {
  frequency: 1,
  startTime: getCurStartTime(),
  stepHours: null,
  stepDays: null,
  months: [],
  monthDays: [],
};
const initialFormValues = {
  tableName: '',
  tableNameEn: 'ud_',
  pathId: '',
  collectFlag: false,
  collectPathId: '',
  syncJobType: 0,
  jobCronJson: defaultFrequency,
  // sendMessageFlag: false,
  mail: '',
};

const UdInput = ({onChange, value, ...rest}) => {
  return (
    <Input
      defaultValue='ud_'
      maxLength={50}
      value={value}
      onChange={(e) => {
        if (e.target.value.startsWith('ud_')) {
          onChange(e);
        } else {
          e.preventDefault();
        }
      }}
      {...rest}
    />
  );
};

const SaveTableModal = (
  {
    visible,
    onOk,
    onCancel,
    title,
    tableData,
    type = 'add',
    isSaveAs,
    syncFlag,
    isWide = true,
    onlyFolder,
    isWideList,
    requestTablePathId,
    isSaving = false,
  },
  ref
) => {
  const [searchParams] = useSearchParams();

  // const [importPermission, setImportPermission] = useSafeState(true);
  const [syncFormValues, setSyncFormValues] = useSafeState(initialFormValues);

  const [userMailList, setUserMailList] = useSafeState([]);

  const fetchUserMailList = useCallback(async () => {
    try {
      const {data} = await getUserMailList();
      setUserMailList(data);
    } catch (err) {
      throw new Error('Get user mail list failed');
    }
  }, [setUserMailList]);

  const [form] = Form.useForm();

  const collectFolderRef = useRef();

  const dbFolderRef = useRef();

  useMount(() => {
    fetchUserMailList();
  });

  useEffect(() => {
    if (tableData && visible) {
      const next = deepClone(tableData);
      if (next.jobCronJson) {
        next.jobCronJson = JSON.parse(next.jobCronJson);
      }
      if (next.collectPathId) {
        next.collectFlag = true;
      }
      if (!next.tableNameEn) {
        next.tableNameEn = 'ud_';
      }
      if (next.mail) {
        next.mails = next.mail.split(',').map((id) => +id);
      }
      form.setFieldsValue(next);
      setSyncFormValues(next);
    }
  }, [form, setSyncFormValues, tableData, visible]);

  function handleValuesChange(changedValues, allValues) {
    if (changedValues.syncJobType === 1) {
      setSyncFormValues({
        ...allValues,
        jobCronJson: defaultFrequency,
      });
      form.setFieldsValue({
        jobCronJson: defaultFrequency,
      });
    } else if (changedValues.mails) {
      setSyncFormValues((pre) => ({
        ...pre,
        mail: userMailList
          .filter((item) => changedValues.mails.includes(item.userId))
          .map((d) => d.email)
          .filter(Boolean)
          .join(','),
      }));
    } else {
      setSyncFormValues({
        ...allValues,
      });
    }

    /* if (changedValues.jobCronJson) {
      // /^\+?[1-9][0-9]*$/  正整数
      const checkRule = new Map([
        [
          2,
          {
            field: 'stepHours',
            reg: /^(([1-9])|(1\d)|(2[0-3]))$/,
            msg: '请填写间隔小时数(1 ~ 23范围整数)',
          },
        ],
        [
          3,
          {
            field: 'stepDays',
            reg: /^(([1-9])|([1-2]\d)|(3[0-1]))$/,
            msg: '请填写间隔天数(1 ~ 31范围整数)',
          },
        ],
        [5, {field: ['months', 'monthDays'], msg: '请选择月份和日期'}],
      ]);
      const {frequency, startTime} = changedValues.jobCronJson;
      const okDateTimeVal = dayjs().isBefore(dayjs(startTime));
      FormErrMsg.resetFormErrMsg(form, 'jobCronJson');

      [...checkRule.keys()].forEach((key) => {
        if (key === frequency) {
          let isValid = false;
          const {field, reg, msg} = checkRule.get(key);
          if (Array.isArray(field)) {
            isValid = field.every(
              (item) => changedValues.jobCronJson[item].length > 0
            );
          } else {
            const curFieldVal = changedValues.jobCronJson[field];
            isValid = reg.test(curFieldVal);
          }

          if (!isValid) {
            FormErrMsg.setFormErrMsg(form, 'jobCronJson', msg);
          }
        }
      });

      if (!okDateTimeVal) {
        FormErrMsg.setFormErrMsg(
          form,
          'jobCronJson',
          '请选择当前系统时间之后的时间'
        );
      }
    } */
  }

  function handleSubmit(flag) {
    form.validateFields().then(() => {
      onOk(syncFormValues, flag, (err) => {
        FormErrMsg.disposeErr(form, err);
      });
    });
  }

  /* async function validateImportPermission(value) {
    const res = await checkWrite({
      pathId: value,
    });
    setImportPermission(!!res.data);
  } */

  useImperativeHandle(ref, () => ({
    setFormErrorMsg: (name, msg) => {
      FormErrMsg.setFormErrMsg(form, name, msg);
    },
  }));

  return (
    <MicroDataQueryContext.Provider
      value={{
        loading: false,
      }}
    >
      <GlobalModal
        footer={[
          <Button
            className='cancel-btn-text-color'
            key={1}
            loading={isSaving}
            size='small'
            type='default'
            onClick={onCancel}
          >
            取消
          </Button>,
          <Button
            className='ensure-btn-text-color'
            key={2}
            loading={isSaving}
            size='small'
            type='primary'
            onClick={() => handleSubmit(0)}
          >
            保存
          </Button>,
          syncFlag ? (
            false
          ) : (
            <Button
              className='ensure-btn-text-color'
              key={3}
              loading={isSaving}
              size='small'
              type='primary'
              onClick={() => {
                handleSubmit(1);
              }}
            >
              保存并同步
            </Button>
          ),
        ].filter(Boolean)}
        title={title}
        visible={visible}
        width={650}
        onCancel={onCancel}
      >
        <Form
          {...formItemLayout}
          form={form}
          initialValues={initialFormValues}
          name='saveTable'
          requiredMark={false}
          validateTrigger='onBlur'
          onValuesChange={handleValuesChange}
        >
          <Form.Item
            required
            label={<FormRequireLabel title='显示名称' />}
            name='tableName'
            rules={[
              {
                required: true,
                message: '请输入显示名称',
              },
            ]}
          >
            <Input placeholder='请输入显示名称' />
          </Form.Item>
          <Form.Item
            required
            label={<FormRequireLabel title='英文名称' />}
            name='tableNameEn'
            rules={[
              {
                required: true,
                message: '请输入英文名称',
              },
            ]}
          >
            <UdInput
              disabled={tableData?.tableNameEn && !isSaveAs}
              placeholder='请输入英文名称'
            />
          </Form.Item>
          <Form.Item
            label={
              <FormRequireLabel
                title={`${type === 'add' ? '保存' : '同步'}至数据库`}
              />
            }
            name='pathId'
            rules={[
              {
                required: true,
                message: '请选择数据库',
              },
            ]}
          >
            <FolderTree
              addFolderApi={dbAddFolder}
              isWideList={isWideList}
              needPermission={searchParams.get('tabIndex') === '2'}
              onlyFolder={
                onlyFolder
                  ? onlyFolder
                  : (data) => {
                      return (
                        (data.pathId === null && data.draftFlag !== 0) ||
                        [1, 2].includes(data.pathType)
                      );
                    }
              }
              ref={dbFolderRef}
              treeApi={(props) => {
                return dbPathList({
                  ...props,
                  ...(requestTablePathId
                    ? {tablePathId: requestTablePathId}
                    : {}),
                });
              }}
              visible={visible}
              wideIdProp={`${tableData?.pathId ?? ''}`}
              onSelect={(info) => {
                // validateImportPermission(info.node.id);
                form.setFieldsValue({
                  pathId: info.node.id,
                });
                setSyncFormValues((prev) => {
                  return {
                    ...prev,
                    pathId: info.node.id,
                  };
                });
              }}
            />
          </Form.Item>
          {/* {syncFormValues.pathId > 0 ? (
            <Form.Item {...formTailLayout}>
              <p>
                导入权限验证{importPermission ? '通过' : '失败'}
                {importPermission ? (
                  <AiFillCheckCircle color='green' />
                ) : (
                  <AiFillCloseCircle color='red' />
                )}
              </p>
            </Form.Item>
          ) : null} */}
          <Form.Item
            label='收藏数据表'
            name='collectFlag'
            valuePropName='checked'
          >
            <Checkbox />
          </Form.Item>
          {syncFormValues.collectFlag ? (
            <Form.Item name='collectPathId' {...formTailLayout}>
              <FolderTree
                onlyFolder
                addFolderApi={scAddFolder}
                ref={collectFolderRef}
                treeApi={scFolderList}
                visible={syncFormValues.collectFlag}
                wideIdProp={`${tableData?.collectPathId ?? ''}`}
                onSelect={(info) => {
                  form.setFieldsValue({
                    collectPathId: info.node.id,
                  });
                  setSyncFormValues((prev) => {
                    return {
                      ...prev,
                      collectPathId: info.node.id,
                    };
                  });
                }}
              />
            </Form.Item>
          ) : null}
          <SyncFormItems
            formValues={syncFormValues}
            userMailList={userMailList}
          />
          <FormErrMsg />
        </Form>
      </GlobalModal>
    </MicroDataQueryContext.Provider>
  );
};

export default React.forwardRef(SaveTableModal);
